import React from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import LazyLoad from "react-lazyload"
import { AiFillHeart } from "react-icons/ai"
import "../../css/card/List_Card.css"

const List_Card = props => {
    const userData = useSelector(state => state.user)
    let path = `/post?id=${props.item.id}`
    let url = `https://adplin.s3.amazonaws.com/${props.item.id}/resize/cover_${
        props.item.main_image.split(".")[0]
    }.jpeg`

    let first_character
    let name
    if (props.item.display_name === "full_name") {
        first_character = props.item.first_name[0]
        name = `${props.item.first_name} ${props.item.last_name}`
    } else {
        first_character = props.item.business_name[0]
        name = props.item.business_name
    }
    let price_basic = kFormatter(props.item.price_basic)
    let price_average = kFormatter(props.item.price_average)
    let price_luxury = kFormatter(props.item.price_luxury)

    function kFormatter(num) {
        return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num)
    }

    function heartClicked(e, post_id, index, action) {
        e.preventDefault()
        if (userData.token && userData.token !== "undefined") {
            if (action === "save") {
                props.changeList(index, post_id)
            } else {
                props.changeList(index, undefined)
            }
            //fech to server and update database
            fetch("/post/post_save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                },
                body: JSON.stringify({
                    action: action,
                    post_id: post_id
                })
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    console.log(data)
                })
        } else {
            props.passedFunction("Sign in to", "pop")
            document.getElementById("loginForm").style.display = "flex"
        }
    }

    function imgLoad(e) {
        e.currentTarget.style.transform = "translateX(0)"
    }

    return (
        <Link className="list_card_link_container" to={path}>
            <LazyLoad
                height={100}
                style={{ width: "100%" }}
                once
                placeholder={
                    <div
                        style={{
                            width: "100%",
                            height: "200px",
                            backgroundColor: "rgb(231 230 230)"
                        }}
                    ></div>
                }
            >
                <div className="list_card_link_image_container">
                    <img
                        className="list_card_link_image"
                        src={url}
                        alt={"category images"}
                        onLoad={imgLoad}
                    />
                </div>
                <div className="list_card_link_bottom_container">
                    <div className="list_card_link_bottom_first_line">
                        <div className="list_card_link_bottom_user_container">
                            <div className="list_card_link_bottom_first_character">
                                {first_character}
                            </div>
                            <div className="list_card_link_bottom_name">
                                {name}
                            </div>
                        </div>
                        {props.item.saved > 0 ? (
                            <AiFillHeart
                                className="browse_trending2_user_info_like"
                                size={16}
                                color={"#EB5757"}
                                onClick={e =>
                                    heartClicked(
                                        e,
                                        props.item.id,
                                        props.index,
                                        "unsave"
                                    )
                                }
                            />
                        ) : (
                            <AiFillHeart
                                className="browse_trending2_user_info_like"
                                size={16}
                                color={"#BDB8C6"}
                                onClick={e =>
                                    heartClicked(
                                        e,
                                        props.item.id,
                                        props.index,
                                        "save"
                                    )
                                }
                            />
                        )}
                    </div>

                    <div className="list_card_link_title">
                        {props.item.title}
                    </div>
                    <div className="list_card_link_bottom_price_container">
                        <div>${price_basic}</div>
                        <div>${price_average}</div>
                        <div>${price_luxury}</div>
                    </div>
                </div>
            </LazyLoad>
        </Link>
    )
}

// <Link className="list_card_link_container" to={path}>
//     <LazyLoad
//         height={100}
//         style={{ width: "100%" }}
//         once
//         placeholder={
//             <div
//                 style={{
//                     width: "100%",
//                     height: "200px",
//                     backgroundColor: "rgb(231 230 230)"
//                 }}
//             ></div>
//         }
//     >
//         <div className="list_card_link_image_container">
//             <img
//                 className="list_card_link_image"
//                 src={url}
//                 alt={"category images"}
//             />
//         </div>
//         <div className="list_card_link_bottom_container">
//             <div className="list_card_link_bottom_first_character">
//                 {first_character}
//             </div>
//             <div className="list_card_link_bottom_name">{name}</div>
//             <div className="list_card_link_bottom_price_container">
//                 <div>${price_basic}</div>
//                 <div>${price_average}</div>
//                 <div>${price_luxury}</div>
//             </div>
//         </div>
//     </LazyLoad>
// </Link>

export default List_Card
