import React, { useState, useEffect } from "react"
import { Editor, EditorState, convertFromRaw } from "draft-js"
import "draft-js/dist/Draft.css"
import "../../css/post/Description.css"

const Description = props => {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    )

    useEffect(() => {
        if (props.description !== undefined) {
            let content = EditorState.createWithContent(
                convertFromRaw(JSON.parse(props.description))
            )
            setEditorState(content)
        }
    }, [props.description])
    return (
        <div id="post_description_container">
            <Editor editorState={editorState} readOnly={true} />
        </div>
    )
}

export default Description
