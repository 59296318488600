import React, { useState, useEffect } from "react"
import "../../css/Title.css"

const Title = props => {
    const [title, setTitle] = useState("")
    const [lengthColor, setLengthColor] = useState(
        "create_edit_title_input_length_black"
    )

    function changeHandler(e) {
        setTitle(e.currentTarget.value)
        props.updateParent("title", e.currentTarget.value)

        if (e.currentTarget.value.length >= 50) {
            setLengthColor("create_edit_title_input_length_red")
        } else {
            setLengthColor("create_edit_title_input_length_black")
        }
    }

    useEffect(() => {
        if (props.title !== undefined) {
            setTitle(props.title)
        }
    }, [props])

    return (
        <div>
            <div className="create_edit_title">Title</div>
            <div id="create_edit_title_input_container">
                <input
                    id="create_edit_title_input"
                    type="text"
                    placeholder="Title"
                    maxLength="50"
                    value={title}
                    onChange={changeHandler}
                />
                <div id={lengthColor}>{title.length}/50</div>
            </div>
        </div>
    )
}

export default Title
