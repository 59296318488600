import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { MdStar, MdStarBorder } from "react-icons/md"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import Report_Review from "./Report_Review"
import "../../css/post/Review.css"
import getScript from "../../language"

const Review = props => {
    let script = getScript("post")
    const userData = useSelector(state => state.user)
    const [userID, setUserID] = useState()
    const [review_id, setReview_id] = useState()
    const [list, setList] = useState([])
    const [listReady, setListReady] = useState(false)
    const [sort, setSort] = useState("Newest")
    const [arrow, setArrow] = useState(<IoIosArrowDown />)
    const [sortShown, setSortShown] = useState(false)

    function showSort() {
        if (!sortShown) {
            document.getElementById(
                "post_review_sort_container"
            ).style.display = "flex"
            setArrow(<IoIosArrowUp />)
            setSortShown(true)
        } else {
            document.getElementById(
                "post_review_sort_container"
            ).style.display = "none"
            setArrow(<IoIosArrowDown />)
            setSortShown(false)
        }
    }

    function changeReviewId(id) {
        setReview_id(id)
    }

    useEffect(() => {
        //get reviews
        fetch(`/post/get_review?post_id=${props.post_id}`)
            .then(res => res.json())
            .then(res => {
                setList(
                    res.results.sort((a, b) => {
                        return new Date(a.time) - new Date(b.time)
                    })
                )
                setListReady(true)
            })
    }, [])

    useEffect(() => {
        if (props.user_id !== undefined) {
            setList(
                list.filter(e => {
                    return e.user_id != props.user_id
                })
            )
        }
    }, [listReady, props.user_id])

    useEffect(() => {
        document.getElementById("post_review_sort_container").style.display =
            "none"
        setArrow(<IoIosArrowDown />)
        if (sort === "Newest") {
            setList(
                list.sort((a, b) => {
                    return new Date(a.time) - new Date(b.time)
                })
            )
        } else if (sort === "Oldest") {
            setList(
                list.sort((a, b) => {
                    return new Date(b.time) - new Date(a.time)
                })
            )
        } else if (sort === "Lowest rating") {
            setList(
                list.sort((a, b) => {
                    return a.star - b.star
                })
            )
        } else if (sort === "Highest rating") {
            setList(
                list.sort((a, b) => {
                    return b.star - a.star
                })
            )
        }
        setSortShown(false)
    }, [sort])

    return (
        <div>
            <div id="post_review_title_container">
                <div id="post_review_title">{script.reviews}</div>
                <div id="post_review_sort_button" onClick={showSort}>
                    <div id="post_review_sort_button_sort_by">
                        {script.sort}
                    </div>
                    {sort}
                    <div id="post_review_sort_button_icon">{arrow}</div>
                </div>
                <div id="post_review_sort_container">
                    <li
                        onClick={() => {
                            setSort("Newest")
                        }}
                    >
                        {script.newest}
                    </li>
                    <li
                        onClick={() => {
                            setSort("Oldest")
                        }}
                    >
                        {script.oldest}
                    </li>
                    <li
                        onClick={() => {
                            setSort("Highest rating")
                        }}
                    >
                        {script.highest}
                    </li>
                    <li
                        onClick={() => {
                            setSort("Lowest rating")
                        }}
                    >
                        {script.lowest}
                    </li>
                </div>
            </div>
            <ReviewLoggedInUser
                post_id={props.post_id}
                user_id={props.user_id}
            />
            {list.length > 0 ? (
                <div>
                    {list.map(item => {
                        return (
                            <ReviewList
                                item={item}
                                changeReviewId={changeReviewId}
                            />
                        )
                    })}
                </div>
            ) : (
                <div id="post_review_no_review">{script.no_review}</div>
            )}
            <Report_Review review_id={review_id} post_id={props.post_id} />
        </div>
    )
}

const ReviewLoggedInUser = props => {
    let script = getScript("post")
    const userData = useSelector(state => state.user)
    const [user_id, setUser_id] = useState()
    const [name, setName] = useState()
    const [firstCharacter, setFirstCharacter] = useState()
    const [star, setStar] = useState(0)
    const [originalStar, setOriginalStar] = useState(0)
    const [text, setText] = useState()
    const [originalText, setOriginalText] = useState()
    const [time, setTime] = useState()
    const [alreadyReviewed, setAlreadyReviewed] = useState(false)
    const [editMode, setEditMode] = useState(false)

    function cancelReview() {
        setText(originalText)
        setStar(originalStar)
        setEditMode(false)
    }

    function deleteReview() {
        fetch("/post/delete_review", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                post_id: props.post_id
            })
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
            })
        setStar(0)
        setOriginalStar(0)
        setText()
        setOriginalText()
        setAlreadyReviewed(false)
        setEditMode(false)
    }

    function saveReview() {
        fetch("/post/save_review", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                post_id: props.post_id,
                star,
                text
            })
        })
            .then(res => {
                return res.json()
            })
            .then(res => {})
        setAlreadyReviewed(true)
        setEditMode(false)
    }

    let ReviewButton = () => {
        if (alreadyReviewed) {
            if (editMode) {
                return (
                    <div id="post_review_buttons_container">
                        <div class="post_review_buttons" onClick={cancelReview}>
                            {script.cancel}
                        </div>
                        <div class="post_review_buttons" onClick={saveReview}>
                            {script.save}
                        </div>
                    </div>
                )
            } else {
                return (
                    <div id="post_review_buttons_container">
                        <div class="post_review_buttons" onClick={deleteReview}>
                            {script.delete}
                        </div>
                        <div
                            class="post_review_buttons"
                            onClick={() => {
                                setEditMode(true)
                            }}
                        >
                            {script.edit}
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div class="post_review_buttons" onClick={saveReview}>
                    {script.save}
                </div>
            )
        }
    }

    let ReviewInput = () => {
        if (alreadyReviewed) {
            if (editMode) {
                return (
                    <textarea
                        id="post_review_input"
                        type="text"
                        placeholder="Tell us about your experience."
                        value={text}
                        maxLength="500"
                        onChange={e => {
                            setText(e.currentTarget.value)
                        }}
                    ></textarea>
                )
            } else {
                return <div className="post_review_text_container">{text}</div>
            }
        } else {
            return (
                <textarea
                    id="post_review_input"
                    type="text"
                    placeholder="Tell us about your experience."
                    value={text}
                    maxLength="500"
                    onChange={e => {
                        setText(e.currentTarget.value)
                    }}
                ></textarea>
            )
        }
    }

    let Stars = () => {
        let response = []
        if (alreadyReviewed) {
            if (editMode) {
                for (let i = 0; i < 5; i++) {
                    if (i < star) {
                        response.push(
                            <MdStar
                                onClick={() => {
                                    setStar(i + 1)
                                }}
                            />
                        )
                    } else {
                        response.push(
                            <MdStarBorder
                                onClick={() => {
                                    setStar(i + 1)
                                }}
                            />
                        )
                    }
                }
                return (
                    <div
                        id="post_review_star_container_edit"
                        className="post_review_star_container"
                    >
                        {response}
                    </div>
                )
            } else {
                for (let i = 0; i < 5; i++) {
                    if (i < star) {
                        response.push(<MdStar />)
                    } else {
                        response.push(<MdStarBorder />)
                    }
                }
                return (
                    <div className="post_review_star_container">{response}</div>
                )
            }
        } else {
            for (let i = 0; i < 5; i++) {
                if (i < star) {
                    response.push(
                        <MdStar
                            onClick={() => {
                                setStar(i + 1)
                            }}
                        />
                    )
                } else {
                    response.push(
                        <MdStarBorder
                            onClick={() => {
                                setStar(i + 1)
                            }}
                        />
                    )
                }
            }
            return (
                <div
                    id="post_review_star_container_edit"
                    className="post_review_star_container"
                >
                    {response}
                </div>
            )
        }
    }

    useEffect(() => {
        if (user_id !== undefined) {
            fetch(
                `/post/get_review_of_user?post_id=${props.post_id}&user_id=${user_id}`
            )
                .then(res => res.json())
                .then(res => {
                    if (res.results.length > 0) {
                        setAlreadyReviewed(true)
                        setStar(res.results[0].star)
                        setOriginalStar(res.results[0].star)
                        setText(res.results[0].text)
                        setOriginalText(res.results[0].text)
                        setTime(res.results[0].time)
                    }
                    if (res.userData.display_name === "full_name") {
                        setName(
                            `${res.userData.first_name} ${res.userData.last_name}`
                        )
                        setFirstCharacter(res.userData.first_name[0])
                    } else {
                        setName(res.userData.business_name)
                        setFirstCharacter(res.userData.business_name[0])
                    }
                })
        }
    }, [user_id])
    useEffect(() => {
        if (props.user_id !== undefined) {
            setUser_id(props.user_id)
        }
    }, [props])

    if (props.user_id !== undefined) {
        return (
            <div className="post_review_list_container">
                <div className="post_review_image_container">
                    {firstCharacter}
                </div>
                <div>
                    <div id="post_review_logged_in_top">
                        <div className="post_review_name">{name}</div>
                        {time ? (
                            <div
                                className="post_review_date"
                                id="post_review_logged_in_date"
                            >
                                {new Date(time).toLocaleDateString("en-US")}
                            </div>
                        ) : (
                            <div
                                className="post_review_date"
                                id="post_review_logged_in_date"
                            >
                                {new Date().toLocaleDateString("en-US")}
                            </div>
                        )}

                        <div>{Stars()}</div>
                        <ReviewButton />
                    </div>
                    {ReviewInput()}
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

const ReviewList = props => {
    let script = getScript("post")
    const [firstCharacter, setFirstCharacter] = useState()
    const [name, setName] = useState()

    let ReviewListStar = props => {
        let icons = []
        for (let i = 0; i < 5; i++) {
            if (i < props.star) {
                icons.push(<MdStar />)
            } else {
                icons.push(<MdStarBorder />)
            }
        }
        return <div className="post_review_star_container">{icons}</div>
    }

    function showReport(post_id) {
        document.getElementById("post_report_review_container").style.display =
            "flex"
        props.changeReviewId(post_id)
    }

    useEffect(() => {
        if (props.item.display_name === "full_name") {
            setFirstCharacter(props.item.first_name[0])
            setName(`${props.item.first_name} ${props.item.last_name}`)
        } else {
            setFirstCharacter(props.item.business_name[0])
            setName(props.item.business_name)
        }
    }, [props])
    return (
        <div className="post_review_list_container">
            <div className="post_review_image_container">{firstCharacter}</div>
            <div>
                <div className="post_review_list_top">
                    <div>{name}</div>
                    <div className="post_review_date">
                        {new Date(props.item.time).toLocaleDateString("en-US")}
                    </div>
                    <ReviewListStar star={props.item.star} />
                    <div
                        className="post_review_list_report"
                        onClick={() => showReport(props.item.id)}
                    >
                        {script.report}
                    </div>
                </div>
                <div className="post_review_text_container">
                    {props.item.text}
                </div>
            </div>
        </div>
    )
}

export default Review
