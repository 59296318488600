import React, { useState, useEffect } from "react"
import {
    Editor,
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
    convertToRaw
} from "draft-js"
import "draft-js/dist/Draft.css"
import "../../css/Description.css"

const Description = ({ updateParent }) => {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    )
    const [className, setClassName] = useState("RichEditor-editor")
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            setEditorState(newState)
        }
    }

    function toggleBlockType(blockType) {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType))
    }

    function toggleInlineStyle(inlineStyle) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
    }
    function mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                editorState,
                4 /* maxDepth */
            )
            if (newEditorState !== editorState) {
                setEditorState(newEditorState)
            }
            return
        }
        return getDefaultKeyBinding(e)
    }
    function changeHandler(e){
 	const contentState = e.getCurrentContent();
  	const oldContent = editorState.getCurrentContent();
  	const maxLength = 5000
  	if (contentState === oldContent || contentState.getPlainText().length <= maxLength) {
      		setEditorState(e);
  	}
    }

    useEffect(() => {
        var contentState = editorState.getCurrentContent()
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== "unstyled") {
                setClassName(e => e + " RichEditor-hidePlaceholder")
            }
        }
        updateParent("description", JSON.stringify(convertToRaw(contentState)))
    }, [editorState])
    return (
        <div id="description_container">
            <div className="RichEditor-root">
                <div id="RichEditor-title">Description</div>
                <div id="RichEditor-controls-continaer">
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={toggleBlockType}
                    />
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                </div>
                <div
                    className={className}
                    onClick={e => e.currentTarget.focus()}
                >
                    <Editor
                        blockStyleFn={getBlockStyle}
                        editorState={editorState}
                        //onChange={setEditorState}
                        onChange={changeHandler}
			handleKeyCommand={handleKeyCommand}
                        placeholder="Describe your portfolio..."
                        keyBindingFn={mapKeyToEditorCommand}
                        customStyleMap={styleMap}
                        spellCheck={true}
                    />
                </div>
            </div>
        </div>
    )
}

// class Description extends React.Component {
//     constructor(props) {
//         super(props)
//         this.state = { editorState: EditorState.createEmpty() }

//         this.focus = () => this.refs.editor.focus()
//         this.onChange = editorState => this.setState({ editorState })

//         this.handleKeyCommand = this._handleKeyCommand.bind(this)
//         this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this)
//         this.toggleBlockType = this._toggleBlockType.bind(this)
//         this.toggleInlineStyle = this._toggleInlineStyle.bind(this)
//     }

//     _handleKeyCommand(command, editorState) {
//         const newState = RichUtils.handleKeyCommand(editorState, command)
//         if (newState) {
//             this.onChange(newState)
//             return true
//         }
//         return false
//     }

//     _mapKeyToEditorCommand(e) {
//         if (e.keyCode === 9 /* TAB */) {
//             const newEditorState = RichUtils.onTab(
//                 e,
//                 this.state.editorState,
//                 4 /* maxDepth */
//             )
//             if (newEditorState !== this.state.editorState) {
//                 this.onChange(newEditorState)
//             }
//             return
//         }
//         return getDefaultKeyBinding(e)
//     }

//     _toggleBlockType(blockType) {
//         this.onChange(
//             RichUtils.toggleBlockType(this.state.editorState, blockType)
//         )
//     }

//     _toggleInlineStyle(inlineStyle) {
//         this.onChange(
//             RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
//         )
//     }

//     render() {
//         const { editorState } = this.state

//         If the user changes block type before entering any text, we can
//         either style the placeholder or hide it. Let's just hide it now.
//         let className = "RichEditor-editor"
//         var contentState = editorState.getCurrentContent()
//         if (!contentState.hasText()) {
//             if (contentState.getBlockMap().first().getType() !== "unstyled") {
//                 className += " RichEditor-hidePlaceholder"
//             }
//         }

//         return (
//             <div id="description_container">
//                 <div className="RichEditor-root">
//                     <BlockStyleControls
//                         editorState={editorState}
//                         onToggle={this.toggleBlockType}
//                     />
//                     <InlineStyleControls
//                         editorState={editorState}
//                         onToggle={this.toggleInlineStyle}
//                     />
//                     <div className={className} onClick={this.focus}>
//                         <Editor
//                             blockStyleFn={getBlockStyle}
//                             customStyleMap={styleMap}
//                             editorState={editorState}
//                             handleKeyCommand={this.handleKeyCommand}
//                             keyBindingFn={this.mapKeyToEditorCommand}
//                             onChange={this.onChange}
//                             placeholder="Describe your portfolio..."
//                             ref="editor"
//                             spellCheck={true}
//                         />
//                     </div>
//                 </div>
//                 <div>{JSON.stringify(editorState)}</div>
//                 <div>
//                     <Editor editorState={editorState} ref="editor2" />
//                 </div>
//             </div>
//         )
//     }
// }

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2
    }
}

function getBlockStyle(block) {
    switch (block.getType()) {
        case "blockquote":
            return "RichEditor-blockquote"
        default:
            return null
    }
}

class StyleButton extends React.Component {
    constructor() {
        super()
        this.onToggle = e => {
            e.preventDefault()
            this.props.onToggle(this.props.style)
        }
    }

    render() {
        let className = "RichEditor-styleButton"
        if (this.props.active) {
            className += " RichEditor-activeButton"
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        )
    }
}

const BLOCK_TYPES = [
    // { label: "H1", style: "header-one" },
    // { label: "H2", style: "header-two" },
    { label: "H1", style: "header-three" },
    { label: "H2", style: "header-four" },
    { label: "H3", style: "header-five" },
    { label: "H4", style: "header-six" },
    // { label: "Blockquote", style: "blockquote" },
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" }
    // { label: "Code Block", style: "code-block" }
]

const BlockStyleControls = props => {
    const { editorState } = props
    const selection = editorState.getSelection()
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType()

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map(type => (
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </div>
    )
}

var INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" }
    // { label: "Monospace", style: "CODE" }
]

const InlineStyleControls = props => {
    const currentStyle = props.editorState.getCurrentInlineStyle()

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type => (
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </div>
    )
}

export default Description
