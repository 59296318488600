import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { MdClose } from "react-icons/md"
import { MdKeyboardBackspace } from "react-icons/md"
import Subscription from "../components/subscription/Subscription"
import getScript from "../language"
import "../css/Create.css"

const Create = () => {
    const script = getScript("db_categories")
    const mainScript = getScript("main_categories")
    const userData = useSelector(state => state.user)
    const history = useHistory()
    const [categories, setCategories] = useState([])
    const [listToShow, setListToShow] = useState([])
    const [addCategory, setAddCategory] = useState()
    const [mobileShowGroups, setMobileShowGroups] = useState(true)

    function addCategoryClicked() {
        let container = document.getElementById(
            "create_category_add_category_container"
        )

        if (container.style.display === "flex") {
            container.style.display = "none"
        } else {
            container.style.display = "flex"
        }
    }

    function createCategory() {
        if (addCategory && addCategory !== "" && addCategory.length <= 100) {
            history.push(
                `/create/create_edit?category=15&category_name=${addCategory}`
            )
        }
    }

    function groupClicked(group, e, colorPath) {
        setMobileShowGroups(false)
        let doc = document.getElementById("create_category_group_container")
            .children
        for (let i = 0; i < doc.length; i++) {
            doc[i].style.borderBottom = "0px"
        }
        unColorAllImages()
        e.currentTarget.children[0].src = `/images/browse/${colorPath}`
        e.currentTarget.style.borderBottom = "2px solid black"
        if (group === "Other") {
            setListToShow(
                categories.filter(
                    item =>
                        item.group !== "Design" &&
                        item.group !== "Video & Audio" &&
                        item.group !== "Development" &&
                        item.group !== "Marketing" &&
                        item.group !== "Writing & Translation"
                )
            )
        } else {
            setListToShow(categories.filter(item => item.group === group))
        }
    }

    function unColorAllImages() {
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[0].src = "/images/browse/design.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[1].src = "/images/browse/video.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[2].src = "/images/browse/development.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[3].src = "/images/browse/marketing.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[4].src = "/images/browse/writing.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[5].src = "/images/browse/other.svg"
    }

    useEffect(() => {
        fetch("/create/create_availability", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === false) {
                    history.push("/create_failed")
                }
            })
        fetch("/category/get")
            .then(res => res.json())
            .then(res => {
                setCategories(res.categories)
                setListToShow(
                    res.categories.filter(item => item.group === "Design")
                )

                document.getElementById(
                    "create_category_group_container"
                ).children[0].style.borderBottom = "2px solid black"

                // for (let i = 0; i < res.categories.length; i++) {
                //     setCategories(list => [...list, res.categories[i].name])
                // }
            })
    }, [])

    return (
        <div>
            <div id="create_wrapper_desktop">
                <div id="create_category_title_container">
                    <div id="create_category_title">{mainScript.choose}</div>
                    <div
                        id="create_category_add_category"
                        onClick={addCategoryClicked}
                    >
                        {mainScript.add}
                    </div>
                </div>
                <div id="create_category_each">{mainScript.each}</div>
                <div id="create_category_group_container">
                    <li
                        onClick={e =>
                            groupClicked("Design", e, "design_color.svg")
                        }
                    >
                        <img
                            src="/images/browse/design_color.svg"
                            class="browse_search_categories_icon"
                            alt="Design"
                            style={{ paddingTop: "10px" }}
                        />
                        {mainScript.design}
                    </li>
                    <li
                        onClick={e =>
                            groupClicked("Video & Audio", e, "video_color.svg")
                        }
                    >
                        <img
                            src="/images/browse/video.svg"
                            class="browse_search_categories_icon"
                            alt="Video and Audio"
                            style={{ paddingTop: "10px" }}
                        />
                        {mainScript.video}
                    </li>
                    <li
                        onClick={e =>
                            groupClicked(
                                "Development",
                                e,
                                "development_color.svg"
                            )
                        }
                    >
                        <img
                            src="/images/browse/development.svg"
                            class="browse_search_categories_icon"
                            alt="Development"
                            style={{ paddingTop: "10px" }}
                        />
                        {mainScript.development}
                    </li>
                    <li
                        onClick={e =>
                            groupClicked("Marketing", e, "marketing_color.svg")
                        }
                    >
                        <img
                            src="/images/browse/marketing.svg"
                            class="browse_search_categories_icon"
                            alt="Marketing"
                            style={{ paddingTop: "10px" }}
                        />
                        {mainScript.marketing}
                    </li>
                    <li
                        onClick={e =>
                            groupClicked(
                                "Writing & Translation",
                                e,
                                "writing_color.svg"
                            )
                        }
                    >
                        <img
                            src="/images/browse/writing.svg"
                            class="browse_search_categories_icon"
                            alt="Writing"
                            style={{ paddingTop: "10px" }}
                        />
                        {mainScript.writing}
                    </li>
                    <li
                        onClick={e =>
                            groupClicked("Other", e, "other_color.svg")
                        }
                    >
                        <img
                            src="/images/browse/other.svg"
                            class="browse_search_categories_icon"
                            alt="Other"
                            style={{ paddingTop: "10px" }}
                        />
                        {mainScript.other}
                    </li>
                </div>
                <div id="create_category_list_container">
                    {listToShow.map(category => {
                        let path = `/create/create_edit?category=${category.id}&category_name=${category.name}`
                        return (
                            <li className="create_category_list">
                                <Link
                                    className="create_category_list_link"
                                    to={path}
                                >
                                    {script[category.id]}
                                </Link>
                            </li>
                        )
                    })}
                </div>
            </div>
            <div id="create_category_add_category_container">
                <div id="create_category_add_category_box">
                    <MdClose
                        id="create_category_add_category_close"
                        size={20}
                        onClick={addCategoryClicked}
                    />
                    <div id="create_category_add_category_title">
                        {mainScript.cant}
                    </div>
                    <input
                        id="create_category_add_category_input"
                        type="text"
                        placeholder={mainScript.your}
                        maxLength={100}
                        onChange={e => setAddCategory(e.currentTarget.value)}
                    />
                    <button
                        id="create_category_add_category_button"
                        onClick={createCategory}
                    >
                        {mainScript.add}
                    </button>
                </div>
            </div>
            <div id="create_wrapper_mobile">
                <div id="create_category_title_container">
                    <div id="create_category_title">{mainScript.choose}</div>
                    <div
                        id="create_category_add_category"
                        onClick={addCategoryClicked}
                    >
                        {mainScript.add}
                    </div>
                </div>
                <div id="create_category_each">{mainScript.each}</div>
                {mobileShowGroups ? (
                    <div id="create_group_container_mobile">
                        <li onClick={e => groupClicked("Design", e)}>
                            <img
                                src="/images/browse/design_color.svg"
                                class="browse_search_categories_icon"
                                alt="Design"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.design}
                        </li>
                        <li onClick={e => groupClicked("Video & Audio", e)}>
                            <img
                                src="/images/browse/video_color.svg"
                                class="browse_search_categories_icon"
                                alt="Video and Audio"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.video}
                        </li>
                        <li onClick={e => groupClicked("Development", e)}>
                            <img
                                src="/images/browse/development_color.svg"
                                class="browse_search_categories_icon"
                                alt="Development"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.development}
                        </li>
                        <li onClick={e => groupClicked("Marketing", e)}>
                            <img
                                src="/images/browse/marketing_color.svg"
                                class="browse_search_categories_icon"
                                alt="Marketing"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.marketing}
                        </li>
                        <li
                            onClick={e =>
                                groupClicked("Writing & Translation", e)
                            }
                        >
                            <img
                                src="/images/browse/writing_color.svg"
                                class="browse_search_categories_icon"
                                alt="Writing"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.writing}
                        </li>
                        <li onClick={e => groupClicked("Other", e)}>
                            <img
                                src="/images/browse/other_color.svg"
                                class="browse_search_categories_icon"
                                alt="Other"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.other}
                        </li>
                    </div>
                ) : (
                    <>
                        <div
                            id="create_mobile_back_button"
                            onClick={() => setMobileShowGroups(true)}
                        >
                            <MdKeyboardBackspace />
                            Back
                        </div>
                        <div id="create_list_container">
                            {listToShow.map(category => {
                                let path = `/create/create_edit?category=${category.id}&category_name=${category.name}`
                                return (
                                    <li className="categories_list">
                                        <Link
                                            className="categories_list_link"
                                            to={path}
                                        >
                                            {script[category.id]}
                                        </Link>
                                    </li>
                                )
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Create
