import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import LazyLoad from "react-lazyload"
import "../css/Recently_Viewed.css"

const Recently_Viewed = props => {
    const history = useHistory()
    const userData = useSelector(state => state.user)
    const [list, setList] = useState([])

    function viewPost(id) {
        history.push(`/post?id=${id}`)
    }

    function sendMessage(post_id, title, to_id) {
        if (userData.token && userData.token !== "undefined") {
            props.socket.emit("send message from post", {
                post_id: post_id,
                message: title,
                token: userData.token,
                to: to_id
            })
            props.socket.on("send message from post response", e => {
                if (e.status === "succeed") {
                    history.push(
                        `/messenger?token=djiqwd33irjnc25Rasdj0dijdascgNIDDNswqrnt&room=${e.room}&post_user=${e.post_user_id}&to=${e.post_user_name}&phone=${e.phone}&link=${e.link}&email=${e.email}`
                    )
                } else if (e.status === "unauthorize") {
                    props.passedFunction("Sign in to", "pop")
                    document.getElementById("loginForm").style.display = "flex"
                }
            })
        } else {
            props.passedFunction("Sign in to", "pop")
            document.getElementById("loginForm").style.display = "flex"
        }
    }

    useEffect(() => {
        if (userData.token && userData.token !== "undefined") {
            fetch("/post/get_recently_viewed", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                },
                body: JSON.stringify({})
            })
                .then(res => {
                    return res.json()
                })
                .then(res => {
                    setList(res.results)
                })
        }
    }, [])
    return (
        <div>
            <div id="recently_viewed_title">Recent Views</div>
            {list.map(item => {
                let url = `https://adplin.s3.amazonaws.com/${
                    item.id
                }/resize/cover_${item.main_image.split(".")[0]}.jpeg`

                let name
                if (item.display_name === "full_name") {
                    name = `${item.first_name} ${item.last_name}`
                } else {
                    name = item.business_name
                }
                return (
                    <LazyLoad
                        offset={500}
                        style={{ width: "100%" }}
                        once
                        placeholder={
                            <div
                                style={{
                                    height: "200px",
                                    width: "100%",
                                    backgroundColor: "rgb(231 230 230)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                Scroll to see more
                            </div>
                        }
                    >
                        <li className="recently_viewed_grid_container recently_viewed_list">
                            <img
                                src={url}
                                alt={item.title}
                                className="recently_viewed_images"
                                onClick={() => viewPost(item.id)}
                            />
                            <div className="recently_viewed_grid_container2">
                                <div onClick={() => viewPost(item.id)}>
                                    {item.title}
                                </div>
                                <div onClick={() => viewPost(item.id)}>
                                    {name}
                                </div>
                                <div className="recently_viewed_list_buttons">
                                    <div onClick={() => viewPost(item.id)}>
                                        View
                                    </div>
                                    <div
                                        onClick={() =>
                                            sendMessage(
                                                item.id,
                                                item.title,
                                                item.user_id
                                            )
                                        }
                                    >
                                        Message
                                    </div>
                                </div>
                            </div>
                        </li>
                    </LazyLoad>
                )
            })}
        </div>
    )
}

export default Recently_Viewed
