import React, { useEffect } from "react"

const Post_Square = () => {
    useEffect(() => {
        try {
            let parentElement = document.getElementById(
                "post_desktop_container"
            )
            if (getComputedStyle(parentElement).display !== "none") {
                ;(window.adsbygoogle = window.adsbygoogle || []).push({})
            }
            let wrapper = document.getElementById("body_wrapper")
            const observer = new MutationObserver((mutations, observer) => {
                wrapper.style.height = ""
                wrapper.style.minHeight = "75vh"
            })
            observer.observe(wrapper, {
                attributes: true,
                attributeFilter: ["style"]
            })
        } catch (err) {
            //console.log(err)
        }
    }, [])

    return (
        <div style={{ width: "100%", maxHeight: "350px", marginTop: "15px" }}>
            <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-3878773813404073"
                data-ad-slot="5637420974"
                data-ad-format="rectangle"
                data-full-width-responsive="true"
            />
        </div>
    )
}

export default Post_Square
