import React, { useState, useEffect } from "react"
import "../../css/Tag.css"

const Tag = ({ updateParent }) => {
    const [tag, setTag] = useState([])
    const [tagInput, setTagInput] = useState("")
    const [tagInputId, setTagInputId] = useState("create_edit_tag_input_black")
    const [placeholder, setPlaceholder] = useState("Logo")
    const [placeholderCount, setPlaceholderCount] = useState(0)
    const [message, setMessage] = useState("")
    function addHandler() {
        if (!validate()) {
            return
        }
        if (tag.length < 5) {
            setTag(old => [...old, tagInput])
        } else {
            setMessage("You can add up to 5 tags.")
        }

        setTagInput("")
    }
    function handleKeyDown(e) {
        if (e.key === "Enter") {
            if (!validate()) {
                return
            }
            if (tag.length < 5) {
                setTag(old => [...old, tagInput])
            } else {
                setMessage("You can add up to 5 tags.")
            }
            setTagInput("")
        }
    }

    function validate() {
        if (tagInput === "") {
            return false
        }
        if (tag.indexOf(tagInput) !== -1) {
            setTagInput("")
            return false
        }
        if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(tagInput)) {
            setTagInput("")
            setMessage("You can not insert special character.")
            return false
        }
        return true
    }

    function deleteHandler(e) {
        if (tag.length >= 5) {
            setMessage("")
        }
        setTag(
            tag.filter(item => item !== e.currentTarget.getAttribute("item"))
        )
    }

    useEffect(() => {
        updateParent("tag", tag)
    }, [tag])

    useEffect(() => {
        if (tagInput.length >= 15) {
            setTagInputId("create_edit_tag_input_red")
        } else {
            setTagInputId("create_edit_tag_input_black")
        }
    }, [tagInput])

    useEffect(() => {
        let changePlaceholder = setInterval(() => {
            let lists = [
                "Logo",
                "Mobile App",
                "Business",
                "Marketing",
                "Video Edit"
            ]
            let newCount
            if (placeholderCount === lists.length - 1) {
                setPlaceholderCount(0)
                newCount = 0
            } else {
                setPlaceholderCount(placeholderCount + 1)
                newCount = placeholderCount + 1
            }
            setPlaceholder(lists[newCount])
        }, 1000)
        return () => {
            clearInterval(changePlaceholder)
        }
    })
    return (
        <div>
            <div id="create_edit_tag_title_container">
                <div className="create_edit_title">Tag</div>
                <div>
                    Add up to 5 tags to help clients to find your post easily.
                </div>
            </div>
            <div id="create_edit_tag_item_container">
                {tag.map(item => {
                    return (
                        <div className="create_edit_tag_item">
                            <div
                                className="create_edit_tag_item_delete"
                                item={item}
                                onClick={deleteHandler}
                            >
                                X
                            </div>
                            <div>{item}</div>
                        </div>
                    )
                })}
            </div>
            <div id="create_edit_tag_input_container">
                <input
                    type="text"
                    value={tagInput}
                    maxlength="15"
                    onChange={e => setTagInput(e.currentTarget.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={placeholder}
                />
                <div id={tagInputId}>{tagInput.length}/15</div>
                <button value="Add" onClick={addHandler}>
                    Add
                </button>
            </div>
            <div id="create_edit_tag_message">{message}</div>
        </div>
    )
}

export default Tag
