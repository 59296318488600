exports.logIn = (email, from, token) => {
    return {
        type: "LogIn",
        payload: { email, from, token }
    }
}

exports.logOut = () => {
    return {
        type: "LogOut",
        payload: {}
    }
}

exports.updateToken = token => {
    return {
        type: "UpdateToken",
        payload: { token }
    }
}

exports.updateLanguage = (language) => {
    return {
	type: "UpdateLanguage",
	payload: {language}
    }	
}
