import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { FiPlus } from "react-icons/fi"
import { TiDeleteOutline } from "react-icons/ti"
import "../../css/UploadMultipleFiles.css"

const UploadMultipleFiles = ({ updateParent }) => {
    return (
        <div id="uploadMultipleFiles_container_layout">
            <Upload updateParent={updateParent} />
        </div>
    )
}

const Upload = ({ updateParent }) => {
    const userData = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [originalFileList, setOriginalFileList] = useState([])
    const [message, setMessage] = useState("")

    function changeHeightOfDiv() {
        let classes = document.getElementsByClassName(
            "uploadMultipleFiles_file_display"
        )
        for (let i = 0; i < classes.length; i++) {
            classes[i].style.height = `${
                document.getElementById(
                    "uploadMultipleFiles_upload_button_container"
                ).clientWidth / 1.33
            }px`
        }
        document.getElementById(
            "uploadMultipleFiles_upload_button_container"
        ).style.height = `${
            document.getElementById(
                "uploadMultipleFiles_upload_button_container"
            ).clientWidth / 1.33
        }px`
    }

    function handleDelete2(e) {
        console.log(`DELETE ${e.currentTarget.value}`)
        console.log(fileList)
        setFileList(fileList.filter(item => item !== e.currentTarget.value))
        setOriginalFileList(
            originalFileList.filter(item => item !== e.currentTarget.value)
        )
    }

    function handleDelete(e, fileList) {
        let parentNode = e.target.parentNode
        while (
            parentNode.getAttribute("class") !==
            "uploadMultipleFiles_file_display"
        ) {
            parentNode = parentNode.parentNode
        }
        console.log(parentNode.getAttribute("filename"))
        let filenameOfParentNode = parentNode.getAttribute("filename")
        parentNode.remove()
        //Delete file from filelist
        var array = [...fileList] // make a separate copy of the array
        console.log(filenameOfParentNode)
        console.log(array)
        console.log(fileList)
        console.log(fileList.indexOf(filenameOfParentNode))
        var index = fileList.indexOf(filenameOfParentNode)
        console.log(`index of file = ${index}`)
        document.getElementById(
            "uploadMultipleFiles_delete_handler"
        ).value = filenameOfParentNode
        document.getElementById("uploadMultipleFiles_delete_handler").click()

        //if (index !== -1) {
        //array.splice(index, 1);
        // setFileList(
        //     fileList.filter(
        //         item => item !== parentNode.getAttribute("filename")
        //     )
        // )
        //setFileList(array=>array.splice(index,1));
        //}
    }

    function validate(file) {
        const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png"
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isJpgOrPng) {
            setMessage("You can only upload JPG/PNG file!")
        } else if (!isLt20M) {
            setMessage("Image must smaller than 20MB!")
        } else {
            setMessage("")
        }
        return isJpgOrPng && isLt20M
    }

    function fileChanged(e) {
        if (!validate(e.currentTarget.files[0])) {
            return
        }
        setLoading(true)
        var div = document.createElement("div")
        var div_hover = document.createElement("div")
        var img = document.createElement("img")
        //div.innerHTML = "NEW IMAGE"
        // set style
        //div.style.color = 'red';
        // better to use CSS though - just set class
        //div.setAttribute("onmouseover", "(e)=>{return console.log(e)}")
        div.setAttribute("class", "uploadMultipleFiles_file_display")
        div_hover.setAttribute(
            "class",
            "uploadMultipleFiles_file_display_hover"
        )
        img.setAttribute("class", "uploadMultipleFiles_file_display_image")
        div.appendChild(img)
        div.appendChild(div_hover)
        let deleteComponent = document.getElementById(
            "uploadMultipleFiles_delete_component"
        )
        let newDeleteComponent = deleteComponent.cloneNode(true)
        newDeleteComponent.style.display = "flex"
        newDeleteComponent.onclick = e => {
            return handleDelete(e, fileList)
        }
        div_hover.appendChild(newDeleteComponent)
        document
            .getElementById("uploadMultipleFiles_container")
            .appendChild(div)
        let divCounter = document.getElementById(
            "uploadMultipleFiles_container"
        ).childElementCount
        // if (divCounter <= 4) {
        //     document.getElementById(
        //         "uploadMultipleFiles_container"
        //     ).style.gridTemplateColumns = "repeat(2, 1fr)"
        // } else if (divCounter > 4) {
        //     document.getElementById(
        //         "uploadMultipleFiles_container"
        //     ).style.gridTemplateColumns = "repeat(4, 1fr)"
        // }
        changeHeightOfDiv()

        let formData = new FormData()
        formData.append("sub", e.currentTarget.files[0])
        formData.append(
            "file_number",
            document.getElementsByClassName("uploadMultipleFiles_file_display")
                .length
        )
        fetch("/create/temp/upload_sub", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`
            },
            body: formData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.status === "succeed") {
                    //info.file.status = "done"
                    //setImageUrl(`/images/tmp/${res.id}/main${res.ext}`)
                    setFileList(old => [...old, res.filename])
                    setOriginalFileList(old => [
                        ...old,
                        res.filename.split(".")[0] + res.ext
                    ])
                    img.setAttribute(
                        "src",
                        `/images/tmp/${res.id}/resize/${res.filename}`
                    )
                    div.setAttribute("filename", `${res.filename}`)
                } else if (res.status === "unauthorized") {
                }
                setLoading(false)
            })
        document.getElementById("uploadMultipleFiles_input").value = ""
    }

    function dropHandler(e) {
        e.preventDefault()
        e.stopPropagation()
        if (!validate(e.dataTransfer.files[0])) {
            return
        }
        setLoading(true)
        var div = document.createElement("div")
        var div_hover = document.createElement("div")
        var img = document.createElement("img")
        //div.innerHTML = "NEW IMAGE"
        // set style
        //div.style.color = 'red';
        // better to use CSS though - just set class
        //div.setAttribute("onmouseover", "(e)=>{return console.log(e)}")
        div.setAttribute("class", "uploadMultipleFiles_file_display")
        div_hover.setAttribute(
            "class",
            "uploadMultipleFiles_file_display_hover"
        )
        img.setAttribute("class", "uploadMultipleFiles_file_display_image")
        div.appendChild(img)
        div.appendChild(div_hover)
        let deleteComponent = document.getElementById(
            "uploadMultipleFiles_delete_component"
        )
        let newDeleteComponent = deleteComponent.cloneNode(true)
        newDeleteComponent.style.display = "flex"
        newDeleteComponent.onclick = e => {
            return handleDelete(e, fileList)
        }
        div_hover.appendChild(newDeleteComponent)
        document
            .getElementById("uploadMultipleFiles_container")
            .appendChild(div)
        let divCounter = document.getElementById(
            "uploadMultipleFiles_container"
        ).childElementCount
        changeHeightOfDiv()

        let formData = new FormData()
        formData.append("sub", e.dataTransfer.files[0])
        formData.append(
            "file_number",
            document.getElementsByClassName("uploadMultipleFiles_file_display")
                .length
        )
        fetch("/create/temp/upload_sub", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`
            },
            body: formData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.status === "succeed") {
                    //info.file.status = "done"
                    //setImageUrl(`/images/tmp/${res.id}/main${res.ext}`)
                    setFileList(old => [...old, res.filename])
                    setOriginalFileList(old => [
                        ...old,
                        res.filename.split(".")[0] + res.ext
                    ])
                    img.setAttribute(
                        "src",
                        `/images/tmp/${res.id}/resize/${res.filename}`
                    )
                    div.setAttribute("filename", `${res.filename}`)
                } else if (res.status === "unauthorized") {
                }
                setLoading(false)
            })
    }
    function dragHandler(e) {
        e.preventDefault()
        e.stopPropagation()
    }
    function openUpload() {
        document.getElementById("uploadMultipleFiles_input").click()
    }

    useEffect(() => {
        updateParent("files", fileList)
    }, [fileList])

    useEffect(() => {
        updateParent("originalFiles", originalFileList)
    }, [originalFileList])

    useEffect(() => {
        // document.getElementById(
        //     "uploadMultipleFiles_container"
        // ).style.gridTemplateColumns = "repeat(2, 1fr)"
        changeHeightOfDiv()
    }, [])
    return (
        <div>
            <div id="uploadMultipleFiles_container">
                <div id="uploadMultipleFiles_upload_button_container">
                    <button
                        id="uploadMultipleFiles_upload_button"
                        onClick={openUpload}
                        onDrop={dropHandler}
                        onDragOver={dragHandler}
                    >
                        <FiPlus />
                        <div>Upload files</div>
                    </button>
                    <input
                        type="file"
                        id="uploadMultipleFiles_input"
                        onChange={fileChanged}
                    />
                    <input
                        id="uploadMultipleFiles_delete_handler"
                        onClick={handleDelete2}
                        value=""
                        style={{ display: "none" }}
                    ></input>
                    <TiDeleteOutline
                        id="uploadMultipleFiles_delete_component"
                        size={40}
                        color="white"
                        style={{ display: "none" }}
                    />
                </div>
            </div>
            <div id="create_edit_upload_multiple_files_message">{message}</div>
        </div>
    )
}

export default UploadMultipleFiles
