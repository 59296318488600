import React from "react"
import Subscription from "../components/subscription/Subscription.js"
import "../css/Create_Failed.css"

const Create_Failed = () => {
    return (
        <div>
            <div id="create_failed_text_container">
                <div id="create_failed_title">
                    You have already created more than 5 posts. Subscribe us and
                    create unlimited number of posts.
                </div>
                <img src="/images/logo_icon.png" id="create_success_image" />
            </div>
            <div>
                <Subscription />
            </div>
        </div>
    )
}

export default Create_Failed
