import React from "react"
import { AiOutlineShareAlt } from "react-icons/ai"
import "../../css/post/Title.css"

const Title = props => {
    function copy() {
        var textField = document.createElement("textarea")
        textField.innerText = `https://adplin.com/post?id=${props.id}`
        document.body.appendChild(textField)
        textField.select()
        document.execCommand("copy")
        textField.remove()

        let copyMessage = document.getElementById("post_copy_message")
        copyMessage.style.display = "flex"
        setTimeout(() => {
            if (copyMessage) {
                copyMessage.style.display = "none"
            }
        }, 2000)
    }
    return (
        <div id="post_title_container">
            <div>{props.title}</div>
            <AiOutlineShareAlt id="post_title_share" onClick={copy} />
        </div>
    )
}

export default Title
