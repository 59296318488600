import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import Desktop_Navigation from "../components/category_list/Desktop_Navigation"
import Mobile_Navigation from "../components/category_list/Mobile_Navigation"
import Top_Posts from "../components/category_list/Top_Posts"
import Discover from "../components/category_list/Discover"
import Loading from "../components/Loading"
import Message from "../components/Message"
import getScript from "../language"
import "../css/category_list/Category_List.css"

const Category_list = props => {
    const userData = useSelector(state => state.user)
    const script = getScript("db_categories")
    const [loading, setLoading] = useState(true)
    const [notFound, setNotFound] = useState(false)
    const [category, setCategory] = useState()
    const [list, setList] = useState([])
    const [other_list, setOther_list] = useState()
    const [top_post_list, setTop_post_list] = useState([])

    let query = new URLSearchParams(useLocation().search)

    useEffect(() => {
        if (query.get("id")) {
            let originalMeta = document.head.querySelector(
                'meta[name="description"]'
            ).content
            let option
            if (userData.token) {
                option = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData.token}`
                    }
                }
            } else {
                option = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
            fetch(`/category/get/info?id=${query.get("id")}`, option)
                .then(res => res.json())
                .then(res => {
                    if (!res.category) {
                        setNotFound(true)
                    } else {
                        if (!res.list.length) {
                            setNotFound(true)
                        } else {
                            setNotFound(false)
                        }
                        setCategory(script[res.category.id])
                        setOther_list(res.other_categories)
                        setList(res.list)
                        setTop_post_list(res.top_post_list)
                        document.head.querySelector(
                            'meta[name="description"]'
                        ).content = `Looking for ${res.category.name} services? Browse AdPlin and compare by skills, reviews, and price. Select the right Business or Freelancer to meet your needs and budget.`
                        document.title = `AdPlin | Category - ${res.category.name}`
                    }
                    setLoading(false)
                })
            return () => {
                document.head.querySelector(
                    'meta[name="description"]'
                ).content = originalMeta
            }
        }
    }, [query.get("id")])

    useEffect(() => {
        if (query.get("group")) {
            let originalMeta = document.head.querySelector(
                'meta[name="description"]'
            ).content

            let option
            if (userData.token) {
                option = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData.token}`
                    }
                }
            } else {
                option = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }

            fetch(
                `/category/get/info?group=${encodeURIComponent(
                    query.get("group")
                )}`,
                option
            )
                .then(res => res.json())
                .then(res => {
                    if (!res.category) {
                        setNotFound(true)
                    } else {
                        if (!res.list.length) {
                            setNotFound(true)
                        } else {
                            setNotFound(false)
                        }
                        setCategory(query.get("group"))
                        console.log(`group = ${query.get("group")}`)
                        setOther_list(res.other_categories)
                        setList(res.list)
                        setTop_post_list(res.top_post_list)
                        document.head.querySelector(
                            'meta[name="description"]'
                        ).content = `Looking for ${query.get(
                            "group"
                        )} services? Browse AdPlin and compare by skills, reviews, and price. Select the right Business or Freelancer to meet your needs and budget.`
                        document.title = `AdPlin | Category - ${query.get(
                            "group"
                        )}`
                    }
                    setLoading(false)
                })
            return () => {
                document.head.querySelector(
                    'meta[name="description"]'
                ).content = originalMeta
            }
        }
    }, [query.get("group")])

    if (loading) {
        return <Loading />
    } else {
        return (
            <div>
                <Desktop_Navigation group={category} other_list={other_list} />
                {notFound ? (
                    <Message
                        message={
                            "No posts yet for this category. Be the first person to advertise on this service!"
                        }
                    />
                ) : (
                    <>
                        <Top_Posts
                            list={top_post_list}
                            passedFunction={props.passedFunction}
                        />
                        <Discover
                            list={list}
                            passedFunction={props.passedFunction}
                        />
                    </>
                )}
            </div>
        )
    }
}

export default Category_list
