import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Subscription from "../components/subscription/Subscription.js"
import "../css/Create_Success.css"

const Create_Success = () => {
    const userData = useSelector(state => state.user)
    const [showMessage, setShowMessage] = useState(false)

    function register() {
        fetch("/stripe/connect/onboard-user", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.url) {
                    window.location = res.url
                }
            })
    }

    useEffect(() => {
        fetch("/stripe/checkChargeEnabled", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.status === "disabled") {
                    setShowMessage(true)
                    //history.push("/create_success")
                } else {
                    console.log(res.status)
                }
            })
    }, [])
    return (
        <div>
            <div id="create_success_text_container">
                <div id="create_success_title">YOUR POST IS NOW CREATED!</div>
                <Link id="create_success_button" to="/my_posts">
                    Click to see your posts
                </Link>
                <img src="/images/logo_icon.png" id="create_success_image" />
            </div>
            <div>
                <Subscription />
            </div>
            {showMessage ? (
                <div>
                    <div>
                        Would you like to register to accept payments from
                        customers?
                    </div>
                    <button onClick={register}>Yes</button>
                    <button
                        onClick={() => {
                            setShowMessage(false)
                        }}
                    >
                        No
                    </button>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default Create_Success
