import React from "react"
import { withRouter } from "react-router-dom"
import { GA_TRACKING_ID } from "../config/config"

class GoogleAnalytics extends React.Component {
    componentWillUpdate({ location, history }) {
        const gtag = window.gtag
        let title = location.pathname
        if (title === "/") {
            title = "Online marketplace for freelance and business services."
        } else if (title.includes("/")) {
            let splited = title.split("/")
            title = splited[splited.length - 1]
        }
        document.title = "AdPlin | " + title
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return
        }

        if (history.action === "PUSH" && typeof gtag === "function") {
            gtag("config", GA_TRACKING_ID, {
                page_title: location.pathname,
                page_location: window.location.href,
                page_path: location.pathname
            })
        }
    }

    render() {
        return null
    }
}

export default withRouter(GoogleAnalytics)
