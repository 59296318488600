import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { FiPlus } from "react-icons/fi"
import "../../css/UploadSingleFile.css"

const UploadSingleFile = ({ updateParent, post_id, mainFile }) => {
    const userData = useSelector(state => state.user)
    const [changed, setChanged] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")

    function openUpload() {
        document.getElementById("uploadSingleFile_input").click()
    }

    function validate(file) {
        const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png"
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isJpgOrPng) {
            setMessage("You can only upload JPG/PNG file!")
        } else if (!isLt20M) {
            setMessage("Image must smaller than 20MB!")
        } else {
            setMessage("")
        }
        return isJpgOrPng && isLt20M
    }

    function changeHandler(e) {
        if (!validate(e.currentTarget.files[0])) {
            return
        }
        setLoading(true)
        //fetch to server
        let formData = new FormData()
        formData.append("main", e.currentTarget.files[0])
        fetch("/create/temp/upload", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`
            },
            body: formData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.status === "succeed") {
                    // setImageUrl(`/images/tmp/${res.id}/main${res.ext}`)
                    // // updateParent("main", `/images/tmp/${res.id}/main${res.ext}`)
                    // updateParent("main", `main${res.ext}`)
                    setImageUrl(`/images/tmp/${res.id}/resize/${res.filename}`)
                    //updateParent("main", `main${res.ext}`)
		    updateParent("main", `${res.filename}`)
                    updateParent(
                        "originalMain",
                        res.filename.split(".")[0] + res.ext
                    )

                    updateParent("main_changed", true)
                } else if (res.status === "unauthorized") {
                }
                setLoading(false)
            })

        e.currentTarget.value = ""
    }

    const Loading = () => {
        return (
            <div id="uploadSingleFile_upload_button">
                <div class="uploadSingleFile_upload_lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (post_id !== undefined && mainFile !== undefined) {
            setImageUrl(
                `https://adplin.s3.amazonaws.com/${post_id}/resize/${mainFile}`
            )
        }
    }, [post_id, mainFile])

    useEffect(() => {
        //resize button
        const resize = () => {
            document.getElementById(
                "uploadSingleFile_upload_button"
            ).style.height =
                document.getElementById("uploadSingleFile_upload_button")
                    .clientWidth /
                    1.33 +
                "px"
        }

        resize()
        window.addEventListener("resize", resize)
        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [])
    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    {imageUrl ? (
                        <div
                            id="uploadSingleFile_upload_button"
                            onClick={openUpload}
                            onLoad={() => {
                                document.getElementById(
                                    "uploadSingleFile_upload_button"
                                ).style.height =
                                    document.getElementById(
                                        "uploadSingleFile_upload_button"
                                    ).clientWidth /
                                        1.33 +
                                    "px"
                            }}
                        >
                            <img
                                src={
                                    imageUrl +
                                    `?ver=${Math.floor(Math.random() * 100000)}`
                                }
                                alt="main image"
                                id="uploadSingleFile_upload_image"
                            />
                        </div>
                    ) : (
                        <button
                            id="uploadSingleFile_upload_button"
                            onClick={openUpload}
                        >
                            <FiPlus />
                            <div>Upload files</div>
                        </button>
                    )}
                </div>
            )}
            <input
                type="file"
                id="uploadSingleFile_input"
                onChange={changeHandler}
            />
            <div id="create_edit_upload_single_file_message">{message}</div>
        </div>
    )
}

export default UploadSingleFile
