import React from "react"
import { useLocation } from "react-router-dom"

const Message = props => {
    let query = new URLSearchParams(useLocation().search)
    let text = query.get("text")
    console.log(props)
    function showLoginForm() {
        props.passedFunction("Sign in to")
        document.getElementById("loginForm").style.display = "flex"
    }
    let Message = ""
    if (text === "alreadyverified") {
        Message = "Your email has been already verified."
    } else if (text === "verified") {
        Message = "Thank you for joining us."
    } else if (text === "unauthorized") {
        Message = "Unauthorized Request"
    }
    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "600px",
                top: "20px"
                // backgroundImage: `url("/images/message/message_test.jpg")`
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "600px",
                    //position: "absolute",
                    left: "0",
                    top: "0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <img
                    src="/images/logo_black.svg"
                    alt="AdPlin Logo"
                    width="200"
                />
                <div style={{ fontSize: "30px", margin: "20px" }}>
                    {Message}
                </div>
                <div style={{ textAlign: "center" }}>
                    We are exited to see how you will contribute to our
                    community.
                </div>
                <div
                    style={{
                        width: "200px",
                        height: "50px",
                        color: "white",
                        backgroundImage:
                            "linear-gradient(45deg, rgb(169 70 210 / 1), #7f52d6b0)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        margin: "20px",
                        transition: "ease-in 0.3s"
                    }}
                    onMouseEnter={e => {
                        e.currentTarget.style.backgroundColor =
                            "rgb(169 70 210 / 1)"
                    }}
                    onMouseLeave={e => {
                        e.currentTarget.style.backgroundColor = ""
                        e.currentTarget.style.backgroundImage =
                            "linear-gradient(45deg, rgb(169 70 210 / 1), #7f52d6b0)"
                    }}
                    onClick={showLoginForm}
                >
                    Click to Log in
                </div>
            </div>
        </div>
    )
}

export default Message
