import React, { useState, useEffect } from "react"
import { useLocation, Link } from "react-router-dom"
import AccountMain from "../components/setting/AccountMain"
import Language_Setting from "../components/setting/Language_Setting"
import Subscription_Setting from "./Subscription_Setting"
import "../css/Setting.css"

const Setting = props => {
    const [page, setPage] = useState("account")
    let query = new URLSearchParams(useLocation().search)

    useEffect(() => {
        if (query.get("page")) {
            setPage(query.get("page"))
        }
    }, [query])
    return (
        <div id="setting_container">
            <ul id="setting_navigation_container">
                <Link
                    to={"/setting?page=account"}
                    className={
                        page === "account" ? "setting_navigation_selected" : ""
                    }
                >
                    Account
                </Link>
                <Link
                    to={"/setting?page=subscription"}
                    className={
                        page === "subscription"
                            ? "setting_navigation_selected"
                            : ""
                    }
                >
                    Subscription
                </Link>
                <Link
                    to={"/setting?page=language"}
                    className={
                        page === "language" ? "setting_navigation_selected" : ""
                    }
                >
                    Language
                </Link>
            </ul>
            {page === "account" ? (
                <AccountMain passedFunction={props.passedFunction} />
            ) : (
                <></>
            )}
            {page === "subscription" ? <Subscription_Setting /> : <></>}
            {page === "language" ? (
                <Language_Setting changeLanguage={props.changeLanguage} />
            ) : (
                <></>
            )}
        </div>
    )
}

export default Setting
