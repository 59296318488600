import React, { useEffect } from "react"
import Browse_search from "../components/browse/Browse_search"
import Trending from "../components/browse/Trending"
import Trending2 from "../components/browse/Trending2"
import Premium from "../components/browse/Premium"
import Trending_week from "../components/browse/Trending_week"
import Browse_categories from "../components/browse/Browse_categories"
import Browse_Horizontal from "../components/adsense/Browse_Horizontal"
import Popular_list from "../components/browse/Popular_list"

import "../css/Browse.css"

const Browse = props => {
    useEffect(() => {
        let originalMeta = document.head.querySelector(
            'meta[name="description"]'
        ).content

        document.head.querySelector(
            'meta[name="description"]'
        ).content = `Browse talented freelancers and business.`

        return () => {
            document.head.querySelector(
                'meta[name="description"]'
            ).content = originalMeta
        }
    }, [])
    return (
        <div>
            <Browse_search />
            <Trending2 passedFunction={props.passedFunction} />
            {/*<div id="browse_top_container">
                <Trending />
                <div id="browse_top_right_container">
                    <Premium />
                    <Trending_week />
                </div>
            </div>*/}
            <Browse_categories />
            <Browse_Horizontal />
            <Popular_list
                passedFunction={props.passedFunction}
                category_id={8}
            />
            <Popular_list
                passedFunction={props.passedFunction}
                category_id={1}
            />
            <Popular_list
                passedFunction={props.passedFunction}
                category_id={11}
            />
        </div>
    )
}

export default Browse
