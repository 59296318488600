import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Loading from "../components/Loading"
import Subscription from "../components/subscription/Subscription"

const Subscription_Setting = () => {
    const userData = useSelector(state => state.user)
    const [loading, setLoading] = useState(true)
    const [stripe_customer_id, setStrip_customer_id] = useState()
    const [stripe_expiration_date, setStripe_expiration_date] = useState()
    const [manage_url, setManage_url] = useState()

    useEffect(() => {
        fetch("/stripe/get_stripe_customer_id", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`
            }
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                console.log(res)
                if (res.status === "succeed") {
                    setStrip_customer_id(res.stripe_customer_id)
                    setStripe_expiration_date(res.stripe_expiration_date)
                    setManage_url(res.url)
                    if (
                        res.stripe_customer_id &&
                        new Date(res.stripe_expiration_date) > new Date()
                    ) {
                        window.location = res.url
                    }
                }
                setLoading(false)
            })
    }, [])

    if (loading) {
        return <Loading />
    } else {
        return (
            <div>
                {stripe_customer_id &&
                new Date(stripe_expiration_date) > new Date() ? (
                    <div>
                        <a href={manage_url}>UPDATE YOUR Subscription</a>
                    </div>
                ) : (
                    <Subscription />
                )}
            </div>
        )
    }
}

export default Subscription_Setting
