exports.apiConfig = {
    facebook_app_id: 381146773100818,
    //facebook_app_id: 788273688687310,
    google_app_id:
        "314874372750-v04saja364q3qbqqi928l5r17dneet3l.apps.googleusercontent.com"
}

//exports.companyName = "Adgency"
exports.companyName = "AdPlin"

//exports.mainColor = "#59007ea6"
//exports.mainColor = "#59007e"
exports.mainColor = "#a946d2"

exports.GA_TRACKING_ID = "G=97J8HMTX3Z"
