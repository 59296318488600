import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { IoArrowBack } from "react-icons/io5"
import { RiSendPlaneFill } from "react-icons/ri"
import { BiPhone, BiLinkExternal } from "react-icons/bi"
import { AiOutlineMail } from "react-icons/ai"
import "../../css/messenger/MessageDetailMobile.css"

const MessageDetailMobile = props => {
    const userData = useSelector(state => state.user)
    const history = useHistory()
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    function sendHandler() {
        if (message.length === 0) {
            return
        }
        createMessage(message, "send")
        props.socket.emit("send message", {
            message: message,
            token: userData.token,
            room: props.room
        })
        props.changeMessageList("message", message, props.room)
        props.changeMessageList("time", new Date(), props.room)
        props.moveToTop(props.room)
        setMessage("")
    }

    function createMessagePrepend(text, type, post_id, url) {
        let messageBoard = document.getElementById(
            "messenger_messageDetailMobile_board"
        )
        let item = document.createElement("li")
        if (type === "post") {
            item.innerHTML = `<img src=${url} alt="post image" onLoad='document.getElementById("messenger_messageDetailMobile_board").scrollTop = document.getElementById("messenger_messageDetailMobile_board").scrollTop + parseInt(getComputedStyle(this).height)'/><div>${text}</div>`
            item.onclick = function () {
                history.push(`/post?id=${post_id}`)
            }
        } else {
            item.innerText = text
        }
        item.setAttribute(
            "class",
            `messenger_messageDetail_board_list messenger_messageDetail_board_list_${type}`
        )
        //messageBoard.appendChild(item)
        messageBoard.prepend(item)
        //messageBoard.scrollTop = messageBoard.scrollHeight
    }

    function createMessage(text, type, post_id, url) {
        let messageBoard = document.getElementById(
            "messenger_messageDetailMobile_board"
        )
        let item = document.createElement("li")
        if (type === "post") {
            item.innerHTML = `<img src=${url} alt="post image" onLoad='document.getElementById("messenger_messageDetailMobile_board").scrollTop = document.getElementById("messenger_messageDetailMobile_board").scrollHeight'/><div>${text}</div>`
            item.onclick = function () {
                history.push(`/post?id=${post_id}`)
            }
        } else {
            item.innerText = text
        }
        item.setAttribute(
            "class",
            `messenger_messageDetail_board_list messenger_messageDetail_board_list_${type}`
        )
        messageBoard.appendChild(item)
        messageBoard.scrollTop = messageBoard.scrollHeight
    }

    function enterPressed(event) {
        var code = event.keyCode || event.which
        if (code === 13) {
            //13 is the enter keycode
            sendHandler()
        }
    }

    function detectScroll(e) {
        let element = document.getElementById(
            "messenger_messageDetailMobile_board"
        )
        if (element.scrollTop === 0 && !loading) {
            props.socket.emit("get more message", {
                token: userData.token,
                room: props.room,
                length: element.children.length
            })
            setLoading(true)
        }
    }

    useEffect(() => {
        if (props.room && props.socket) {
            props.socket.emit("initialization_mobile", {
                token: userData.token,
                room: props.room
            })
            props.socket.on("initialization_response_mobile", e => {
                let messageTitle = document.getElementById(
                    "messenger_messageDetailMobile_top"
                )
                let messageBoard = document.getElementById(
                    "messenger_messageDetailMobile_board"
                )
                messageTitle.innerText = props.toName
                while (messageBoard.firstChild)
                    messageBoard.removeChild(messageBoard.firstChild)
                for (let i = 0; i < e.message.length; i++) {
                    if (e.message[i].post_id) {
                        let url = `https://adplin.s3.amazonaws.com/${
                            e.message[i].post_id
                        }/resize/cover_${
                            e.message[i].main_image.split(".")[0]
                        }.jpeg`
                        createMessagePrepend(
                            e.message[i].message,
                            "post",
                            e.message[i].post_id,
                            url
                        )
                    } else {
                        if (e.message[i].to_id !== parseInt(e.user_id)) {
                            createMessagePrepend(e.message[i].message, "send")
                        } else {
                            createMessagePrepend(
                                e.message[i].message,
                                "receive"
                            )
                        }
                    }
                }
                messageBoard.scrollTop = messageBoard.scrollHeight
            })

            props.socket.on(`receive message ${props.room}`, e => {
                if (e.to !== e.user_id) {
                    createMessage(e.message, "receive")
                    props.changeMessageList("already_read", 1, props.room)
                    props.socket.emit(`read message`, {
                        token: userData.token,
                        room: props.room
                    })
                } else {
                    createMessage(e.message, "send")
                }
            })

            props.socket.on(`get more message response mobile`, e => {
                let messageBoard = document.getElementById(
                    "messenger_messageDetailMobile_board"
                )
                for (let i = 0; i < e.message.length; i++) {
                    if (e.message[i].post_id) {
                        let url = `https://adplin.s3.amazonaws.com/${
                            e.message[i].post_id
                        }/resize/cover_${
                            e.message[i].main_image.split(".")[0]
                        }.jpeg`
                        createMessagePrepend(
                            e.message[i].message,
                            "post",
                            e.message[i].post_id,
                            url
                        )
                    } else {
                        if (e.message[i].to_id !== parseInt(e.user_id)) {
                            createMessagePrepend(e.message[i].message, "send")
                        } else {
                            createMessagePrepend(
                                e.message[i].message,
                                "receive"
                            )
                        }
                    }
                }
                let height = 0
                for (let i = 0; i < e.message.length; i++) {
                    let style = getComputedStyle(messageBoard.children[i])
                    height += messageBoard.children[i].clientHeight
                    // messageBoard.children[i].clientHeight +
                    // parseInt(style.marginTop) +
                    // parseInt(style.marginBottom)
                }
                messageBoard.scrollTop = height
                setLoading(false)
            })
        }
        return () => {
            if (props.socket && props.room) {
                props.socket.off(`receive message ${props.room}`)
                props.socket.off("initialization_response_mobile")
                props.socket.off("get more message response mobile")
            }
        }
    }, [props.socket, props.room])

    useEffect(() => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)

        function resize() {
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty("--vh", `${vh}px`)
            setTimeout(() => window.scrollTo(0, 0), 200)
        }
        window.addEventListener("resize", resize)
        document.getElementById("footer_container").style.visibility = "hidden"
        return () => {
            window.removeEventListener("resize", resize)
            document.getElementById("footer_container").style.visibility =
                "visible"
        }
    }, [])

    return (
        <div id="messenger_messageDetailMobile_container">
            <div id="messenger_messageDetailMobile_top_container">
                <IoArrowBack
                    size={24}
                    onClick={() => {
                        props.chooseRoom()
                    }}
                />
                <div id="messenger_messageDetailMobile_top"></div>
                <div id="messenger_messageDetailMobile_top_right">
                    {props.to_phone ? (
                        <BiPhone
                            size={24}
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                                window.location.href = `tel://${props.to_phone}`
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    {props.to_link ? (
                        <BiLinkExternal
                            size={24}
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                                window.location.href = `${props.to_link}`
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    {props.to_email ? (
                        <AiOutlineMail
                            size={24}
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                                window.location.href = `mailto:${props.to_email}`
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div
                id="messenger_messageDetailMobile_board"
                onScroll={detectScroll}
            ></div>
            <div id="messenger_messageDetailMobile_input">
                <input
                    type="text"
                    placeholder="Aa"
                    value={message}
                    onChange={e => {
                        setMessage(e.currentTarget.value)
                    }}
                    onKeyPress={e => {
                        enterPressed(e)
                    }}
                />
                <div
                    className="messenger_messageDetailMobile_send_button"
                    onClick={sendHandler}
                >
                    <RiSendPlaneFill color={"#fff"} size={40} />
                </div>
            </div>
        </div>
    )
}

export default MessageDetailMobile
