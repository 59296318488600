import React, { useEffect } from "react"

import "../css/Home.css"
import "../css/full_width.css"
import Categories from "../components/home/Categories"
import UserInfo from "../components/home/UserInfo"
import Introduction from "../components/home/Introduction"
import HowItWorks from "../components/home/HowItWorks"
import getScript from "../language"

const Home = () => {
    //let domain = "http://localhost:3000/"
    let script = getScript("introduction")

    // useEffect(() => {
    //     document.getElementById("headerContainer").style.backgroundColor =
    //         "rgba(50, 51, 56, 0.3)"
    //     //"rgb(173 117 177 / 0.3)"
    //     window.addEventListener("scroll", changeHeader)
    //     return () => window.removeEventListener("scroll", changeHeader)
    // }, [])
    // function changeHeader(event) {
    //     let scrollTop = window.scrollY
    //     if (scrollTop === 0) {
    //         document.getElementById("headerContainer").style.backgroundColor =
    //             "rgba(50, 51, 56, 0.3)" //  "rgb(173 117 177 / 0.3)"
    //     } else {
    //         document.getElementById("headerContainer").style.backgroundColor =
    //             "rgba(50, 51, 56, 1)" //"rgb(173 117 177 / 1)"
    //     }
    // }
    return (
        <div>
            <div
                id="mainBackgroundImage"
                style={{
                    backgroundImage: `url("/images/main6.jpg")`
                }}
            >
                <div id="mainBackTextContainer">
                    <div id="mainBackText">{script.top}</div>
                </div>
            </div>
            <div id="home_bodyWrapper">
                <Categories />

                <Introduction />

                <HowItWorks />
                {/* <UserInfo /> */}
            </div>
        </div>
    )
}

export default Home
