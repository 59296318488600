import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { RiSendPlaneFill } from "react-icons/ri"
import { BiPhone, BiLinkExternal } from "react-icons/bi"
import { AiOutlineMail } from "react-icons/ai"
import "../../css/messenger/MessageDetail.css"

const MessageDetail = props => {
    const userData = useSelector(state => state.user)
    const history = useHistory()
    const [message, setMessage] = useState("")

    function sendHandler() {
        if (message.length === 0) {
            return
        }
        createMessage(message, "send")
        props.socket.emit("send message", {
            message: message,
            token: userData.token,
            room: props.room
        })
        props.changeMessageList("message", message, props.room)
        props.changeMessageList("time", new Date(), props.room)
        props.moveToTop(props.room)
        setMessage("")
    }

    function createMessagePrepend(text, type, post_id, url) {
        let messageBoard = document.getElementById(
            "messenger_messageDetail_board"
        )
        let item = document.createElement("li")
        if (type === "post") {
            item.innerHTML = `<img src=${url} alt="post image" onLoad='document.getElementById("messenger_messageDetail_board").scrollTop = document.getElementById("messenger_messageDetail_board").scrollTop + parseInt(getComputedStyle(this).height)'/><div>${text}</div>`
            item.onclick = function () {
                history.push(`/post?id=${post_id}`)
            }
        } else {
            item.innerText = text
        }
        item.setAttribute(
            "class",
            `messenger_messageDetail_board_list messenger_messageDetail_board_list_${type}`
        )
        //messageBoard.appendChild(item)
        messageBoard.prepend(item)
        //messageBoard.scrollTop = messageBoard.scrollHeight
    }

    function createMessage(text, type, post_id, url) {
        let messageBoard = document.getElementById(
            "messenger_messageDetail_board"
        )
        let item = document.createElement("li")
        if (type === "post") {
            item.innerHTML = `<img src=${url} alt="post image" onLoad='document.getElementById("messenger_messageDetail_board").scrollTop = document.getElementById("messenger_messageDetail_board").scrollHeight'/><div>${text}</div>`
            item.onclick = function () {
                history.push(`/post?id=${post_id}`)
            }
        } else {
            item.innerText = text
        }
        item.setAttribute(
            "class",
            `messenger_messageDetail_board_list messenger_messageDetail_board_list_${type}`
        )
        messageBoard.appendChild(item)
        //messageBoard.prepend(item)
        messageBoard.scrollTop = messageBoard.scrollHeight
    }

    function enterPressed(event) {
        var code = event.keyCode || event.which
        if (code === 13) {
            //13 is the enter keycode
            sendHandler()
        }
    }

    function detectScroll(e) {
        let element = document.getElementById("messenger_messageDetail_board")
        if (element.scrollTop === 0) {
            props.socket.emit("get more message", {
                token: userData.token,
                room: props.room,
                length: element.children.length
            })
        }
    }

    useEffect(() => {
        if (props.room && props.socket) {
            props.socket.emit("initialization", {
                token: userData.token,
                room: props.room
            })
            props.socket.on("initialization_response", e => {
                let messageTitle = document.getElementById(
                    "messenger_messageDetail_top"
                )
                let messageBoard = document.getElementById(
                    "messenger_messageDetail_board"
                )
                messageTitle.innerText = props.toName
                while (messageBoard.firstChild)
                    messageBoard.removeChild(messageBoard.firstChild)
                for (let i = 0; i < e.message.length; i++) {
                    if (e.message[i].post_id) {
                        let url = `https://adplin.s3.amazonaws.com/${
                            e.message[i].post_id
                        }/resize/cover_${
                            e.message[i].main_image.split(".")[0]
                        }.jpeg`
                        createMessagePrepend(
                            e.message[i].message,
                            "post",
                            e.message[i].post_id,
                            url
                        )
                    } else {
                        if (e.message[i].to_id !== parseInt(e.user_id)) {
                            createMessagePrepend(e.message[i].message, "send")
                        } else {
                            createMessagePrepend(
                                e.message[i].message,
                                "receive"
                            )
                        }
                    }
                }
                messageBoard.scrollTop = messageBoard.scrollHeight
            })

            props.socket.on(`receive message ${props.room}`, e => {
                if (e.to !== e.user_id) {
                    createMessage(e.message, "receive")
                    props.changeMessageList("already_read", 1, props.room)
                    props.socket.emit(`read message`, {
                        token: userData.token,
                        room: props.room
                    })
                } else {
                    createMessage(e.message, "send")
                }
            })
            props.socket.on(`get more message response`, e => {
                let messageBoard = document.getElementById(
                    "messenger_messageDetail_board"
                )
                for (let i = 0; i < e.message.length; i++) {
                    if (e.message[i].post_id) {
                        let url = `https://adplin.s3.amazonaws.com/${
                            e.message[i].post_id
                        }/resize/cover_${
                            e.message[i].main_image.split(".")[0]
                        }.jpeg`
                        createMessagePrepend(
                            e.message[i].message,
                            "post",
                            e.message[i].post_id,
                            url
                        )
                    } else {
                        if (e.message[i].to_id !== parseInt(e.user_id)) {
                            createMessagePrepend(e.message[i].message, "send")
                        } else {
                            createMessagePrepend(
                                e.message[i].message,
                                "receive"
                            )
                        }
                    }
                }
                let height = 0
                for (let i = 0; i < e.message.length; i++) {
                    let style = getComputedStyle(messageBoard.children[i])
                    height += messageBoard.children[i].clientHeight
                    // messageBoard.children[i].clientHeight +
                    // parseInt(style.marginTop) +
                    // parseInt(style.marginBottom)
                }
                messageBoard.scrollTop = height
            })
        }
        return () => {
            if (props.socket && props.room) {
                props.socket.off(`receive message ${props.room}`)
                props.socket.off("initialization_response")
                props.socket.off("get more message response")
            }
        }
    }, [props.socket, props.room])

    return (
        <div id="messenger_messageDetail_container">
            <div id="messenger_messageDetail_top_container">
                <div id="messenger_messageDetail_top"></div>
                <div id="messenger_messageDetail_top_right">
                    {props.to_phone ? (
                        <BiPhone
                            size={24}
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                                window.location.href = `tel://${props.to_phone}`
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    {props.to_link ? (
                        <BiLinkExternal
                            size={24}
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                                window.location.href = `${props.to_link}`
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    {props.to_email ? (
                        <AiOutlineMail
                            size={24}
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                                window.location.href = `mailto:${props.to_email}`
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div
                id="messenger_messageDetail_board"
                onScroll={detectScroll}
            ></div>
            <div id="messenger_messageDetail_input">
                <input
                    type="text"
                    placeholder="Aa"
                    value={message}
                    onChange={e => {
                        setMessage(e.currentTarget.value)
                    }}
                    onKeyPress={e => {
                        enterPressed(e)
                    }}
                />
                <div
                    className="messenger_messageDetail_send_button"
                    onClick={sendHandler}
                >
                    <RiSendPlaneFill color={"#fff"} size={25} />
                </div>
            </div>
        </div>
    )
}

export default MessageDetail
