import React from "react"
import "../css/Loading.css"

const Loading = () => {
    return (
        <div id="loading-container">
            <img src="/images/logo_icon.svg" alt="Loading" />
            {/* <div>Loading ...</div> */}
        </div>
    )
}

export default Loading
