import React, { useState } from "react"
import "../../css/post/Report_Review.css"
import getScript from "../../language"

const Report_Review = props => {
    let script = getScript("post")
    const [submitted, setSubmitted] = useState(false)
    const [reason, setReason] = useState("")

    function reportHandler() {
        document.getElementById("post_report_review_container").style.display =
            "none"
    }
    function submitHandler() {
        fetch("/post/report_review", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                post_id: props.post_id,
                review_id: props.review_id,
                reason: reason
            })
        }).then(res => {
            return res.json()
        })
        setSubmitted(true)
    }
    return (
        <div id="post_report_review_container">
            {submitted === false ? (
                <div id="post_report_box_container">
                    <div id="post_report_title">
                        {script.inappropriate_review}
                    </div>
                    <div>{script.tell_us_review}</div>
                    <textarea
                        type="text"
                        id="post_report_textarea"
                        maxLength="1000"
                        value={reason}
                        onChange={e => setReason(e.currentTarget.value)}
                    ></textarea>
                    <div id="post_report_box_buttons">
                        <div onClick={reportHandler}>{script.close}</div>
                        <div onClick={submitHandler}>{script.submit}</div>
                    </div>
                </div>
            ) : (
                <div id="post_report_box_container">
                    <div id="post_report_title">{script.thank}</div>
                    <div id="post_report_close_button" onClick={reportHandler}>
                        {script.close}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Report_Review
