import React from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { FiCheck } from "react-icons/fi"
import "../../css/subscription/Subscription.css"
const { REACT_APP_LIVE } = process.env

let stripePromise
if (REACT_APP_LIVE === "true") {
    stripePromise = loadStripe(
        "pk_live_51I5j8JCt4Hzp7gbFrsA6Oq6eGpEXcmCrXJz2EjCLwFaVOMA2zyHDOpb1F0r4ppVZvlWMWfFfhfrBmiw7vR9cA4Bx00G3b0ulVN"
    )
} else {
    stripePromise = loadStripe(
        "pk_test_51I5j8JCt4Hzp7gbFuLq4q67L0xAAW4hyrRmRUu63dpV3qzQcB0ZP6sM0AeKB8i4pPGIVzoroRYpsWY7qv2n2Nk4H00WPzWwntW"
    )
}

const Subscription = () => {
    return (
        <Elements stripe={stripePromise}>
            <Subscription_Stripe />
        </Elements>
    )
}

const Subscription_Stripe = () => {
    const stripe = useStripe()
    const userData = useSelector(state => state.user)

    const checkout = product => {
        let priceId

        if (REACT_APP_LIVE === "true") {
            if (product === "monthly") {
                priceId = "price_1I9do4Ct4Hzp7gbFG3wNDT1I"
            } else if (product === "yearly") {
                priceId = "price_1I9doBCt4Hzp7gbFcz2FpYU1"
            }
        } else {
            if (product === "monthly") {
                //priceId = "price_1I5jUQCt4Hzp7gbFHktza0SA"
                //Daily for testing
                priceId = "price_1I8CMyCt4Hzp7gbF3NwZIYnb"
            } else if (product === "yearly") {
                priceId = "price_1I7AL5Ct4Hzp7gbFZVcmQqtO"
            }
        }
        fetch("/stripe/checkout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.token}`
            },
            body: JSON.stringify({
                priceId: priceId
            })
        })
            .then(function (result) {
                return result.json()
            })
            .then(function (data) {
                // Call Stripe.js method to redirect to the new Checkout page
                stripe
                    .redirectToCheckout({
                        sessionId: data.sessionId
                    })
                    .then(result => {
                        console.log(result)
                    })
                // .then(handleResult)
            })
    }

    return (
        <div id="subscription_container">
            <div id="subscription_title">
                Boost your post with Subscription!
            </div>
            <div id="subscription_grid">
                <div className="subscription_box_container">
                    <div className="subscription_box_title">Monthly</div>
                    <div className="subscription_original_price"></div>
                    <div className="subscription_pricing_row">
                        <span id="subscription_price_monthly">$15</span> /month
                    </div>
                    <div className="subscription_item_container">
                        <div>
                            <FiCheck /> reach more clients
                        </div>
                        <div>
                            <FiCheck /> remove google ad from your posts
                        </div>
                        <div>
                            <FiCheck /> get unlimited post
                        </div>
                        <div>
                            <FiCheck /> priority placement in post listings
                        </div>
                    </div>
                    <div
                        className="subscription_buttons"
                        onClick={() => {
                            checkout("monthly")
                        }}
                    >
                        Subscribe
                    </div>
                    {/* <button className="subscription_buttons">Subscribe</button> */}
                </div>
                <div className="subscription_box_container">
                    <div className="subscription_box_title">Yearly</div>
                    <div className="subscription_original_price">$180</div>
                    <div className="subscription_pricing_row">
                        <span id="subscription_price_yearly">$150</span> /year
                    </div>
                    <div className="subscription_item_container">
                        <div>
                            <FiCheck /> includes every benefits of monthly plan
                        </div>
                        <div>
                            <FiCheck /> get $30 discount (2 month)
                        </div>
                        <div>
                            <FiCheck /> have your post on Adplin social media
                        </div>
                    </div>
                    <div
                        className="subscription_buttons"
                        onClick={() => {
                            checkout("yearly")
                        }}
                    >
                        Subscribe
                    </div>
                    {/* <Link className="subscription_buttons" to="/checkout">Subscribe</Link> */}
                </div>
            </div>
        </div>
    )
}

export default Subscription
