import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { FiPlus } from "react-icons/fi"
import { TiDeleteOutline } from "react-icons/ti"
import "../../css/UploadMultipleFiles.css"

const UploadMultipleFiles = ({ updateParent, subFile, post_id }) => {
    return (
        <div id="uploadMultipleFiles_container_layout">
            <Upload
                updateParent={updateParent}
                subFile={subFile}
                post_id={post_id}
            />
        </div>
    )
}

const Upload = ({ updateParent, subFile, post_id }) => {
    const userData = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [originalFileList, setOriginalFileList] = useState([])
    const [newlyUploadedFile, setNewlyUploadedFile] = useState([])
    const [message, setMessage] = useState("")

    function changeHeightOfDiv() {
        let classes = document.getElementsByClassName(
            "uploadMultipleFiles_file_display"
        )
        for (let i = 0; i < classes.length; i++) {
            classes[i].style.height = `${
                document.getElementById(
                    "uploadMultipleFiles_upload_button_container"
                ).clientWidth / 1.33
            }px`
        }
        document.getElementById(
            "uploadMultipleFiles_upload_button_container"
        ).style.height = `${
            document.getElementById(
                "uploadMultipleFiles_upload_button_container"
            ).clientWidth / 1.33
        }px`
    }

    function handleDelete2(e) {
	console.log(`handleDelete2 ${e.currentTarget.value}`)
        setFileList(fileList.filter(item => item !== e.currentTarget.value))
        setOriginalFileList(
            originalFileList.filter(item => item !== e.currentTarget.value)
        )
        setNewlyUploadedFile(
            newlyUploadedFile.filter(item => item !== e.currentTarget.value)
        )
    }

    function handleDelete(e, fileList) {
        let parentNode = e.target.parentNode
        while (
            parentNode.getAttribute("class") !==
            "uploadMultipleFiles_file_display"
        ) {
            parentNode = parentNode.parentNode
        }
        let filenameOfParentNode = parentNode.getAttribute("filename")
        parentNode.remove()
        document.getElementById(
            "uploadMultipleFiles_delete_handler"
        ).value = filenameOfParentNode
        document.getElementById("uploadMultipleFiles_delete_handler").click()
    }

    function validate(file) {
        const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png"
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isJpgOrPng) {
            setMessage("You can only upload JPG/PNG file!")
        } else if (!isLt20M) {
            setMessage("Image must smaller than 20MB!")
        } else {
            setMessage("")
        }
        return isJpgOrPng && isLt20M
    }

    function fileChanged(e) {
        if (!validate(e.currentTarget.files[0])) {
            return
        }
        setLoading(true)
        var div = document.createElement("div")
        var div_hover = document.createElement("div")
        var img = document.createElement("img")
        div.setAttribute("class", "uploadMultipleFiles_file_display")
        div_hover.setAttribute(
            "class",
            "uploadMultipleFiles_file_display_hover"
        )
        img.setAttribute("class", "uploadMultipleFiles_file_display_image")
        div.appendChild(img)
        div.appendChild(div_hover)
        let deleteComponent = document.getElementById(
            "uploadMultipleFiles_delete_component"
        )
        let newDeleteComponent = deleteComponent.cloneNode(true)
        newDeleteComponent.style.display = "flex"
        newDeleteComponent.onclick = e => {
            return handleDelete(e, fileList)
        }
        div_hover.appendChild(newDeleteComponent)
        document
            .getElementById("uploadMultipleFiles_container")
            .appendChild(div)
        let divCounter = document.getElementById(
            "uploadMultipleFiles_container"
        ).childElementCount
        changeHeightOfDiv()

        let formData = new FormData()
        formData.append("sub", e.currentTarget.files[0])
        formData.append(
            "file_number",
            document.getElementsByClassName("uploadMultipleFiles_file_display")
                .length
        )
        fetch("/create/temp/upload_sub", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`
            },
            body: formData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.status === "succeed") {
                    //info.file.status = "done"
                    //setImageUrl(`/images/tmp/${res.id}/main${res.ext}`)
                    setFileList(old => [...old, res.filename])
                    setOriginalFileList(old => [
                        ...old,
                        res.filename.split(".")[0] + res.ext
                    ])
                    setNewlyUploadedFile(old => [...old, res.filename.split(".")[0] + res.ext])
                    img.setAttribute(
                        "src",
                        `/images/tmp/${res.id}/resize/${res.filename}`
                    )
                    div.setAttribute("filename", `${res.filename}`)
                } else if (res.status === "unauthorized") {
                }
                setLoading(false)
            })
        document.getElementById("uploadMultipleFiles_input").value = ""
    }
    function openUpload() {
        document.getElementById("uploadMultipleFiles_input").click()
    }

    useEffect(() => {
        function appendImage(url, value) {
            var div = document.createElement("div")
            var div_hover = document.createElement("div")
            var img = document.createElement("img")
            div.setAttribute("class", "uploadMultipleFiles_file_display")
            div_hover.setAttribute(
                "class",
                "uploadMultipleFiles_file_display_hover"
            )
	    div.setAttribute("filename", `${value}`)
            img.setAttribute("class", "uploadMultipleFiles_file_display_image")
            div.appendChild(img)
            div.appendChild(div_hover)
            let deleteComponent = document.getElementById(
                "uploadMultipleFiles_delete_component"
            )
            let newDeleteComponent = deleteComponent.cloneNode(true)
            newDeleteComponent.style.display = "flex"
            newDeleteComponent.onclick = e => {
                return handleDelete(e, fileList)
            }
            div_hover.appendChild(newDeleteComponent)
            document
                .getElementById("uploadMultipleFiles_container")
                .appendChild(div)
            img.setAttribute("src", `${url}`)
        }

        if (subFile !== undefined && subFile !== "") {
            let newArray = subFile.split(",")
            setFileList(newArray)
            setOriginalFileList(newArray)
            for (let i = 0; i < newArray.length; i++) {
                let fileName = newArray[i].split(".")[0]
                appendImage(
                    `https://adplin.s3.amazonaws.com/${post_id}/resize/${fileName}.jpeg`,
		    newArray[i]
                )
            }
            changeHeightOfDiv()
        }
    }, [subFile])

    useEffect(() => {
        updateParent("files", fileList)
    }, [fileList])

    useEffect(() => {
	updateParent("originalFiles", originalFileList)
    },[originalFileList])
    useEffect(() => {
        updateParent("newlyUploadedFile", newlyUploadedFile)
    }, [newlyUploadedFile])

    useEffect(() => {
        // document.getElementById(
        //     "uploadMultipleFiles_container"
        // ).style.gridTemplateColumns = "repeat(2, 1fr)"
        changeHeightOfDiv()
    }, [])
    return (
        <div>
            <div id="uploadMultipleFiles_container">
                <div id="uploadMultipleFiles_upload_button_container">
                    <button
                        id="uploadMultipleFiles_upload_button"
                        onClick={openUpload}
                    >
                        <FiPlus />
                        <div>Upload files</div>
                    </button>
                    <input
                        type="file"
                        id="uploadMultipleFiles_input"
                        onChange={fileChanged}
                    />
                    <input
                        id="uploadMultipleFiles_delete_handler"
                        onClick={handleDelete2}
                        value=""
                        style={{ display: "none" }}
                    ></input>
                    <TiDeleteOutline
                        id="uploadMultipleFiles_delete_component"
                        size={40}
                        color="white"
                        style={{ display: "none" }}
                    />
                </div>
            </div>
            <div id="create_edit_upload_multiple_files_message">{message}</div>
        </div>
    )
}

export default UploadMultipleFiles
