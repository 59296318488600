import React from "react"
import { useSelector, useDispatch } from "react-redux"
import user from "../../../store/actions/user"

const AdPlin_Language = props => {
    const userData = useSelector(state => state.user)
    const dispatch = useDispatch()

    function selectLanguage(e) {
        //update DB
        fetch("/users/update_language", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                language: e.currentTarget.value
            })
        })
        props.changeLanguage(e.currentTarget.value)
        dispatch(user.updateLanguage(e.currentTarget.value))
    }

    return (
        <div className="language_setting_row_container">
            <div className="language_setting_title">AdPlin language</div>
            <div className="language_setting_description">
                Language for buttons, titles and other text from AdPlin for this
                account
            </div>
            <select
                id="language_setting_select"
                onChange={selectLanguage}
                value={userData.language}
            >
                {/* <option value="cn">Chinese</option> */}
                <option value="en">English</option>
                <option value="ko">Korean</option>
            </select>
        </div>
    )
}

export default AdPlin_Language
