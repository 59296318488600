import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import user from "../store/actions/user"
import "../css/Footer.css"
import getScript from "../language"

const Footer = props => {
    const script = getScript("footer")
    const userData = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [year, setYear] = useState()

    function selectLanguage(e) {
        if (userData.token && userData.token !== "undefined") {
            fetch("/users/update_language", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    language: e.currentTarget.value
                })
            })
        }
        props.changeLanguage(e.currentTarget.value)
        dispatch(user.updateLanguage(e.currentTarget.value))
    }

    useEffect(() => {
        let date = new Date()
        let year = date.getFullYear()
        setYear(year)
    }, [])
    return (
        <div id="footer_container">
            <div id="footer_left_container">
                <Link to="/">
                    <img src="/images/logo_black.svg" width="80px" />
                </Link>
                <div>Copyright© {year} AdPlin All rights reserved. </div>
            </div>
            <div id="footer_right_container">
                <Link to="/terms_of_service">{script.terms}</Link>
                <Link to="/privacy_policy">{script.privacy}</Link>
                <Link to="/company">{script.company}</Link>
                <Link to="/contact">{script.contact}</Link>
                <select
                    id="footer_language_select"
                    value={userData.language}
                    onChange={selectLanguage}
                >
                    <option value="en">English</option>
                    <option value="ko">Korean</option>
                </select>
            </div>
        </div>
    )
}

export default Footer
