import React from "react"
import { Link } from "react-router-dom"
import getScript from "../../language"
import "../../css/card/List.css"

const List = props => {
    const script = getScript("db_categories")
    let source = `/images/${props.source}`
    let url = `/category_list?id=${props.category_id}`
    function zoomInImage(event) {
        event.currentTarget.children[0].children[0].style.transform =
            "scale(1.1)"
        //event.currentTarget.children[1].style.color = "white"
    }
    function zoomOutImage(event) {
        event.currentTarget.children[0].children[0].style.transform = "scale(1)"
        //event.currentTarget.children[1].style.color = "black"
    }
    return (
        <Link
            id="main_categories_list"
            onMouseOver={zoomInImage}
            onMouseOut={zoomOutImage}
            to={url}
        >
            <div className="main_categories_list_image_container">
                <img
                    className="main_categories_list_image"
                    src={source}
                    alt={"category images"}
                />
                <div className="main_categories_list_name">
                    {script[props.category_id]}
                </div>
            </div>
        </Link>
    )
}

export default List
