import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { BiPhone, BiLinkExternal } from "react-icons/bi"
import { AiOutlineMail } from "react-icons/ai"
import { RiMessage2Line } from "react-icons/ri"
import PhoneInput from "react-phone-input-2"
import "../../css/post/Mobile_About.css"
import getScript from "../../language"

const Mobile_About = props => {
    let script = getScript("post")
    const userData = useSelector(state => state.user)
    const history = useHistory()

    function sendMessage() {
        if (
            userData.token &&
            userData.token !== "undefined" &&
            userData.token !== "null"
        ) {
            props.socket.emit("send message from post", {
                post_id: props.post_id,
                message: props.post_title,
                token: userData.token
            })
            props.socket.on("send message from post response", e => {
                if (e.status === "succeed") {
                    history.push(
                        `/messenger?room=${e.room}&to=${e.post_user_name}&phone=${e.phone}&link=${e.link}&email=${e.email}`
                    )
                } else if (e.status === "unauthorize") {
                    props.passedFunction("Sign in to", "pop")
                    document.getElementById("loginForm").style.display = "flex"
                }
            })
        } else {
            props.passedFunction("Sign in to", "pop")
            document.getElementById("loginForm").style.display = "flex"
        }
        // fetch("/message/send", {
        //     method: "POST",
        //     headers: {
        //         Authorization: `Bearer ${userData.token}`,
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({
        //         post_id: props.post_id,
        //         post_title: props.post_title,
        //         post_user_id: props.post_user_id
        //     })
        // })
        //     .then(res => res.json())
        //     .then(res => {
        //         if (res.status === "succeed") {
        //             history.push(
        //                 `/messenger?token=djiqwd33irjnc25Rasdj0dijdascgNIDDNswqrnt&room=${res.room_id}&post_user=${props.post_user_id}`
        //             )
        //         } else if (res.status === "unauthorize") {
        //             props.passedFunction("Sign in to", "pop")
        //             document.getElementById("loginForm").style.display = "flex"
        //         }
        //     })
    }

    function copyToClipBoard(text) {
        var textField = document.createElement("textarea")
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand("copy")
        textField.remove()

        let copyMessage = document.getElementById("post_copy_message")
        copyMessage.style.display = "flex"
        setTimeout(() => {
            if (copyMessage) {
                copyMessage.style.display = "none"
            }
        }, 2000)
    }

    return (
        <div id={props.id}>
            <div id="post_mobile_about_flex">
                <div id="post_mobile_about_picture_container">
                    {props.firstCharacter}
                </div>
                <div id="post_mobile_about_title">
                    <span>About</span> {props.name}
                </div>
            </div>
            {props.phone ? (
                <div
                    className="post_desktop_about_grid"
                    id="post_mobile_phone_display"
                    onClick={() => copyToClipBoard(props.phone)}
                >
                    <PhoneInput country={"us"} value={props.phone} />
                    <BiPhone id="post_desktop_about_phone_icon" />
                </div>
            ) : (
                <></>
            )}
            <div
                className="post_desktop_about_grid"
                onClick={() => copyToClipBoard(props.email)}
            >
                <div>{props.email}</div>
                <AiOutlineMail />
            </div>
            {props.website ? (
                <a
                    href={props.website}
                    id="post_mobile_about_link"
                    className="post_desktop_about_grid"
                >
                    <div>{props.website}</div>
                    <BiLinkExternal />
                </a>
            ) : (
                <></>
            )}
            <div className="post_desktop_about_grid" onClick={sendMessage}>
                <div>{script.message}</div>
                <RiMessage2Line />
            </div>
        </div>
    )
}

export default Mobile_About
