import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import { validateEmail } from "../../functions/global"
import { IoChevronBack } from "react-icons/io5"
import CardSection from "../stripe/CardSection"
import Loading from "../../components/Loading"
import "../../css/Payment.css"

const Form = props => {
    const stripe = useStripe()
    const elements = useElements()
    const userData = useSelector(state => state.user)
    let query = new URLSearchParams(useLocation().search)
    let history = useHistory()
    const [loading, setLoading] = useState(true)
    const [seller, setSeller] = useState()
    const [amount, setAmount] = useState("0")
    const [email, setEmail] = useState("")
    const [login, setLogin] = useState(false)
    const [note, setNote] = useState("")
    const [errMessage, setErrorMessage] = useState("")

    function getSecret() {
        setLoading(true)
        setErrorMessage("")
        if (amount == 0 || amount == "0") {
            setErrorMessage("Amount cannot be 0")
            setLoading(false)
            return
        }
        if (validateEmail(email) === false) {
            setErrorMessage("Invalid email address")
            setLoading(false)
            return
        }
        if (note.length > 100) {
            setErrorMessage("Note is limited to 100 characters.")
            setLoading(false)
            return
        }
        if (!stripe || !elements) {
            setLoading(false)
            return
        }
        fetch("/stripe/connect/payIntent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: query.get("id"),
                amount: amount,
                email: email,
                note: note,
                seller_name: seller
            })
        })
            .then(res => res.json())
            .then(async res => {
                //console.log(res)
                if (res.status === "succeed") {
                    const result = await stripe.confirmCardPayment(
                        res.client_secret,
                        {
                            payment_method: {
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    name: "Jenny Rosen"
                                }
                            }
                        }
                    )
                    if (result.error) {
                        setErrorMessage(result.error.message)
                    } else {
                        if (result.paymentIntent.status === "succeeded") {
                            history.push("/payment_succeed")
                        }
                    }
                    //console.log(result)
                    setLoading(false)
                } else {
                    setErrorMessage("Failed, please try again later.")
                    setLoading(false)
                }
            })
    }

    useEffect(() => {
        //getUserDetail
        fetch("/users/getDisplayNamePublic", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: query.get("id")
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === "failed") {
                    history.push("/browse")
                }
                setSeller(res.display_name)
                //Loading false
                setLoading(false)
            })

        if (
            userData.token &&
            userData.token !== "undefined" &&
            userData.token !== "null"
        ) {
            fetch("/users/get_email", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: query.get("id")
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (
                        res.status === "fail" ||
                        res.status === "unauthorized"
                    ) {
                        setEmail("")
                    } else if (res.status === "succeed") {
                        setEmail(res.email)
                        setLogin(true)
                        document.getElementById(
                            "payment_form_email"
                        ).disabled = true
                    }
                    setLoading(false)
                })
        } else {
            setEmail("")
            setLoading(false)
        }

        document.getElementById("footerDistance").style.marginTop = "0px"
        return () => {
            document.getElementById("footerDistance").style.marginTop = "20px"
            document.getElementById("loading-container").style.display = "none"
        }
    }, [])

    useEffect(() => {
        let loadingWrapper = document.getElementById("loading-container")
        let paymentWrapper = document.getElementById(
            "payment_background_wrapper"
        )
        if (loading) {
            loadingWrapper.style.display = "flex"
            paymentWrapper.style.display = "none"
        } else {
            loadingWrapper.style.display = "none"
            paymentWrapper.style.display = "flex"
        }
    }, [loading])

    return (
        <>
            <Loading />
            <div id="payment_background_wrapper">
                <form id="payment_form_wrapper">
                    <div id="payment_form_recipient">
                        Payment to:{" "}
                        <span id="payment_form_recipient_name">{seller}</span>
                    </div>
                    <div className="payment_form_title">Amount (USD)</div>
                    <input
                        id="payment_form_amount"
                        className="payment_form_input"
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        value={amount}
                        step="1"
                        required
                        onKeyDown={e => {
                            if (e.key === ".") {
                                e.preventDefault()
                            } else if (e.key === "-") {
                                e.preventDefault()
                            }
                        }}
                        onChange={e => {
                            let numberArray = e.currentTarget.value
                                .toString()
                                .split("")
                            for (let i = 0; i < numberArray.length; i++) {
                                if (numberArray[i] === "0") {
                                    numberArray.shift()
                                    i--
                                } else {
                                    break
                                }
                            }
                            setAmount(numberArray.join("").replace(".", ""))
                        }}
                    />
                    <div className="payment_form_title">Card</div>
                    <CardSection />

                    <div className="payment_form_title">Your email address</div>
                    <input
                        id="payment_form_email"
                        className="payment_form_input"
                        type="text"
                        name="email"
                        disabled={login}
                        value={email}
                        required
                        onChange={e => {
                            setEmail(e.currentTarget.value)
                        }}
                    />

                    <div className="payment_form_title">What’s it for?</div>
                    <input
                        id="payment_form_note"
                        className="payment_form_input"
                        type="text"
                        name="note"
                        value={note}
                        maxLength={100}
                        onChange={e => {
                            setNote(e.currentTarget.value)
                        }}
                    />
                    <div id="payment_form_tos">
                        By clicking “Confirm Payment” I agree to the company’s
                        Terms of Service.
                    </div>
                    <div id="payment_form_buttons_wrapper">
                        <div
                            id="payment_form_button_back"
                            className="payment_form_button"
                            onClick={() => {
                                history.goBack()
                            }}
                        >
                            <IoChevronBack id="payment_form_button_back_icon" />
                            Back
                        </div>
                        <div
                            id="payment_form_button_pay"
                            className="payment_form_button"
                            onClick={getSecret}
                        >
                            Confirm payment
                        </div>
                    </div>
                    <div id="payment_form_error_message">{errMessage}</div>
                </form>
            </div>
        </>
    )
}

export default Form
