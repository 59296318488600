import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// import { MdEdit } from "react-icons/md"
import PhoneInput from "react-phone-input-2"
import user from "../../store/actions/user"
import Loading from "../Loading"
import "../../css/AccountMain.css"
import "react-phone-input-2/lib/style.css"

const AccountMain = props => {
    const userData = useSelector(state => state.user)
    const dispatch = useDispatch()
    const history = useHistory()
    let query = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(true)
    const [loginFrom, setLoginFrom] = useState("")
    const [onNameChange, setOnNameChange] = useState(false)
    const [onPasswordChange, setOnPasswordChange] = useState(false)
    const [onPhoneChange, setOnPhoneChange] = useState(false)
    const [onWebsiteChange, setOnWebsiteChange] = useState(false)
    const [new_pw, setNew_pw] = useState("")
    const [new_pw2, setNew_pw2] = useState("")
    const [display_name, setDisplay_name] = useState("")
    const [display_nameOrigin, setDisplay_nameOrigin] = useState("")
    const [passwordChangeMessage, setPasswordChangeMessage] = useState("")
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [firstNameOrigin, setFirstNameOrigin] = useState("")
    const [lastName, setLastName] = useState("")
    const [lastNameOrigin, setLastNameOrigin] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [businessNameOrigin, setBusinessNameOrigin] = useState("")
    const [nameErrorMessage, setNameErrorMessage] = useState("")
    const [phone, setPhone] = useState("")
    const [phoneOrigin, setPhoneOrigin] = useState("")
    const [website, setWebsite] = useState("")
    const [websiteOrigin, setWebsiteOrigin] = useState("")
    const [websiteErrorMessage, setWebsiteErrorMessage] = useState("")

    function validatePassword() {
        if (new_pw === "") {
            setPasswordChangeMessage("Please enter new password.")
        } else if (new_pw === new_pw2) {
            //change password
            setPasswordChangeMessage("")
            fetch("/users/changePassword", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                },
                body: JSON.stringify({
                    new_pw: new_pw
                })
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    if (data.status === "succeed") {
                        //update token
                        dispatch(user.updateToken(data.token))
                    }
                    setOnPasswordChange(false)
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setPasswordChangeMessage("Your password does not match.")
        }
    }

    function validateName() {
        if (display_name === "full_name") {
            if (
                firstName === "" ||
                firstName === null ||
                firstName === undefined
            ) {
                setNameErrorMessage("Please insert your first name.")
            } else if (
                lastName === "" ||
                lastName === null ||
                lastName === undefined
            ) {
                setNameErrorMessage("Please insert your last name.")
            } else {
                setBusinessName(businessNameOrigin)
                //change Name fetch
                sendNameChangeRequest()
            }
        } else if (display_name === "business_name") {
            if (
                businessName === "" ||
                businessName === null ||
                businessName === undefined
            ) {
                setNameErrorMessage("Please insert your business name.")
            } else {
                setFirstName(firstNameOrigin)
                setLastName(lastNameOrigin)
                //change Name fetch
                sendNameChangeRequest()
            }
        }

        function sendNameChangeRequest() {
            fetch("/users/changeName", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                },
                body: JSON.stringify({
                    display_name,
                    firstName,
                    lastName,
                    businessName
                })
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    if (data.status === "succeed") {
                        //update token
                        dispatch(user.updateToken(data.token))
                        setOnNameChange(false)
                        setNameErrorMessage("")
                        if (display_name === "full_name") {
                            setFirstNameOrigin(firstName)
                            setLastNameOrigin(lastName)
                            setDisplay_nameOrigin("full_name")
                        } else if (display_name === "business_name") {
                            setBusinessNameOrigin(businessName)
                            setDisplay_nameOrigin("business_name")
                        }
                    } else if (
                        data.status === "failed" ||
                        data.status === "unauthorized"
                    ) {
                        //what to do?
                        setNameErrorMessage("Please try it again later.")
                    }
                })
                .catch(err => {
                    console.log(err)
                    setNameErrorMessage(err)
                })
        }
    }

    function changePhone() {
        fetch("/users/changePhone", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.token}`
            },
            body: JSON.stringify({
                phone
            })
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                if (data.status === "succeed") {
                    //update token
                    dispatch(user.updateToken(data.token))
                    setOnPhoneChange(false)
                    setPhoneOrigin(phone)
                } else if (
                    data.status === "failed" ||
                    data.status === "unauthorized"
                ) {
                    //what to do?
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    function changeWebsite() {
        console.log(website)
        if (website !== "") {
            try {
                new URL(website)
            } catch (e) {
                setWebsiteErrorMessage("invalid website url")
                return
            }
        }
        fetch("/users/changeWebsite", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.token}`
            },
            body: JSON.stringify({
                website
            })
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                if (data.status === "succeed") {
                    //update token
                    dispatch(user.updateToken(data.token))
                    setWebsiteOrigin(website)
                    setOnWebsiteChange(false)
                } else if (
                    data.status === "failed" ||
                    data.status === "unauthorized"
                ) {
                    //what to do?
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        if (query.get("action") === "reset") {
            fetch("/users/getUserInfo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${query.get("token")}`
                },
                body: JSON.stringify({})
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    if (data.action === "succeed") {
                        dispatch(
                            user.logIn(
                                data.userData.email,
                                data.userData.login_from,
                                query.get("token")
                            )
                        )
                        let userDataAll = data.userData

                        setEmail(userDataAll.email)
                        setFirstName(userDataAll.first_name)
                        setFirstNameOrigin(userDataAll.first_name)
                        setLastName(userDataAll.last_name)
                        setLastNameOrigin(userDataAll.last_name)
                        setBusinessName(userDataAll.business_name)
                        setBusinessNameOrigin(userDataAll.business_name)
                        setPhone(userDataAll.phone_number)
                        setPhoneOrigin(userDataAll.phone_number)
                        setWebsite(userDataAll.website)
                        setWebsiteOrigin(userDataAll.website)
                        setDisplay_name(userDataAll.display_name)
                        setDisplay_nameOrigin(userDataAll.display_name)
                        setLoginFrom(userDataAll.login_from)
                        setOnPasswordChange(true)
                        setLoading(false)
                    }
                })
                .catch(err => {
                    console.log(err)
                    history.push("main")
                    props.passedFunction("Sign in to")
                    document.getElementById("loginForm").style.display = "flex"
                    setLoading(false)
                })
        } else {
            fetch("/users/getUserInfo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                },
                body: JSON.stringify({})
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    let userDataAll = data.userData

                    setEmail(userDataAll.email)
                    setFirstName(userDataAll.first_name)
                    setFirstNameOrigin(userDataAll.first_name)
                    setLastName(userDataAll.last_name)
                    setLastNameOrigin(userDataAll.last_name)
                    setBusinessName(userDataAll.business_name)
                    setBusinessNameOrigin(userDataAll.business_name)
                    setPhone(userDataAll.phone_number)
                    setPhoneOrigin(userDataAll.phone_number)
                    setWebsite(userDataAll.website)
                    setWebsiteOrigin(userDataAll.website)
                    setDisplay_name(userDataAll.display_name)
                    setDisplay_nameOrigin(userDataAll.display_name)
                    setLoginFrom(userDataAll.login_from)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    history.push("main")
                    props.passedFunction("Sign in to")
                    document.getElementById("loginForm").style.display = "flex"
                    setLoading(false)
                })
        }
    }, [])

    if (loading) {
        return <Loading />
    } else {
        return (
            <div>
                <div className="account_main_row">
                    <div>Email</div>
                    <div>{email}</div>
                    <div></div>
                </div>
                {loginFrom === "adplin" ? (
                    <div className="account_main_row">
                        <div>Password</div>
                        <div>*******</div>
                        {onPasswordChange ? (
                            <div
                                className="account_main_row_edit account_main_row_edit_cancel"
                                onClick={() => {
                                    setOnPasswordChange(false)
                                    setPasswordChangeMessage("")
                                }}
                            >
                                <div>cancel</div>
                            </div>
                        ) : (
                            <div
                                className="account_main_row_edit"
                                onClick={() => {
                                    setOnPasswordChange(true)
                                }}
                            >
                                <div>edit</div>
                            </div>
                        )}
                    </div>
                ) : (
                    <></>
                )}
                {onPasswordChange ? (
                    <div>
                        <div className="account_edit_row">
                            <div></div>
                            <input
                                type="password"
                                name="new_password"
                                id="new_password"
                                placeholder="New password"
                                onChange={e => {
                                    setNew_pw(e.currentTarget.value)
                                }}
                            />
                            <div></div>
                            <div></div>
                            <input
                                type="password"
                                name="new_password_confirm"
                                id="new_password_confirm"
                                placeholder="Confirm password"
                                onChange={e => {
                                    setNew_pw2(e.currentTarget.value)
                                }}
                            />
                            <div
                                className="account_edit_save_button"
                                onClick={() => {
                                    validatePassword()
                                }}
                            >
                                save
                            </div>
                        </div>
                        <div className="account_main_error_message">
                            {passwordChangeMessage}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="account_main_row">
                    <div>Name</div>
                    <div>
                        {display_name === "full_name" ? (
                            <div>
                                {firstName} {lastName}
                            </div>
                        ) : (
                            <div>{businessName}</div>
                        )}
                    </div>
                    {onNameChange ? (
                        <div
                            className="account_main_row_edit account_main_row_edit_cancel"
                            onClick={() => {
                                setFirstName(firstNameOrigin)
                                setLastName(lastNameOrigin)
                                setBusinessName(businessNameOrigin)
                                setDisplay_name(display_nameOrigin)
                                setOnNameChange(false)
                            }}
                        >
                            <div>cancel</div>
                        </div>
                    ) : (
                        <div
                            className="account_main_row_edit"
                            onClick={() => {
                                setOnNameChange(true)
                            }}
                        >
                            <div>edit</div>
                        </div>
                    )}
                </div>
                {onNameChange === true ? (
                    <div>
                        <div className="account_edit_row">
                            <div
                                id="account_main_fullName"
                                style={
                                    display_name === "full_name"
                                        ? {
                                              backgroundColor: "#341E5E",
                                              color: "#fff"
                                          }
                                        : {}
                                }
                                onClick={() => {
                                    setDisplay_name("full_name")
                                }}
                            >
                                Full Name
                            </div>
                            <div id="account_main_editing_full_name">
                                <input
                                    id="account_main_editing_mobile_2"
                                    className="account_main_editing_input"
                                    type="text"
                                    disabled={
                                        display_name === "full_name"
                                            ? false
                                            : true
                                    }
                                    value={firstName}
                                    placeholder="First name"
                                    onChange={e => {
                                        setFirstName(e.currentTarget.value)
                                    }}
                                />
                                <input
                                    id="account_main_editing_mobile_4"
                                    className="account_main_editing_input"
                                    type="text"
                                    disabled={
                                        display_name === "full_name"
                                            ? false
                                            : true
                                    }
                                    value={lastName}
                                    placeholder="Last name"
                                    onChange={e => {
                                        setLastName(e.currentTarget.value)
                                    }}
                                />
                            </div>
                            {display_name === "full_name" ? (
                                <div
                                    className="account_edit_save_button"
                                    onClick={() => {
                                        validateName()
                                    }}
                                >
                                    save
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        <div className="account_edit_row">
                            <div
                                id="account_main_fullName"
                                style={
                                    display_name === "business_name"
                                        ? {
                                              backgroundColor: "#341E5E",
                                              color: "#fff"
                                          }
                                        : {}
                                }
                                onClick={() => {
                                    setDisplay_name("business_name")
                                }}
                            >
                                Business
                            </div>
                            <input
                                id="account_main_editing_mobile_6"
                                className="account_main_editing_input"
                                type="text"
                                disabled={
                                    display_name === "business_name"
                                        ? false
                                        : true
                                }
                                value={businessName}
                                placeholder="Business name"
                                onChange={e => {
                                    setBusinessName(e.currentTarget.value)
                                }}
                            />
                            {display_name === "business_name" ? (
                                <div
                                    className="account_edit_save_button"
                                    onClick={() => {
                                        validateName()
                                    }}
                                >
                                    save
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>

                        <div className="account_main_error_message">
                            {nameErrorMessage}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="account_main_row">
                    <div>Phone</div>
                    <div id="account_main_phone_display">
                        <PhoneInput country={"us"} value={phone} />
                    </div>
                    {onPhoneChange ? (
                        <div
                            className="account_main_row_edit account_main_row_edit_cancel"
                            onClick={() => {
                                setPhone(phoneOrigin)
                                setOnPhoneChange(false)
                            }}
                        >
                            <div>cancel</div>
                        </div>
                    ) : (
                        <div
                            className="account_main_row_edit"
                            onClick={() => {
                                setOnPhoneChange(true)
                            }}
                        >
                            <div>Edit</div>
                        </div>
                    )}
                </div>

                {onPhoneChange ? (
                    <div>
                        <div className="account_edit_row">
                            <div></div>
                            <PhoneInput
                                country={"us"}
                                value={phone}
                                onChange={e => {
                                    setPhone(e)
                                }}
                            />
                            <div
                                className="account_edit_save_button"
                                onClick={() => {
                                    changePhone()
                                }}
                            >
                                save
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="account_main_row">
                    <div>Website</div>
                    <div>{website}</div>
                    {onWebsiteChange ? (
                        <div
                            className="account_main_row_edit account_main_row_edit_cancel"
                            onClick={() => {
                                setWebsite(websiteOrigin)
                                setOnWebsiteChange(false)
                            }}
                        >
                            <div>cancel</div>
                        </div>
                    ) : (
                        <div
                            className="account_main_row_edit"
                            onClick={() => {
                                setOnWebsiteChange(true)
                            }}
                        >
                            <div>edit</div>
                        </div>
                    )}
                </div>
                {onWebsiteChange ? (
                    <div>
                        <div className="account_edit_row">
                            <div></div>
                            <input
                                placeholder="https://adplin.com"
                                value={website}
                                onChange={e => {
                                    setWebsite(e.currentTarget.value)
                                }}
                            />
                            <div
                                className="account_edit_save_button"
                                onClick={() => {
                                    changeWebsite()
                                }}
                            >
                                save
                            </div>
                        </div>
                        <div className="account_main_error_message">
                            {websiteErrorMessage}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        )
    }
}

export default AccountMain
