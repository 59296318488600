import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, Link, useHistory } from "react-router-dom"

const Subscription_Succeed = () => {
    let query = new URLSearchParams(useLocation().search)
    const userData = useSelector(state => state.user)

    useEffect(() => {
        if (query.get("session_id")) {
            fetch("/stripe/payment_succeed", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    session_id: query.get("session_id")
                })
            })
                .then(res => {
                    return res.json()
                })
                .then(res => {
                    if (res.status === "succeed") {
                        console.log("success!")
                        console.log(res.session)
                    } else {
                        console.log("failed")
                    }
                })
        }
    }, [])
    return <div>Template Page</div>
}

export default Subscription_Succeed
