import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import "../css/LoginForm.css"
import { apiConfig, companyName, mainColor } from "../config/config"
import { FiX } from "react-icons/fi"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { GoogleLogin } from "react-google-login"
import AppleLogin from "react-apple-login"
import getScript from "../language"

import user from "../store/actions/user"

const LoginForm = props => {
    let script = getScript("loginform")
    const dispatch = useDispatch()
    const history = useHistory()
    const userData = useSelector(state => state.user)

    function closeForm(event) {
        document.getElementById("loginForm").style.display = "none"
    }
    function closeForm2(e) {
        e.stopPropagation()
    }
    function facebookResponse(res) {
        // console.log(res)
        let first_name = undefined
        let last_name = undefined
        if (res.name) {
            let splitedName = res.name.split(" ")
            first_name = splitedName[0]
            last_name = splitedName[splitedName.length - 1]
        }
        if (res.accessToken) {
            logInRequest(
                res.email,
                "facebook",
                undefined,
                first_name,
                last_name
            )
        }
    }

    async function googleSignIn() {
        fetch(`/users/google/generate_url`)
            .then(res => res.json())
            .then(res => {
                window.location.replace(res.url)
            })
    }

    function googleResponse(res) {
        logInRequest(
            res.profileObj.email,
            "google",
            undefined,
            res.profileObj.givenName,
            res.profileObj.familyName
        )
        //dispatch(user.logIn(res.profileObj.email, "google"))
    }
    function googleFailResponse(res) {
        // console.log(res)
    }
    function appleResponse(res) {
        // console.log(res)
        // console.log(res.authorization)
        // console.log(res.authorization.user)
        // console.log(res.authorization.user.email)
        let emailExist
        emailExist = false
        if (res) {
            if (res.user) {
                if (res.user.email) {
                    emailExist = true
                }
            }
        }

        fetch("/users/apple/auth", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(res)
        })
            .then(resp => resp.json())
            .then(data => {
                // console.log(emailExist)
                // console.log(data.email)
                if (emailExist === true) {
                    logInRequest(
                        res.user.email,
                        "apple",
                        data.sub,
                        res.user.name.firstName,
                        res.user.name.lastName
                    )
                } else {
                    logInRequest(
                        undefined,
                        "apple",
                        data.sub,
                        undefined,
                        undefined
                    )
                }
            })
            .catch(err => {
                // console.log(err)
            })
    }

    function logInRequest(
        email,
        login_from,
        code = undefined,
        first_name = undefined,
        last_name = undefined
    ) {
        fetch("/users/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                login_from: login_from,
                code: code,
                first_name: first_name,
                last_name: last_name
            })
        })
            .then(res => res.json())
            .then(data => {
                dispatch(user.logIn(data.email, login_from, data.token))
                if (data.language) {
                    dispatch(user.updateLanguage(data.language))
                    props.changeLanguage(data.language)
                }
                if (props.to === "pop") {
                    props.passedFunction("Sign in to", "")
                } else {
                    history.push("browse")
                }
            })
            .catch(err => {
                // console.log(err)
            })
    }
    useEffect(() => {
        if (userData.status === 1) {
            closeForm()
        }
    }, [userData])
    return (
        <div id="loginForm" onMouseDown={closeForm}>
            <div id="loginBox" onMouseDown={closeForm2}>
                <div id="loginBoxClose" onClick={closeForm}>
                    <FiX size="30" />
                </div>
                <div id="loginText">
                    {script[props.action]}
                    {/* {props.action}{" "}
                    {props.action === "Reset" ? " your account" : companyName} */}
                </div>
                <div id="loginSocial">
                    <FacebookLogin
                        appId={apiConfig.facebook_app_id}
                        autoLoad={false}
                        fields="name,email,picture"
                        disableMobileRedirect={true}
                        callback={facebookResponse}
                        render={renderProps => (
                            <button
                                className="loginFacebook"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 18 18"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.0062 0H0.99375C0.445312 0 0 0.445312 0 0.99375V17.0062C0 17.5547 0.445312 18 0.99375 18H9V10.875H6.84844V8.25H9V6.30938C9 3.98438 10.6125 2.71875 12.6891 2.71875C13.6828 2.71875 14.7516 2.79375 15 2.82656V5.25469H13.3453C12.2156 5.25469 12 5.78906 12 6.57656V8.25H14.6906L14.3391 10.875H12V18H17.0062C17.5547 18 18 17.5547 18 17.0062V0.99375C18 0.445312 17.5547 0 17.0062 0Z"
                                        fill="white"
                                    ></path>
                                </svg>
                                <div className="loginCompanies">
                                    {script.facebook}
                                </div>
                            </button>
                        )}
                    />
                    <GoogleLogin
                        clientId={apiConfig.google_app_id}
                        buttonText="Login"
                        onClick={googleSignIn}
                        onSuccess={googleResponse}
                        onFailure={googleFailResponse}
                        render={renderProps => (
                            <button
                                className="loginGoogle"
                                onClick={googleSignIn}
                                //onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 18 19"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9 7.84363V11.307H13.8438C13.6365 12.428 12.9994 13.373 12.0489 14.0064V16.2534H14.9562C16.6601 14.6951 17.641 12.4029 17.641 9.67839C17.641 9.04502 17.5854 8.43176 17.4792 7.84865H9V7.84363Z"
                                        fill="#3E82F1"
                                    ></path>
                                    <path
                                        d="M9.00001 14.861C6.65394 14.861 4.67192 13.2876 3.96406 11.1714H0.955627V13.4937C2.43709 16.4142 5.48091 18.4198 9.00001 18.4198C11.432 18.4198 13.4697 17.6206 14.9562 16.2533L12.0489 14.0064C11.245 14.5443 10.2135 14.861 9.00001 14.861Z"
                                        fill="#32A753"
                                    ></path>
                                    <path
                                        d="M3.96404 5.45605H0.955617C0.348876 6.66246 0 8.02972 0 9.47238C0 10.915 0.348876 12.2823 0.955617 13.4887L3.96404 11.1714C3.78202 10.6335 3.6809 10.0605 3.6809 9.47238C3.6809 8.88426 3.78202 8.31122 3.96404 7.77336V5.45605Z"
                                        fill="#F9BB00"
                                    ></path>
                                    <path
                                        d="M0.955627 5.45597L3.96406 7.77327C4.67192 5.65703 6.65394 4.08368 9.00001 4.08368C10.3197 4.08368 11.5079 4.53608 12.4382 5.42078L15.0219 2.85214C13.4646 1.40948 11.427 0.52478 9.00001 0.52478C5.48091 0.52478 2.43709 2.53043 0.955627 5.45597Z"
                                        fill="#E74133"
                                    ></path>
                                </svg>
                                <div className="loginCompanies">
                                    {script.google}
                                </div>
                            </button>
                        )}
                    />

                    <AppleLogin
                        clientId="adplin"
                        redirectURI="https://adplin.com"
                        usePopup={true}
                        scope="name email"
                        response_mode="form_post"
                        callback={appleResponse}
                        render={renderProps => (
                            <button
                                className="loginApple"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 16 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.6442 9.51027C12.6362 8.03572 13.3031 6.92277 14.6531 6.10313C13.8978 5.02232 12.7567 4.42768 11.25 4.31116C9.82366 4.19866 8.26473 5.14286 7.6942 5.14286C7.09152 5.14286 5.70938 4.35134 4.62455 4.35134C2.38259 4.3875 0 6.13929 0 9.70313C0 10.7558 0.192857 11.8433 0.578571 12.9656C1.09286 14.4402 2.94911 18.0563 4.88571 17.996C5.89821 17.9719 6.61339 17.2768 7.93125 17.2768C9.20893 17.2768 9.87187 17.996 11.0009 17.996C12.9536 17.9679 14.633 14.6813 15.1232 13.2027C12.5036 11.9692 12.6442 9.58661 12.6442 9.51027ZM10.3701 2.91295C11.467 1.61116 11.3665 0.425893 11.3344 0C10.3661 0.05625 9.24509 0.658929 8.60625 1.40223C7.90313 2.19777 7.48929 3.18214 7.57768 4.29107C8.62634 4.37143 9.58259 3.83304 10.3701 2.91295Z"
                                        fill="black"
                                    ></path>
                                </svg>
                                <div className="loginCompanies">
                                    {script.apple}
                                </div>
                            </button>
                        )}
                    />
                </div>
                <div id="loginForm_line">
                    <span id="loginForm_or">{script.or}</span>
                </div>
                <ActualForm
                    action={props.action}
                    to={props.to}
                    passedFunction={props.passedFunction}
                    changeLanguage={props.changeLanguage}
                />
                <div></div>
            </div>
            <div
                id="googleAuthFunction"
                className="loginForm_invisible"
                onClick={googleSignIn}
            ></div>
            <div id="googleAuthID" className="loginForm_invisible"></div>
        </div>
    )
}

const ActualForm = props => {
    let script = getScript("loginform")
    const dispatch = useDispatch()
    const history = useHistory()
    const [account_email_1, setAccount_email_1] = useState("")
    const [account_email_2, setAccount_email_2] = useState("")
    const [account_email_3, setAccount_email_3] = useState("")
    const [account_password_1, setAccount_password_1] = useState("")
    const [account_password_2, setAccount_password_2] = useState("")
    const [account_firstname, setAccount_firstname] = useState("")
    const [account_lastname, setAccount_lastname] = useState("")
    const [message_1, setMessage_1] = useState("")
    const [message_2, setMessage_2] = useState("")
    const [message_3, setMessage_3] = useState("")
    const [loading, setLoading] = useState(false)

    function emailChanged_1(e) {
        setAccount_email_1(e.target.value)
    }
    function emailChanged_2(e) {
        setAccount_email_2(e.target.value)
    }
    function emailChanged_3(e) {
        setAccount_email_3(e.target.value)
    }
    function passwordChanged_1(e) {
        setAccount_password_1(e.target.value)
    }
    function passwordChanged_2(e) {
        setAccount_password_2(e.target.value)
    }
    function firstnameChanged(e) {
        setAccount_firstname(e.target.value)
    }
    function lastnameChanged(e) {
        setAccount_lastname(e.target.value)
    }
    function goToJoin() {
        props.passedFunction("Join")
    }
    function goToSignIn() {
        props.passedFunction("Sign in to")
    }
    function goToReset() {
        setAccount_email_3("")
        setMessage_3("")
        props.passedFunction("Reset")
    }

    function enterPressed(event, action) {
        var code = event.keyCode || event.which
        if (code === 13) {
            //13 is the enter keycode
            validateForm(action)
        }
    }
    function logInRequest(
        email,
        login_from,
        code = undefined,
        first_name = undefined,
        last_name = undefined
    ) {
        fetch("/users/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                login_from: login_from,
                code: code,
                first_name: first_name,
                last_name: last_name
            })
        })
            .then(res => res.json())
            .then(data => {
                dispatch(user.logIn(data.email, login_from, data.token))
                if (data.language) {
                    dispatch(user.updateLanguage(data.language))
                    props.changeLanguage(data.language)
                }
                if (props.to === "pop") {
                    props.passedFunction("Sign in to", "")
                } else {
                    history.push("browse")
                }
            })
            .catch(err => {
                // console.log(err)
            })
    }

    function validateForm(action) {
        let email = ""
        let password = ""
        let first_name = ""
        let last_name = ""

        if (action === "login") {
            if (account_email_1 === "") {
                setMessage_1(script.insertemail)
                return
            } else if (account_password_1 === "") {
                setMessage_1(script.insertpassword)
                return
            }
            email = account_email_1
            password = account_password_1
        } else if (action === "create") {
            if (account_email_2 === "") {
                setMessage_2(script.insertemail)
                return
            } else if (account_password_2 === "") {
                setMessage_2(script.insertpassword)
                return
            } else if (account_firstname === "") {
                setMessage_2(script.insertfirstname)
                return
            } else if (account_firstname === "") {
                setMessage_2(script.insertlastname)
                return
            }
            email = account_email_2
            password = account_password_2
            first_name = account_firstname
            last_name = account_lastname
        } else if (action === "reset") {
            if (account_email_3 === "") {
                setMessage_3(script.insertemail)
                return
            }
            email = account_email_3
        }
        setLoading(true)
        fetch("/users/loginForm", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                action: action,
                email: email,
                password: password,
                first_name: first_name,
                last_name: last_name
            })
        })
            .then(resp => resp.json())
            .then(data => {
                // console.log(data)
                setLoading(false)
                if (data.action === "failed") {
                    if (action === "login") {
                        setMessage_1(data.message)
                    } else if (action === "create") {
                        setMessage_2(data.message)
                    } else if (action === "reset") {
                        setMessage_3(data.message)
                    }
                } else if (data.action === "pending") {
                    props.passedFunction("Verify")
                } else if (data.action === "succeed") {
                    logInRequest(
                        email,
                        "adplin",
                        data.sub,
                        undefined,
                        undefined
                    )
                } else if (data.action === "sent") {
                    setMessage_3(script.resetmessage)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }
    useEffect(() => {
        setAccount_password_1("")
        setAccount_password_2("")
    }, [])
    if (props.action === "Verify") {
        return (
            <div id="loginForm_verify_container">
                <div id="loginForm_verify_logo">
                    <img
                        src="/images/logo_black.svg"
                        alt="AdPlin Logo"
                        width="200"
                    />
                </div>
                <div id="loginForm_verify_title">{script.verifyaccount}</div>
                <div id="loginForm_verify_text">
                    {script.justsent}
                    <span id="loginForm_verify_email"> {account_email_2}</span>
                    {script.click}
                </div>
                <div className="loginForm_nav_container">
                    {script.already}{" "}
                    <span className="loginForm_nav" onClick={goToSignIn}>
                        {script.signintoaccount}
                    </span>
                </div>
            </div>
        )
    } else if (props.action === "Reset") {
        return (
            <div>
                <form id="loginForm_form" name="loginForm">
                    <input
                        type="text"
                        id="accountId"
                        name="accountId"
                        placeholder={script.email}
                        value={account_email_3}
                        autoCapitalize="none"
                        required
                        onKeyPress={e => {
                            enterPressed(e, "reset")
                        }}
                        onChange={emailChanged_3}
                    />
                    <input
                        id="loginForm_submitBtn"
                        type="button"
                        disabled={loading ? "true" : ""}
                        value={loading ? script.loading : script.resetbutton}
                        onClick={() => validateForm("reset")}
                    />
                    <div className="loginForm_message">{message_3}</div>
                </form>
                <div className="loginForm_nav_container">
                    {script.already}{" "}
                    <span className="loginForm_nav" onClick={goToSignIn}>
                        {script.signintoaccount}
                    </span>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                {props.action === "Sign in to" ? (
                    <div>
                        <form id="loginForm_form" name="loginForm">
                            <input
                                type="text"
                                id="accountId"
                                name="accountId"
                                placeholder={script.email}
                                value={account_email_1}
                                autoCapitalize="none"
                                required
                                onKeyPress={e => {
                                    enterPressed(e, "login")
                                }}
                                onChange={emailChanged_1}
                            />
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder={script.password}
                                value={account_password_1}
                                required
                                onKeyPress={e => {
                                    enterPressed(e, "login")
                                }}
                                onChange={passwordChanged_1}
                            />
                            <input
                                id="loginForm_submitBtn"
                                type="button"
                                disabled={loading ? "true" : ""}
                                value={
                                    loading
                                        ? script.loading
                                        : script.loginbutton
                                }
                                onClick={() => validateForm("login")}
                            />
                            <div className="loginForm_message">{message_1}</div>
                        </form>
                        <div>
                            <div
                                id="loginForm_nav_container_forgotten"
                                className="loginForm_nav_container"
                            >
                                <span
                                    className="loginForm_nav"
                                    onClick={goToReset}
                                >
                                    {script.reset}
                                </span>
                            </div>
                        </div>
                        <div id="loginForm_sign_in_box_container">
                            <div>{script.not}</div>
                            <div id="loginForm_sign_in_box" onClick={goToJoin}>
                                {script.create}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <form id="loginForm_form" name="loginForm">
                            <input
                                type="text"
                                id="accountId"
                                name="accountId"
                                placeholder={script.email}
                                value={account_email_2}
                                autoCapitalize="none"
                                required
                                onKeyPress={e => {
                                    enterPressed(e, "create")
                                }}
                                onChange={emailChanged_2}
                            />
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder={script.password}
                                value={account_password_2}
                                required
                                onKeyPress={e => {
                                    enterPressed(e, "create")
                                }}
                                onChange={passwordChanged_2}
                            />
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                placeholder={script.firstname}
                                value={account_firstname}
                                required
                                onKeyPress={e => {
                                    enterPressed(e, "create")
                                }}
                                onChange={firstnameChanged}
                            />
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                placeholder={script.lastname}
                                value={account_lastname}
                                required
                                onKeyPress={e => {
                                    enterPressed(e, "create")
                                }}
                                onChange={lastnameChanged}
                            />
                            <input
                                id="loginForm_submitBtn"
                                type="button"
                                disabled={loading ? "true" : ""}
                                value={
                                    loading
                                        ? script.loading
                                        : script.createbutton
                                }
                                onClick={() => validateForm("create")}
                            />
                            <div className="loginForm_message">{message_2}</div>
                        </form>

                        <div className="loginForm_nav_container">
                            {script.already}{" "}
                            <span
                                className="loginForm_nav"
                                onClick={goToSignIn}
                            >
                                {script.signintoaccount}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default LoginForm
