import React from 'react';

const Advertise = ()=>{
    return (
        <div>
            Advertise Page
        </div>
    )
}

export default Advertise;

