import React from "react"
import "../../css/notification/Full_Notification.css"

const Full_Notification = props => {
    return (
        <div id="full_notification_wrapper">
            <div id="full_notification_box_wrapper">
                {props.img ? <img src={props.img} /> : <></>}
                <div id="full_notification_title">{props.title}</div>
                <div id="full_notification_message">{props.message}</div>
                {props.button ? <div></div> : <></>}
            </div>
        </div>
    )
}

export default Full_Notification
