import React, { useEffect } from "react"
import { FiX } from "react-icons/fi"
import { AiOutlineCopy, AiOutlineInfoCircle } from "react-icons/ai"
import "../../css/earning/MyPaymentLink.css"

const MyPaymentLink = props => {
    function closePopUp() {
        document.getElementById("earning_mypaymentlink_wrapper").style.display =
            "none"
    }

    function copy() {
        var textField = document.createElement("textarea")
        textField.innerText = props.link
        document.body.appendChild(textField)
        textField.select()
        document.execCommand("copy")
        textField.remove()

        let copyMessage = document.getElementById(
            "earning_mypaymentlink_copy_message"
        )
        copyMessage.style.display = "flex"
        setTimeout(() => {
            if (copyMessage) {
                copyMessage.style.display = "none"
            }
        }, 2000)
    }

    useEffect(() => {
        return () => {
            //hide MyPaymentLink
            document.getElementById(
                "earning_mypaymentlink_wrapper"
            ).style.display = "none"
        }
    }, [])
    return (
        <div id="earning_mypaymentlink_wrapper">
            {/* <div id="earning_mypaymentlink_position"> */}
            <div id="earning_mypaymentlink_box_wrapper">
                <FiX
                    id="earning_mypaymentlink_close_button"
                    onClick={closePopUp}
                />
                <div id="earning_mypaymentlink_title">My payment link</div>
                <div id="earning_mypaymentlink_linkWrapper" onClick={copy}>
                    <div id="earning_mypaymentlink_link">{props.link}</div>
                    <AiOutlineCopy id="earning_mypaymentlink_copy_button" />
                </div>

                <div id="earning_mypaymentlink_infoWrapper">
                    <AiOutlineInfoCircle id="earning_mypaymentlink_info_icon" />
                    Share your payment link with your clients to request
                    payments.
                </div>
            </div>
            <div id="earning_mypaymentlink_copy_message">
                Copied to clipboard
            </div>
        </div>
    )
}

export default MyPaymentLink
