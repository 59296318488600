import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Form from "../components/payment/Form"
import Full_Notification from "../components/notification/Full_Notification"
import Loading from "../components/Loading"

const Payment = props => {
    const [stripePromise, setStripePromise] = useState()
    const [registered, setRegistered] = useState(true)
    const query = new URLSearchParams(useLocation().search)
    const history = useHistory()

    useEffect(() => {
        //get account id
        fetch("/stripe/connect/getAccountID", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: query.get("id")
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === "failed") {
                    history.push("/browse")
                } else if (res.status === "noAccount") {
                    //when account is not set up
                    fetch("/users/sendEmailToRegisterBank", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            token: query.get("id")
                        })
                    })
                    setRegistered(false)
                } else {
                    const stripe = loadStripe(
                        "pk_test_51I5j8JCt4Hzp7gbFuLq4q67L0xAAW4hyrRmRUu63dpV3qzQcB0ZP6sM0AeKB8i4pPGIVzoroRYpsWY7qv2n2Nk4H00WPzWwntW",
                        {
                            stripeAccount: res.id
                        }
                    )
                    setStripePromise(stripe)
                }
            })
    }, [])
    return (
        <>
            {registered && stripePromise ? (
                <Elements stripe={stripePromise}>
                    <Form />
                </Elements>
            ) : registered ? (
                <Loading />
            ) : (
                <Full_Notification
                    img="/images/payment/payment_fail.svg"
                    title="Oh no!"
                    message="This freelancer or agency has not setup their bank account. While we let them know you are waiting, please feel free to contact them directly."
                />
                // <>

                //     <div>
                //         Freelancer or agent has not registered their bank
                //         account to Adplin.
                //     </div>
                //     <div>Please cont</div>
                // </>
            )}
        </>
    )
}

export default Payment
