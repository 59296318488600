import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { AiOutlineMenu } from "react-icons/ai"
import { BiSearch } from "react-icons/bi"
import { MdClose } from "react-icons/md"
import "../css/Header.css"
import getScript from "../language"

import user from "../store/actions/user"
import Create from "../route/Create"

const Header = props => {
    const script = getScript("header")
    const userData = useSelector(state => state.user)
    const dispatch = useDispatch()
    const history = useHistory()
    const [color, setColor] = useState("#6b6a6a")
    const [menuShown, setMenuShown] = useState(false)
    const [mobileSearchShown, setMobileSearchShown] = useState(false)
    const [userName, setUserName] = useState("")
    const [input, setInput] = useState()

    function showLoginForm() {
        props.passedFunction("Sign in to")
        document.getElementById("loginForm").style.display = "flex"
    }

    function showSignUpForm() {
        props.passedFunction("Join")
        document.getElementById("loginForm").style.display = "flex"
    }

    function logOut() {
        dispatch(user.logOut())
        history.push("browse")
    }

    function hideMenu() {
        if (document.getElementById("headerShowMenu") !== null) {
            document.getElementById("headerShowMenu").style.transform =
                "rotate(0deg)"
        }
        document.getElementById("headerNavRight_menu").style.display = "none"
        document.getElementById(
            "headerNavRight_menu_container_position"
        ).style.display = "none"
        setMenuShown(false)
    }
    function showMenu() {
        // if (document.getElementById("headerShowMenu") !== null) {
        //     document.getElementById("headerShowMenu").style.transform =
        //         "rotate(180deg)"
        // }
        if (menuShown === true) {
            // if (document.getElementById("headerShowMenu") !== null) {
            //     document.getElementById("headerShowMenu").style.transform =
            //         "rotate(0deg)"
            // }
            document.getElementById("headerNavRight_menu").style.display =
                "none"
            document.getElementById(
                "headerNavRight_menu_container_position"
            ).style.display = "none"
            setMenuShown(false)
        } else {
            // if (document.getElementById("headerShowMenu") !== null) {
            //     document.getElementById("headerShowMenu").style.transform =
            //         "rotate(180deg)"
            // }
            document.getElementById("headerNavRight_menu").style.display =
                "flex"
            document.getElementById(
                "headerNavRight_menu_container_position"
            ).style.display = "flex"

            setMenuShown(true)
        }
    }

    function extendWidth() {
        document.getElementById(
            "headerNav_search_input_desktop_container"
        ).style.minWidth = "400px"
    }

    function minimizeWidth() {
        document.getElementById(
            "headerNav_search_input_desktop_container"
        ).style.minWidth = ""
    }

    function showMobileSearch() {
        document.getElementById("headerNavRight_menu").style.display = "none"
        document.getElementById(
            "headerNavRight_menu_container_position"
        ).style.display = "none"
        setMenuShown(false)
        setMobileSearchShown(true)
        let mobileSearchElement = document.getElementById(
            "header_search_mobile"
        )
        mobileSearchElement.style.display = "flex"
        document.getElementById("headerContainer").style.borderBottom = "0px"
        document.getElementById("header_serach_mobile_input").focus()
    }
    function desktopSearchClicked() {
        document.getElementById("headerNav_search_input_desktop").focus()
    }
    function hideMobileSearch() {
        document.getElementById("header_search_mobile").style.display = "none"
        document.getElementById("headerContainer").style.borderBottom =
            "1px solid #e8e4ee"
        setMobileSearchShown(false)
    }
    function keyPressHandler(e) {
        if (e.key === "Enter") {
            search()
        }
    }
    function search() {
        if (input !== "" && input !== undefined) {
            history.push(`search_result?search=${encodeURIComponent(input)}`)
            document.getElementById("header_serach_mobile_input").blur()
            hideMobileSearch()
            minimizeWidth()
        }
    }
    useEffect(() => {
        window.addEventListener("resize", hideMenu)
    }, [])

    useEffect(() => {
        if (userData.token && userData.token !== "undefined") {
            fetch("/users/getUserInfo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                },
                body: JSON.stringify({})
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    if (data.action === "succeed") {
                        if (data.userData.display_name === "full_name") {
                            setUserName(
                                `${data.userData.first_name} ${data.userData.last_name}`
                            )
                        } else {
                            setUserName(`${data.userData.business_name}`)
                        }
                    }
                })
        }
    }, [userData])
    return (
        <div>
            <div id="headerContainer">
                <div id="headerNavContainer">
                    <div id="headerNavLeft_container">
                        <Link id="headerNavLeft" to="/" onClick={hideMenu}>
                            {/* {companyName.toUpperCase()} */}
                            <img
                                // src="/images/logoWhite.svg"
                                src="/images/logo_black.svg"
                                width="130px"
                                height="30px"
                            />
                        </Link>
                        <div
                            id="headerNav_search_input_desktop_container"
                            onClick={desktopSearchClicked}
                        >
                            <input
                                type="search"
                                placeholder={script.search}
                                id="headerNav_search_input_desktop"
                                onFocus={extendWidth}
                                onBlur={minimizeWidth}
                                onKeyPress={keyPressHandler}
                                onChange={e => setInput(e.currentTarget.value)}
                            />
                            <BiSearch
                                id="headerNav_search_input_desktop_icon"
                                size={20}
                                onMouseDown={e => {
                                    e.preventDefault()
                                }}
                                onClick={search}
                            />
                        </div>
                    </div>
                    {mobileSearchShown ? (
                        <MdClose
                            id="headerNav_search_input_mobile_icon"
                            onClick={hideMobileSearch}
                        />
                    ) : (
                        <BiSearch
                            id="headerNav_search_input_mobile_icon"
                            onClick={showMobileSearch}
                        />
                    )}
                    {userData.status === 1 ? (
                        <ul id="headerNavRight">
                            <Link
                                to="/browse"
                                className="headerNav headerNavUnderBar header_regularMenu"
                                id="headerNavLink"
                            >
                                {script.browse}
                            </Link>
                            <Link
                                to="/categories"
                                className="headerNav headerNavUnderBar header_regularMenu"
                                id="headerNavLink"
                            >
                                {script.categories}
                            </Link>
                            <Link
                                to="/create"
                                className="headerNav header_regularMenu"
                            >
                                {script.create}
                            </Link>
                            <Link
                                to="/messenger"
                                className="headerNav header_regularMenu"
                            >
                                {script.messenger}
                            </Link>
                            <li
                                className="headerNavRight_Icon header_regularMenu"
                                id="headerShowMenu"
                                onClick={showMenu}
                            >
                                {userName[0] ? userName[0].toUpperCase() : ""}
                                {/* <FaChevronCircleDown
                                    size={30}
                                    color={color3}
                                    onMouseEnter={() => {
                                        //changeColorIn(3)
                                    }}
                                    onMouseLeave={() => {
                                        //changeColorOut(3)
                                    }}
                                    onClick={showMenu}
                                /> */}
                            </li>
                            <li>
                                {menuShown === false ? (
                                    <AiOutlineMenu
                                        className="header_mobileMenu"
                                        size={30}
                                        color={color}
                                        onMouseEnter={() => {
                                            //changeColorIn(4)
                                        }}
                                        onMouseLeave={() => {
                                            //changeColorOut(4)
                                        }}
                                        onClick={showMenu}
                                    />
                                ) : (
                                    <MdClose
                                        className="header_mobileMenu"
                                        size={30}
                                        color={color}
                                        onClick={showMenu}
                                    />
                                )}
                            </li>
                        </ul>
                    ) : (
                        <ul id="headerNavRight">
                            <Link
                                to="/browse"
                                className="headerNav headerNavUnderBar header_regularMenu"
                                id="headerNavLink"
                            >
                                {script.browse}
                            </Link>
                            <Link
                                to="/categories"
                                className="headerNav headerNavUnderBar header_regularMenu"
                                id="headerNavLink"
                            >
                                {script.categories}
                            </Link>
                            <li
                                className="headerNav headerNavUnderBar header_regularMenu"
                                onClick={showLoginForm}
                            >
                                {script.create}
                            </li>
                            <li
                                className="headerNav headerNavUnderBar header_regularMenu"
                                id="headerLogIn"
                                onClick={showLoginForm}
                            >
                                {script.login}
                            </li>
                            <li
                                className="headerNav header_regularMenu"
                                id="headerSignUp"
                                onClick={showSignUpForm}
                            >
                                {script.signup}
                            </li>
                            <li>
                                {menuShown === false ? (
                                    <AiOutlineMenu
                                        className="header_mobileMenu"
                                        size={30}
                                        color={color}
                                        onMouseEnter={() => {
                                            //changeColorIn(4)
                                        }}
                                        onMouseLeave={() => {
                                            //changeColorOut(4)
                                        }}
                                        onClick={showMenu}
                                    />
                                ) : (
                                    <MdClose
                                        className="header_mobileMenu"
                                        size={30}
                                        color={color}
                                        onClick={showMenu}
                                    />
                                )}
                            </li>
                        </ul>
                    )}
                </div>
            </div>
            {userData.status === 1 ? (
                <div
                    id="headerNavRight_menu_container_position"
                    onClick={showMenu}
                >
                    <div id="headerNavRight_menu_container">
                        <ul
                            id="headerNavRight_menu"
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                        >
                            <Link
                                to="/browse"
                                className="header_mobileMenu_only"
                                onClick={showMenu}
                            >
                                {script.browse}
                            </Link>
                            <Link
                                to="/categories"
                                className="header_mobileMenu_only"
                                onClick={showMenu}
                            >
                                {script.categories}
                            </Link>
                            <Link
                                to="/create"
                                className="header_mobileMenu_only"
                                onClick={showMenu}
                            >
                                {script.create}
                            </Link>
                            <Link
                                to="/messenger"
                                className="header_mobileMenu_only"
                                onClick={showMenu}
                            >
                                {script.messenger}
                            </Link>
                            {/*<Link
                                to="/payment"
                                className="header_mobileMenu"
                                onClick={showMenu}
                            >
                                {script.payment}
                            </Link>*/}
                            <Link
                                to="/recently_viewed"
                                className="header_mobileMenu"
                                onClick={showMenu}
                            >
                                {script.recently}
                            </Link>
                            <div className="header_mobileMenu_line"></div>
                            <div className="header_mobileMenu_user">
                                <div id="header_mobileMenu_user_icon">
                                    {userName[0]
                                        ? userName[0].toUpperCase()
                                        : ""}
                                </div>
                                <div>{userName}</div>
                            </div>
                            <Link
                                to="/my_posts"
                                className="header_mobileMenu"
                                onClick={showMenu}
                            >
                                {script.myposts}
                            </Link>
                            <Link
                                to="/subscription_setting"
                                className="header_mobileMenu"
                                onClick={showMenu}
                            >
                                {script.subscription}
                            </Link>
                            <Link
                                to="/earning"
                                className="header_mobileMenu"
                                onClick={showMenu}
                            >
                                {script.earning}
                            </Link>
                            <Link
                                to="/setting"
                                className="header_desktopMenu_only"
                                onClick={showMenu}
                            >
                                {script.setting}
                            </Link>
                            <Link
                                to="/setting?page=account"
                                className="header_mobileMenu_only"
                                onClick={showMenu}
                            >
                                {script.account}
                            </Link>
                            <Link
                                to="/setting?page=language"
                                className="header_mobileMenu_only"
                                onClick={showMenu}
                            >
                                {script.language}
                            </Link>
                            <div className="header_mobileMenu_line"></div>
                            <li
                                onClick={() => {
                                    logOut()
                                    showMenu()
                                }}
                            >
                                {script.logout}
                            </li>
                        </ul>
                    </div>
                </div>
            ) : (
                <div
                    id="headerNavRight_menu_container_position"
                    onClick={showMenu}
                >
                    <div id="headerNavRight_menu_container">
                        <ul id="headerNavRight_menu">
                            <Link
                                to="/browse"
                                className="header_mobileMenu"
                                onClick={showMenu}
                            >
                                {script.browse}
                            </Link>
                            <Link
                                to="/categories"
                                className="header_mobileMenu"
                                onClick={showMenu}
                            >
                                {script.categories}
                            </Link>
                            <li
                                onClick={() => {
                                    showLoginForm()
                                    showMenu()
                                }}
                            >
                                {script.create}
                            </li>
                            <li
                                onClick={() => {
                                    showLoginForm()
                                    showMenu()
                                }}
                            >
                                {script.login}
                            </li>
                            <li
                                onClick={() => {
                                    showSignUpForm()
                                    showMenu()
                                }}
                            >
                                {script.signup}
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            <form action="javascript:void(0);" id="header_search_mobile">
                <div id="header_search_mobile_search_container">
                    <BiSearch id="header_serach_mobile_input_icon" />
                    <input
                        type="search"
                        id="header_serach_mobile_input"
                        onBlur={hideMobileSearch}
                        placeholder={script.search}
                        onKeyPress={keyPressHandler}
                        onChange={e => setInput(e.currentTarget.value)}
                    />
                </div>
            </form>
        </div>
    )
}

export default Header
