import React, { useEffect } from "react"
import { FiX } from "react-icons/fi"
import getScript from "../language"
import "../css/AdBlockerDetector.css"

const AdBlockerDetector = () => {
    const script = getScript("adblocker")

    async function detectingAdBlock() {
        try {
            await fetch(
                "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            )
            return true
        } catch (err) {
            return false
        }
    }

    function closeDisableRequest() {
        let adplin_police = document.getElementById("adplin_police")
        adplin_police.style.display = "none"
    }

    useEffect(() => {
        setTimeout(async () => {
            let response = await detectingAdBlock()
            // console.log(`Google Ad status: ${response}`)
            let adplin_police = document.getElementById("adplin_police")
            if (response) {
                adplin_police.style.display = "none"
            } else {
                adplin_police.style.display = "flex"
            }
        }, 60000 * 1) //1minutes
    }, [])

    return (
        <div id="adplin_police">
            <div id="adplin_disable_request_wrapper">
                <h3>{script.title}</h3>
                <div>{script.description1}</div>
                <div>{script.description2}</div>
                <h4>{script.description3}</h4>
                {/* <button onClick={closeDisableRequest}>{script.close}</button> */}
                <div id="adplin_disable_request_wrapper_close_wrapper">
                    <FiX
                        id="adplin_disable_request_wrapper_close"
                        size="30"
                        onClick={closeDisableRequest}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdBlockerDetector
