import React from "react"
import { companyName, mainColor } from "../../config/config"
import { FiMinimize2, FiDollarSign, FiThumbsUp } from "react-icons/fi"
import getScript from "../../language"
//import "../../css/Home_Introduction.css"
import "../../css/home/Introduction.css"

const Introduction = () => {
    let script = getScript("introduction")
    return (
        <div id="home_introduction_container">
            <div id="home_introduction_title">{script.different}</div>
            <div id="home_introduction_text_container">
                <div id="home_introduction_text">
                    <FiMinimize2
                        size={30}
                        style={{ color: "#2E3A59", marginBottom: 10 }}
                    />
                    <div>{script.different1}</div>
                </div>
                <div id="home_introduction_text">
                    <FiDollarSign
                        size={30}
                        style={{ color: "#2E3A59", marginBottom: 10 }}
                    />
                    <div>{script.different2}</div>
                </div>
                <div id="home_introduction_text">
                    <FiThumbsUp
                        size={30}
                        style={{ color: "#2E3A59", marginBottom: 10 }}
                    />
                    <div>{script.different3}</div>
                </div>
            </div>
        </div>
    )
}

export default Introduction
