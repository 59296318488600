import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { BiMenuAltLeft } from "react-icons/bi"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import LazyLoad from "react-lazyload"
import "../css/My_Posts.css"

const My_Posts = () => {
    const userData = useSelector(state => state.user)
    const history = useHistory()
    const [list, setList] = useState([])
    const [sort, setSort] = useState("Date")
    const [arrow, setArrow] = useState([<IoIosArrowDown />])
    const [showMenu, setShowMenu] = useState(false)
    const [deletePostID, setDeletePostID] = useState()

    function menuHandlerDesktop() {
        let mobileSortMenu = document.getElementById(
            "my_posts_list_header_desktop_menu"
        )
        if (showMenu) {
            setShowMenu(false)
            setArrow([<IoIosArrowDown />])
            mobileSortMenu.style.display = "none"
        } else {
            setShowMenu(true)
            setArrow([<IoIosArrowUp />])
            mobileSortMenu.style.display = "flex"
        }
    }

    function menuHandler() {
        let mobileSortMenu = document.getElementById(
            "my_posts_list_header_mobile_menu"
        )
        if (showMenu) {
            setShowMenu(false)
            setArrow([<IoIosArrowDown />])
            mobileSortMenu.style.display = "none"
        } else {
            setShowMenu(true)
            setArrow([<IoIosArrowUp />])
            mobileSortMenu.style.display = "flex"
        }
    }

    function viewMyPost(id) {
        history.push(`/post?id=${id}`)
    }

    function editMyPost(id) {
        history.push(`/edit_post?id=${id}`)
    }

    function openDeleteConfirm(id) {
        setDeletePostID(id)
        document.getElementById("my_posts_delete_post").style.display = "flex"
    }
    function closeDeleteConfirm() {
        document.getElementById("my_posts_delete_post").style.display = "none"
    }
    function deleteMyPost(id) {
        document.getElementById("my_posts_delete_post").style.display = "none"
        //DELETE
        let newList = list.filter(item => {
            return item.id !== deletePostID
        })

        setList(newList)
        fetch("/post/delete_my_post", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                post_id: deletePostID
            })
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                //console.log(res)
            })
    }

    useEffect(() => {
        setArrow([<IoIosArrowDown />])
        document.getElementById(
            "my_posts_list_header_mobile_menu"
        ).style.display = "none"

        switch (sort) {
            case "Date":
                setList(
                    list.sort((a, b) => {
                        return (
                            new Date(a.creation_time) -
                            new Date(b.creation_time)
                        )
                    })
                )
                break
            case "Name":
                setList(
                    list.sort((a, b) =>
                        a.title < b.title ? -1 : a.title > b.title ? 1 : 0
                    )
                )
                break
            case "Category":
                setList(
                    list.sort((a, b) =>
                        a.category_name < b.category_name
                            ? -1
                            : a.category_name > b.category_name
                            ? 1
                            : 0
                    )
                )
                break
        }
        setShowMenu(false)
    }, [sort])

    useEffect(() => {
        fetch("/post/get_my_posts", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`
                //"Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status === "unauthorized") {
                    history.push("/login")
                } else {
                    setList(res.results)
                }
            })
    }, [])
    return (
        <div>
            <div id="my_posts_top_container">
                <div>My posts</div>
                <div onClick={() => history.push("/create")}>Create</div>
            </div>
            <div id="my_posts_box_container">
                <div
                    id="my_posts_list_header"
                    className="my_posts_grid_container"
                >
                    <div
                        id="my_posts_list_header_desktop"
                        onClick={menuHandlerDesktop}
                    >
                        <BiMenuAltLeft />
                        <div id="my_posts_list_header_desktop_sort">{sort}</div>
                        {arrow}
                        <div id="my_posts_list_header_desktop_menu">
                            <li onClick={() => setSort("Date")}>Date</li>
                            <li onClick={() => setSort("Name")}>Name</li>
                            <li onClick={() => setSort("Category")}>
                                Category
                            </li>
                        </div>
                    </div>
                    <div>Title</div>
                    <div>Date</div>
                    <div>Category</div>
                    <div></div>
                </div>
                <div id="my_posts_list_header_mobile" onClick={menuHandler}>
                    <BiMenuAltLeft />
                    <div id="my_posts_list_header_mobile_sort">{sort}</div>
                    {arrow}
                    <div id="my_posts_list_header_mobile_menu">
                        <li onClick={() => setSort("Date")}>Date</li>
                        <li onClick={() => setSort("Name")}>Name</li>
                        <li onClick={() => setSort("Category")}>Category</li>
                    </div>
                </div>
                {list.map(item => {
                    let url = `https://adplin.s3.amazonaws.com/${
                        item.id
                    }/resize/cover_${item.main_image.split(".")[0]}.jpeg`
                    return (
                        <LazyLoad
                            height={100}
                            offset={200}
                            style={{ width: "100%" }}
                            once
                            placeholder={
                                <div
                                    style={{
                                        height: "200px",
                                        width: "100%",
                                        backgroundColor: "rgb(231 230 230)"
                                    }}
                                ></div>
                            }
                        >
                            <li className="my_posts_grid_container my_posts_list">
                                <img
                                    src={url}
                                    alt={item.title}
                                    className="my_posts_images"
                                    onClick={() => viewMyPost(item.id)}
                                />
                                <div className="my_posts_grid_container2">
                                    <div onClick={() => viewMyPost(item.id)}>
                                        {item.title}
                                    </div>
                                    <div onClick={() => viewMyPost(item.id)}>
                                        {new Date(
                                            item.creation_time
                                        ).toDateString()}
                                    </div>
                                    <div onClick={() => viewMyPost(item.id)}>
                                        {item.category_name}
                                    </div>
                                    <div className="my_posts_list_buttons">
                                        <div
                                            onClick={() => editMyPost(item.id)}
                                        >
                                            Edit
                                        </div>
                                        <div
                                            onClick={() =>
                                                openDeleteConfirm(item.id)
                                            }
                                        >
                                            Delete
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </LazyLoad>
                    )
                })}
            </div>
            <div id="my_posts_delete_post">
                <div id="my_posts_delete_post_box">
                    <div>Delete my post?</div>
                    <div>
                        <div onClick={closeDeleteConfirm}>Cancel</div>
                        <div onClick={deleteMyPost}>Delete</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default My_Posts
