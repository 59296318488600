import React from "react"
import { CardElement } from "@stripe/react-stripe-js"

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            //backgroundColor: "#d1d1d1",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
}

const LabelStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    border: "1px solid #e0e1e4"
}

function CardSection() {
    return (
        <label style={LabelStyle}>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
        </label>
    )
}
export default CardSection
