import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import List_Card from "../components/card/List_Card"
import Search_Horizontal from "../components/adsense/Search_Horizontal"
import "../css/Search_Result.css"

const Search_Result = props => {
    const userData = useSelector(state => state.user)
    let query = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState()
    const [filter, setFilter] = useState([true, true, true, true])
    const [categoryList, setCategoryList] = useState([])
    const [titleList, setTitleList] = useState([])
    const [tagList, setTagList] = useState([])
    const [userList, setUserList] = useState([])

    function changeTitleList(index, id) {
        let newList = [...titleList]
        newList[index].saved = id
        setTitleList(newList)
    }

    function changeTagList(index, id) {
        let newList = [...tagList]
        newList[index].saved = id
        setTagList(newList)
    }

    function changeUserList(index, id) {
        let newList = [...userList]
        newList[index].saved = id
        setUserList(newList)
    }

    function changeFilter(index) {
        let currentFilter = [...filter]
        if (currentFilter[index]) {
            currentFilter[index] = false
        } else {
            currentFilter[index] = true
        }
        console.log(currentFilter)
        setFilter(currentFilter)
    }

    useEffect(() => {
        if (query.get("search")) {
            setSearch(query.get("search"))
            let url1
            let url2
            let option1
            let option2

            if (userData.token && userData.token !== "undefined") {
                url1 = "/search"
                option1 = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData.token}`
                    },
                    body: JSON.stringify({
                        query: query.get("search")
                    })
                }
                url2 = "/search/search_history_save_logged_in"
                option2 = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData.token}`
                    },
                    body: JSON.stringify({
                        query: query.get("search")
                    })
                }
            } else {
                url1 = "/search"
                option1 = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        query: query.get("search")
                    })
                }
                url2 = "/search/search_history_save"
                option2 = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        query: query.get("search")
                    })
                }
            }

            fetch(url1, option1)
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    setCategoryList(res.category)
                    setTitleList(res.title)
                    setTagList(res.tag)
                    setUserList(res.user)
                    setLoading(false)
                })
            //Search from db

            fetch(url2, option2)
        }
    }, [query.get("search")])

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <div>
                    <div id="search_result_ad">
                        <Search_Horizontal />
                    </div>
                    <div id="search_result_title">Results for "{search}"</div>
                    {categoryList.length === 0 &&
                    titleList.length === 0 &&
                    tagList.length === 0 &&
                    userList.length === 0 ? (
                        <div>
                            Sorry, We could not find any result. Please try
                            again with different keyword.
                        </div>
                    ) : (
                        <></>
                    )}
                    <div id="search_result_filter_container">
                        {categoryList.length > 0 ||
                        titleList.length > 0 ||
                        tagList.length > 0 ||
                        userList.length > 0 ? (
                            <span id="search_result_search_for">
                                Search for:{" "}
                            </span>
                        ) : (
                            <></>
                        )}
                        {categoryList.length > 0 ? (
                            <span
                                className="search_result_filters"
                                style={
                                    filter[0]
                                        ? { color: "#8134bd" }
                                        : { color: "#757477" }
                                }
                                onClick={() => changeFilter(0)}
                            >
                                category
                            </span>
                        ) : (
                            <></>
                        )}
                        {titleList.length > 0 ? (
                            <span
                                className="search_result_filters"
                                style={
                                    filter[1]
                                        ? { color: "#8134bd" }
                                        : { color: "#757477" }
                                }
                                onClick={() => changeFilter(1)}
                            >
                                services
                            </span>
                        ) : (
                            <></>
                        )}
                        {tagList.length > 0 ? (
                            <span
                                className="search_result_filters"
                                style={
                                    filter[2]
                                        ? { color: "#8134bd" }
                                        : { color: "#757477" }
                                }
                                onClick={() => changeFilter(2)}
                            >
                                tag
                            </span>
                        ) : (
                            <></>
                        )}
                        {userList.length > 0 ? (
                            <span
                                className="search_result_filters"
                                style={
                                    filter[3]
                                        ? { color: "#8134bd" }
                                        : { color: "#757477" }
                                }
                                onClick={() => changeFilter(3)}
                            >
                                user
                            </span>
                        ) : (
                            <></>
                        )}
                    </div>
                    {categoryList.length > 0 && filter[0] ? (
                        <>
                            <div className="search_result_sub_title">
                                Relevent Categories
                            </div>

                            <div id="search_result_category_list_container">
                                {categoryList.map(item => {
                                    return (
                                        <Link
                                            to={`/category_list?id=${item.id}`}
                                            className="search_result_category_list"
                                        >
                                            {item.name}
                                        </Link>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {titleList.length > 0 && filter[1] ? (
                        <>
                            <div className="search_result_sub_title">
                                Relevent services
                            </div>
                            <div className="search_result_list_container">
                                {titleList.map((item, index) => {
                                    return (
                                        <List_Card
                                            item={item}
                                            index={index}
                                            passedFunction={
                                                props.passedFunction
                                            }
                                            changeList={changeTitleList}
                                        />
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {tagList.length > 0 && filter[2] ? (
                        <>
                            <div className="search_result_sub_title">
                                Relevent Tags
                            </div>
                            <div className="search_result_list_container">
                                {tagList.map((item, index) => {
                                    return (
                                        <List_Card
                                            item={item}
                                            index={index}
                                            passedFunction={
                                                props.passedFunction
                                            }
                                            changeList={changeTagList}
                                        />
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {userList.length > 0 && filter[3] ? (
                        <>
                            <div className="search_result_sub_title">
                                Relevent Users
                            </div>
                            <div className="search_result_list_container">
                                {userList.map((item, index) => {
                                    return (
                                        <List_Card
                                            item={item}
                                            index={index}
                                            passedFunction={
                                                props.passedFunction
                                            }
                                            changeList={changeUserList}
                                        />
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </>
    )
}

export default Search_Result
