import React, { useState } from "react"
import { MdInfoOutline } from "react-icons/md"
import UploadSingleFile from "./UploadSingleFile.js"
import UploadMultipleFiles from "./UploadMultipleFiles.js"
import "../../css/UploadFiles.css"

const UploadFiles = ({ updateParent, post_id, mainFile, subFile }) => {
    function showInfo() {}
    return (
        <div>
            <div id="create_edit_upload_input">
                <div className="create_edit_box_container">
                    <div className="create_edit_title create_edit_picture_title">
                        Upload main image
                        <div className="create_edit_picture_info_container">
                            <MdInfoOutline onClick={showInfo} />
                            <div className="create_edit_picture_info">
                                Images will be resized to 1024px / 768px
                            </div>
                        </div>
                    </div>
                    <UploadSingleFile
                        updateParent={updateParent}
                        post_id={post_id}
			mainFile={mainFile}
                    />
                </div>
                <div className="create_edit_box_container">
                    <div className="create_edit_title create_edit_picture_title">
                        Upload more files{" "}
                        <div className="create_edit_picture_info_container">
                            <MdInfoOutline onClick={showInfo} />
                            <div className="create_edit_picture_info">
                                Images will be resized to 1024px / 768px
                            </div>
                        </div>
                    </div>
                    <UploadMultipleFiles
                        updateParent={updateParent}
                        post_id={post_id}
                        subFile={subFile}
                    />
                </div>
            </div>
        </div>
    )
}

export default UploadFiles
