import React from "react"
import { AiOutlineInfoCircle } from "react-icons/ai"
import "../../css/notification/Relative_Notification.css"

const Relative_Notification = props => {
    return (
        <div id="relative_notification_box_wrapper">
            {props.img ? (
                <img src={props.img} width="205px" height="205px" />
            ) : (
                <></>
            )}
            <div id="relative_notification_title">{props.title}</div>
            <div id="relative_notification_message">{props.message}</div>
            {props.info ? (
                <div id="relative_notification_info">
                    <AiOutlineInfoCircle id="relative_notification_info_icon" />
                    {props.info}
                </div>
            ) : (
                <></>
            )}
            {props.button ? (
                <div id="relative_button" onClick={props.button}>
                    {props.buttonName}
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default Relative_Notification
