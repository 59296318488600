import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
    Elements,
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import "../css/Checkout.css"
const stripePromise = loadStripe(
    "pk_test_51I5j8JCt4Hzp7gbFuLq4q67L0xAAW4hyrRmRUu63dpV3qzQcB0ZP6sM0AeKB8i4pPGIVzoroRYpsWY7qv2n2Nk4H00WPzWwntW"
)

const Checkout = () => {
    return (
        <Elements stripe={stripePromise}>
            <Subscription_Form />
        </Elements>
    )
}

const Subscription_Form = () => {
    const userData = useSelector(state => state.user)
    const stripe = useStripe()
    const elements = useElements()
    const [message, setMessage] = useState("")

    const sendToSucceed = () => {
        console.log("sendToSucceed function called")
    }

    const handleSubmit = async e => {
        e.preventDefault()
        console.log("handle submit")

        if (!stripe || !elements) {
            return
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement)
            // card: elements.getElement(CardElement)
            // card: {
            //     exp_month: elements.getElement(CardExpiryElement),
            //     exp_year: elements.getElement(CardExpiryElement),
            //     number: elements.getElement(CardNumberElement),
            //     cvc: elements.getElement(CardCvcElement)
            // }
        })
        if (error) {
            setMessage(error.code)
        } else {
            setMessage("")
            fetch("/stripe", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    payment_method: paymentMethod
                })
            })
                .then(res => {
                    return res.json()
                })
                .then(res => {
                    console.log(res)
                    if (res.status === "succeed") {
                        console.log("succeed")
                        console.log(res.subscription.status)
                        if (res.subscription.status === "incomplete") {
                            stripe
                                .confirmCardPayment(
                                    res.subscription.latest_invoice
                                        .payment_intent.client_secret
                                )
                                .then(function (result) {
                                    // Handle result.error or result.paymentIntent
                                    console.log(result)
                                    if (result.error) {
                                        setMessage(result.error.code)
                                    } else if (
                                        result.paymentIntent.status ===
                                        "succeeded"
                                    ) {
                                        sendToSucceed()
                                    }
                                })
                        }
                    } else {
                        setMessage(res.status.code)
                        console.log(res.status.code)
                    }
                })
        }

        console.log(paymentMethod)
    }

    const checkout = () => {
        fetch("/stripe/checkout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                priceId: "price_1I5jUQCt4Hzp7gbFHktza0SA"
            })
        })
            .then(function (result) {
                return result.json()
            })
            .then(function (data) {
                // Call Stripe.js method to redirect to the new Checkout page
                stripe
                    .redirectToCheckout({
                        sessionId: data.sessionId
                    })
                    .then(result => {
                        console.log(result)
                    })
                // .then(handleResult)
            })
    }
    return (
        <form id="checkout_form_container" onSubmit={handleSubmit}>
            <div>Credit or Debit card</div>
            <div id="checkout_card_row">
                <div id="checkout_form_stripe_container">
                    {/* <CardElement options={CARD_OPTIONS} /> */}
                    <CardNumberElement />
                    <CardExpiryElement />
                    <CardCvcElement />
                </div>
                <div id="checkout_form_button_container">
                    <button type="submit" disabled={!stripe}>
                        Pay
                    </button>
                </div>
            </div>
            <div id="checkout_message">{message}</div>

            <div onClick={checkout}>CHECKOUT</div>
        </form>
    )
}

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            //width: '500px',
            //height: '300px',
            //padding: '10px',
            //borderRadius: '5px',
            //backgroundColor: '#d0d0d0',
            iconColor: "purple",
            color: "black",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "18px",
            lineHeight: "18px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fce883" },
            "::placeholder": { color: "#87bbfd" }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
}

export default Checkout
