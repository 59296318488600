import React, { useState, useEffect } from "react"
import "../../css/post/Tag.css"

const Tag = props => {
    const [tag, setTag] = useState([])
    // useEffect(() => {
    //     console.log(props.tag)
    //     if (props.tag) {
    //         props.tag.split(",").map(item => {
    //             setTag(old => {
    //                 return [...old, item]
    //             })
    //         })
    //     }
    // }, [props])
    return (
        <div id="post_tag_container">
            {props.tag.map(item => {
                return <div className="post_tag_item_container">{item}</div>
            })}
        </div>
    )
}

export default Tag
