import React, { useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import "../components/LoginForm"
import "../css/Home.css"

const Login = props => {
    const query = new URLSearchParams(useLocation().search)
    const history = useHistory()
    const userData = useSelector(state => state.user)
    //let domain = "http://localhost:3000/"
    useEffect(() => {
        if (query.get("action") == "sign_in") {
            if (query.get("to") == "pop") {
                props.passedFunction("Sign in to", "pop")
            } else {
                props.passedFunction("Sign in to", "")
            }
        }
        document.getElementById("loginForm").style.display = "flex"
    }, [])

    useEffect(() => {
        if (
            userData.token &&
            userData.token !== "undefined" &&
            userData.token !== "null" &&
            query.get("page") !== undefined
        ) {
            history.push(query.get("page"))
        }
    }, [userData])
    return <div></div>
}

export default Login
