import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { IoIosArrowDown } from "react-icons/io"
import MyPaymentLink from "../components/earning/MyPaymentLink"
import Relative_Notification from "../components/notification/Relative_Notification"
import Loading from "../components/Loading"
import "../css/Earning.css"

const Earning = props => {
    const userData = useSelector(state => state.user)
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [accountStatus, setAccountStatus] = useState()
    const [balance, setBalance] = useState({})
    const [charges, setCharges] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [moreClicked, setMoreClicked] = useState(false)
    const [lastCharge, setLastCharge] = useState(undefined)
    const [myPaymentLink, setMyPaymentLink] = useState("")

    function getLink() {
        setLoading(true)
        fetch("/stripe/connect/onboard-user", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(res => {
                if (res.url) {
                    window.location = res.url
                }
                //setLoading(false)
            })
    }

    function getPaymentLink() {
        setLoading(true)
        fetch("/users/getEncryptedPublicID", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setMyPaymentLink(
                    `https://adplin.com/payment?id=${res.encryptedUserID}`
                )
                document.getElementById(
                    "earning_mypaymentlink_wrapper"
                ).style.display = "flex"
                setLoading(false)
            })
    }

    function getMoreCharges() {
        if (hasMore) {
            setMoreClicked(true)
            fetch("/stripe/getMoreCharges", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    lastCharge: lastCharge
                })
            })
                .then(res => {
                    return res.json()
                })
                .then(res => {
                    let copiedArr = [...charges, ...res.charges.data]

                    setCharges(copiedArr)
                    setHasMore(res.charges.has_more)
                    setLastCharge(
                        res.charges.data[res.charges.data.length - 1].id
                    )
                    setMoreClicked(false)
                })
        }
    }

    function earningTop() {
        if (accountStatus === "enabled") {
            return (
                <div id="earning_title_wrapper">
                    <div id="earning_title">My earning</div>
                    <div id="earning_title_buttons">
                        <div onClick={getLink}>Update account</div>
                        <div onClick={getPaymentLink}>My payment link</div>
                    </div>
                </div>
            )
        } else if (accountStatus === "notRegister") {
            return (
                <div id="earning_title_wrapper_non_enabled">
                    <div id="earning_title">My earning</div>
                    <Relative_Notification
                        img="/images/notification/bank.svg"
                        button={getLink}
                        buttonName="Register"
                        title="Add a bank"
                        message="You have not linked your bank account to receive payments. Please register first."
                        info="You will be redirected to the Stripe registration page."
                    />
                </div>
            )
        } else if (accountStatus === "disabled") {
            return (
                <div id="earning_title_wrapper_non_enabled">
                    <div id="earning_title">My earning</div>
                    <Relative_Notification
                        img="/images/notification/rejected.svg"
                        title="Stripe has rejected your account."
                        message="We are sorry to tell you that your account was rejected by stripe to enable charges and payout. Please contact Adplin or Stripe to get further information."
                    />
                </div>
            )
        } else if (accountStatus === "pending") {
            return (
                <div id="earning_title_wrapper_non_enabled">
                    <div id="earning_title">My earning</div>
                    <Relative_Notification
                        img="/images/notification/pending.svg"
                        title="Pending"
                        message="Stripe is verifying the information that was provided. Once it is verified, you can start accepting payments."
                    />
                </div>
            )
        } else if (accountStatus === "rejected") {
            return (
                <div id="earning_title_wrapper_non_enabled">
                    <div id="earning_title">My earning</div>
                    <Relative_Notification
                        img="/images/notification/pending.svg"
                        title="Rejected"
                        message="Stripe is verifying the information that was provided. Once it is verified, you can start accepting payments."
                    />
                </div>
            )
        }
    }

    function openOrCloseDetail(num) {
        let id = `earning_payment_detail_id_${num}`
        let list = document.getElementsByClassName(id)
        let button = document.getElementById(id)
        if (button.style.transform == "rotate(180deg)") {
            button.style.transform = ""
        } else {
            button.style.transform = "rotate(180deg)"
        }
        for (let i = 0; i < list.length; i++) {
            if (list[i].style.display == "table-row") {
                list[i].style.display = "none"
            } else {
                list[i].style.display = "table-row"
            }
        }
    }

    function closeDetail(num) {
        let id = `earning_payment_detail_id_${num}`
        let list = document.getElementsByClassName(id)
        for (let i = 0; i < list.length; i++) {
            list[i].style.display = "none"
        }
    }

    useEffect(() => {
        if (
            !userData.token ||
            userData.token === "undefined" ||
            userData.token === "null"
        ) {
            history.push("/login?action=sign_in&to=pop&page=earning")
            // props.passedFunction("Sign in to", "pop")
            // document.getElementById("loginForm").style.display = "flex"
        } else {
            fetch("/stripe/checkChargeEnabled", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type": "application/json"
                }
            })
                .then(res => {
                    return res.json()
                })
                .then(res => {
                    console.log(res)
                    if (res.status === "unauthorized") {
                        history.push(
                            "/login?action=sign_in&to=pop&page=earning"
                        )
                        // props.passedFunction("Sign in to", "pop")
                        // document.getElementById("loginForm").style.display =
                        //     "flex"
                    } else if (res.status === "notRegister") {
                        setAccountStatus("notRegister")
                    } else if (res.status === "disabled") {
                        setAccountStatus("disabled")
                    } else if (res.status === "enabled") {
                        setBalance(res.balance)
                        setCharges(res.charges.data)
                        setHasMore(res.charges.has_more)
                        if (res.charges.has_more) {
                            setLastCharge(
                                res.charges.data[res.charges.data.length - 1].id
                            )
                        }
                        setAccountStatus("enabled")
                    } else if (res.status === "pending") {
                        setAccountStatus("pending")
                    } else if (res.status === "err") {
                        setAccountStatus("err")
                        history.push("earning")
                    } else if (res.status === "rejected") {
                        setAccountStatus("rejected")
                    } else {
                        //console.log(res.status)
                    }
                    setLoading(false)
                })
        }
    }, [])
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    {earningTop()}
                    {accountStatus == "enabled" ? (
                        <>
                            <div>
                                <table id="earning_balance_table_wrapper">
                                    <tr>
                                        <th>Available Balance</th>
                                        <th>Pending Balance</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            ${balance.available[0].amount / 100}
                                        </td>
                                        <td>
                                            ${balance.pending[0].amount / 100}
                                        </td>
                                    </tr>
                                </table>
                                <div id="earning_payment_title">Payments</div>
                                <table id="earning_table">
                                    <tr>
                                        <th>Date</th>
                                        <th>Customer email</th>
                                        <th>Amount</th>
                                        {/* <th>Status</th> */}
                                        <th></th>
                                    </tr>
                                    {charges == undefined ||
                                    charges.length === 0 ? (
                                        <tr id="earning_no_payment">
                                            <td colspan="4">
                                                No payments received yet.
                                            </td>
                                        </tr>
                                    ) : (
                                        charges.map((obj, index) => {
                                            if (obj.status != "succeeded") {
                                                return
                                            }
                                            let detailID = `earning_payment_detail_id_${index}`
                                            let dateObj = new Date(
                                                obj.created * 1000
                                            )
                                            let month = dateObj.toLocaleString(
                                                "default",
                                                { month: "long" }
                                            )
                                            let date = dateObj.getDate()
                                            let year = dateObj.getFullYear()
                                            let time = dateObj.toLocaleString(
                                                "en-US"
                                            ) //`${year}/${month}/${date} - ${dateObj.getHours()} ${dateObj.getMinutes()} $ `
                                            return (
                                                <>
                                                    <tr
                                                        className="earning_payment_wrapper"
                                                        onClick={() => {
                                                            openOrCloseDetail(
                                                                index
                                                            )
                                                        }}
                                                    >
                                                        <td>
                                                            {`${month} ${date}`}
                                                        </td>
                                                        <td className="earning_payment_email_address">
                                                            {obj.metadata.buyer}
                                                        </td>
                                                        {obj.disputed ||
                                                        obj.refunded ? (
                                                            <td className="earning_payment_bad_amount">
                                                                $
                                                                {obj.amount /
                                                                    100}
                                                            </td>
                                                        ) : (
                                                            <td className="earning_payment_amount">
                                                                $
                                                                {obj.amount /
                                                                    100}
                                                            </td>
                                                        )}

                                                        {/* <td>{obj.status}</td> */}
                                                        <td>
                                                            <IoIosArrowDown
                                                                className="earning_payment_detail_button"
                                                                id={detailID}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        className={`earning_payment_detail_wrapper ${detailID}`}
                                                        onClick={() => {
                                                            closeDetail(index)
                                                        }}
                                                    >
                                                        <td colspan="4">
                                                            <span className="earning_payment_detail_title">
                                                                Time:{" "}
                                                            </span>{" "}
                                                            {time}
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        className={`earning_payment_detail_wrapper ${detailID}`}
                                                        onClick={() => {
                                                            closeDetail(index)
                                                        }}
                                                    >
                                                        <td colspan="4">
                                                            <span className="earning_payment_detail_title">
                                                                Card:{" "}
                                                            </span>{" "}
                                                            {
                                                                obj
                                                                    .payment_method_details
                                                                    .card.brand
                                                            }
                                                            /
                                                            {
                                                                obj
                                                                    .payment_method_details
                                                                    .card
                                                                    .exp_month
                                                            }
                                                            /
                                                            {
                                                                obj
                                                                    .payment_method_details
                                                                    .card
                                                                    .exp_year
                                                            }
                                                            / xxxx-
                                                            {
                                                                obj
                                                                    .payment_method_details
                                                                    .card.last4
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        className={`earning_payment_detail_wrapper ${detailID}`}
                                                        onClick={() => {
                                                            closeDetail(index)
                                                        }}
                                                    >
                                                        <td colspan="4">
                                                            <span className="earning_payment_detail_title">
                                                                Note:{" "}
                                                            </span>
                                                            {obj.metadata.note}
                                                        </td>
                                                    </tr>
                                                    {
                                                        //if refunded
                                                        obj.refunded &&
                                                        obj.refunds.data
                                                            .length > 0 ? (
                                                            <>
                                                                <tr
                                                                    className={`earning_payment_detail_wrapper ${detailID}`}
                                                                    onClick={() => {
                                                                        closeDetail(
                                                                            index
                                                                        )
                                                                    }}
                                                                >
                                                                    <td className="earning_payment_refund_info_title">
                                                                        Refund
                                                                        info
                                                                    </td>
                                                                </tr>
                                                                {obj.refunds.data.map(
                                                                    (
                                                                        refObj,
                                                                        refInd
                                                                    ) => {
                                                                        return (
                                                                            <>
                                                                                <tr
                                                                                    className={`earning_payment_detail_wrapper ${detailID} earning_payment_detail_refund_wrapper`}
                                                                                    onClick={() => {
                                                                                        closeDetail(
                                                                                            index
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <td colspan="4">
                                                                                        <span className="earning_payment_detail_title earning_payment_detail_refund_title">
                                                                                            Refunded
                                                                                            time:{" "}
                                                                                        </span>
                                                                                        {new Date(
                                                                                            refObj.created *
                                                                                                1000
                                                                                        ).toLocaleString(
                                                                                            "en-US"
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr
                                                                                    className={`earning_payment_detail_wrapper ${detailID} earning_payment_detail_refund_wrapper`}
                                                                                    onClick={() => {
                                                                                        closeDetail(
                                                                                            index
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <td colspan="4">
                                                                                        <span className="earning_payment_detail_title earning_payment_detail_refund_title">
                                                                                            Amount:{" "}
                                                                                        </span>

                                                                                        $
                                                                                        {refObj.amount /
                                                                                            100}
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    }
                                                                )}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </>
                                            )
                                        })
                                    )}
                                </table>
                                {hasMore ? (
                                    moreClicked ? (
                                        <div id="earning_more_charges_button">
                                            loading
                                        </div>
                                    ) : (
                                        <div
                                            id="earning_more_charges_button"
                                            onClick={getMoreCharges}
                                        >
                                            see more
                                        </div>
                                    )
                                ) : (
                                    <></>
                                )}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
            <MyPaymentLink link={myPaymentLink} />
        </>
    )
}

export default Earning
