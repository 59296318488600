import React from "react"
import { Link } from "react-router-dom"
import Subscription from "../components/subscription/Subscription.js"
import "../css/Create_Success.css"

const Edit_Success = () => {
    return (
        <div>
            <div id="create_success_text_container">
                <div id="create_success_title">YOUR POST HAS BEEN EDITED!</div>
                <Link id="create_success_button" to="/my_posts">
                    Click to see your posts
                </Link>
                <img src="/images/logo_icon.png" id="create_success_image" />
            </div>
            <div>
                <Subscription />
            </div>
        </div>
    )
}

export default Edit_Success

