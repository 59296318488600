import React from "react"
import List from "../card/List"
import { Link } from "react-router-dom"
import getScript from "../../language"
import "../../css/browse/Browse_category.css"

const Browse_categories = () => {
    const browseScript = getScript("browse")

    return (
        <div id="browse_categories_container">
            <div id="browse_categories_title_container">
                <div className="browse_title">{browseScript.popular}</div>
                <Link id="browse_categories_see_all" to="/categories">
                    {browseScript.see}
                </Link>
            </div>
            <div id="browse_popular_categories">
                <List
                    name={"Web Develope"}
                    source={"categories/main_pc_web.jpg"}
                    category_id={8}
                />
                <List
                    name={"Logo Design"}
                    source={"categories/main_pc_logo.jpg"}
                    category_id={1}
                />
                <List
                    name={"Video & Animation"}
                    source={"categories/main_pc_video.jpg"}
                    category_id={10}
                />
                <List
                    name={"Digital Marketing"}
                    source={"categories/main_pc_marketing.jpg"}
                    category_id={11}
                />

                <List
                    name={"Covid-19 Related"}
                    source={"categories/main_pc_covid.jpg"}
                    category_id={13}
                />
                <List
                    name={"Product Ad Design"}
                    source={"categories/main_pc_product.jpg"}
                    category_id={12}
                />
                <List
                    name={"Menu Design"}
                    source={"categories/main_pc_menu.jpg"}
                    category_id={14}
                />
                <List
                    name={"Sign Design"}
                    source={"categories/main_pc_sign.jpg"}
                    category_id={2}
                />
                {/* <List name={"3D Art"} source={"categories/main_pc_.jpg"} category_id={4} />
                <List
                    name={"Mobile App Develope"}
                    source={"main14.jpg"}
                    category_id={9}
                /> */}
            </div>
        </div>
    )
}

export default Browse_categories
