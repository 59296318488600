import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"
import { AiFillHeart } from "react-icons/ai"
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi"
import getScript from "../../language"
import "../../css/browse/Trending2.css"

const Popular_list = props => {
    const userData = useSelector(state => state.user)
    const browseScript = getScript("browse")
    const [post, setPost] = useState([])
    const [loaded, setLoaded] = useState(0)

    function heartClicked(e, id, index, action) {
        e.preventDefault()
        //check if user is logged in
        //if logged in > save post on db depends on already saved or not
        //else open log in window
        if (userData.token && userData.token !== "undefined") {
            if (action === "save") {
                let newPost = [...post]
                newPost[index].saved = 1
                setPost(newPost)
            } else {
                let newPost = [...post]
                newPost[index].saved = undefined
                setPost(newPost)
            }
            //fech to server and update database
            fetch("/post/post_save", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                },
                body: JSON.stringify({
                    action: action,
                    post_id: id
                })
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    //console.log(data)
                })
        } else {
            props.passedFunction("Sign in to", "pop")
            document.getElementById("loginForm").style.display = "flex"
        }
    }

    function clickController(e, to) {
        let box = e.currentTarget.parentElement
        if (to === "left") {
            box.scrollLeft -= box.clientWidth
        } else if (to === "right") {
            box.scrollLeft += +box.clientWidth
        }
    }

    function loadedEffect(e) {
        let box = e.currentTarget.parentElement.parentElement
        let count = loaded + 1
        setLoaded(count)
        if (count >= post.length) {
            box.style.marginTop = "0px"
        }
    }

    function kFormatter(num) {
        return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num)
    }

    useEffect(() => {
        //get list data
        let url
        if (userData.token && userData.token !== "undefined") {
            url = `/browse/get/popular?token=${userData.token}&category_id=${props.category_id}`
        } else {
            url = `/browse/get/popular?token=undefined&category_id=${props.category_id}`
        }

        fetch(url)
            .then(res => res.json())
            .then(res => {
                if (!res.data) {
                } else {
                    setPost(res.data)
                }
            })
    }, [])

    return (
        <LazyLoad
            id="browse_trending2_container"
            once
            height={333}
            placeholder={<PlaceHolder />}
        >
            <div className="browse_title">
                {browseScript[props.category_id]}
            </div>
            <div id="browse_trending2_list_container">
                <BiChevronsLeft
                    id="browse_trending2_list_controller_left"
                    onClick={e => clickController(e, "left")}
                />
                <BiChevronsRight
                    id="browse_trending2_list_controller_right"
                    onClick={e => clickController(e, "right")}
                />
                {post.map((item, index) => {
                    let name
                    if (item.display_name === "full_name") {
                        name = `${item.first_name} ${item.last_name}`
                    } else {
                        name = `${item.business_name}`
                    }

                    let title = ""
                    if (item.title.length >= 25) {
                        for (let i = 0; i < 20; i++) {
                            title += item.title[i]
                        }
                        title += ".."
                    } else {
                        title = item.title
                    }

                    return (
                        <Link
                            to={`/post?id=${item.id}`}
                            className="browse_trending2_list"
                        >
                            <img
                                className="browse_trending2_list_img"
                                src={`https://adplin.s3.amazonaws.com/${
                                    item.id
                                }/resize/cover_${
                                    item.main_image.split(".")[0]
                                }.jpeg`}
                                alt={item.title}
                                onLoad={loadedEffect}
                            />
                            <div className="browse_trending2_user_info">
                                <div className="browse_trending2_user_info_left">
                                    <div className="browse_trending2_user_info_left_icon">
                                        {name[0]}
                                    </div>
                                    <div className="browse_trending2_user_info_left_name">
                                        {name}
                                    </div>
                                </div>
                                {item.saved > 0 ? (
                                    <AiFillHeart
                                        className="browse_trending2_user_info_like"
                                        size={16}
                                        color={"#EB5757"}
                                        onClick={e =>
                                            heartClicked(
                                                e,
                                                item.id,
                                                index,
                                                "unsave"
                                            )
                                        }
                                    />
                                ) : (
                                    <AiFillHeart
                                        className="browse_trending2_user_info_like"
                                        size={16}
                                        color={"#BDB8C6"}
                                        onClick={e =>
                                            heartClicked(
                                                e,
                                                item.id,
                                                index,
                                                "save"
                                            )
                                        }
                                    />
                                )}
                            </div>
                            <div className="browse_trending2_title">
                                {item.title}
                            </div>
                            <div className="browse_trending2_price_container">
                                <div>${kFormatter(item.price_basic)}</div>
                                <div>${kFormatter(item.price_average)}</div>
                                <div>${kFormatter(item.price_luxury)}</div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </LazyLoad>
    )
}

const PlaceHolder = () => {
    return (
        <div id="browse_trending2_placeholder_container">
            <div>Loading...</div>
        </div>
    )
}

export default Popular_list
