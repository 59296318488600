import React, { useEffect } from "react"

const Browse_Horizontal = () => {
    useEffect(() => {
        try {
            ;(window.adsbygoogle = window.adsbygoogle || []).push({})
            let wrapper = document.getElementById("body_wrapper")
            const observer = new MutationObserver((mutations, observer) => {
                wrapper.style.height = ""
                wrapper.style.minHeight = "75vh"
            })
            observer.observe(wrapper, {
                attributes: true,
                attributeFilter: ["style"]
            })
        } catch (err) {
            //console.log(err)
        }
    }, [])

    return (
        <div
            style={{
                width: "100%",
                maxHeight: "130px",
                marginTop: "15px"
            }}
        >
            <ins
                className="adsbygoogle"
                style={{ display: "block", maxHeight: "130px" }}
                data-ad-client="ca-pub-3878773813404073"
                data-ad-slot="3450241332"
                data-ad-format="horizontal"
                data-full-width-responsive="true"
            />
        </div>
    )
}

export default Browse_Horizontal
