import React, { useState, useEffect } from "react"
import { useLocation, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import getScript from "../language"
import "../css/post/Post.css"

import Loading from "../components/Loading"
import Category from "../components/post/Category"
import Title from "../components/post/Title"
import Likes from "../components/post/Likes"
import Tag from "../components/post/Tag"
import Pictures from "../components/post/Pictures"
import Description from "../components/post/Description"
import Price from "../components/post/Price"
import Mobile_About from "../components/post/Mobile_About"
import Review from "../components/post/Review"
import Desktop_About from "../components/post/Desktop_About"
import AdplinAd from "../components/post/AdplinAd"
import Post_Horizontal from "../components/adsense/Post_Horizontal"
import Post_Square from "../components/adsense/Post_Square"

const Post = props => {
    let script = getScript("post")
    const userData = useSelector(state => state.user)
    let query = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(true)
    const [userID, setUserID] = useState()
    const [postUserID, setPostUserID] = useState()
    const [encryptedUserID, setEncryptedUserID] = useState()
    const [category_id, setCategory_id] = useState()
    const [category_name, setCategory_name] = useState()
    const [title, setTitle] = useState()
    const [point, setPoint] = useState(0)
    const [count, setCount] = useState(0)
    const [tag, setTag] = useState([])
    const [pictures, setPictures] = useState([])
    const [description, setDescription] = useState()
    const [basicPrice, setBasicPrice] = useState()
    const [averagePrice, setAveragePrice] = useState()
    const [luxuryPrice, setLuxuryPrice] = useState()
    const [email, setEmail] = useState()
    const [name, setName] = useState()
    const [firstCharacter, setFirstCharacter] = useState()
    const [phone, setPhone] = useState()
    const [website, setWebsite] = useState()
    const [subscribedPost, setSubscribedPost] = useState(false)

    const [notFound, setNotFound] = useState(false)

    useEffect(() => {
        //get post data
        let token = userData.token
        let post_id = query.get("id")
        let originalMeta = document.head.querySelector(
            'meta[name="description"]'
        ).content

        fetch(`/post/get?id=${post_id}&token=${token}`)
            .then(res => res.json())
            .then(res => {
                if (!res.data) {
                    setNotFound(true)
                } else {
                    setUserID(res.user_id)
                    setEncryptedUserID(res.encryptedUserID)
                    setCategory_id(res.data.category_id)
                    setCategory_name(res.data.name)
                    setTitle(res.data.title)
                    if (res.count === 0) {
                        setPoint(0)
                    } else {
                        setPoint(res.point / res.count)
                    }
                    setCount(res.count)
                    let tmp_picture = []
                    tmp_picture.push(
                        `${res.data.main_image.split(".")[0]}.jpeg`
                    )
                    if (
                        res.data.sub_files !== "" &&
                        res.data.sub_files !== undefined
                    ) {
                        res.data.sub_files.split(",").map(item => {
                            tmp_picture.push(`${item.split(".")[0]}.jpeg`)
                        })
                    }
                    setPictures(tmp_picture)
                    setDescription(res.data.description)

                    if (res.data.tag) {
                        res.data.tag.split(",").map(item => {
                            setTag(old => {
                                return [...old, item]
                            })
                        })
                    }
                    setBasicPrice(res.data.price_basic)
                    setAveragePrice(res.data.price_average)
                    setLuxuryPrice(res.data.price_luxury)
                    setEmail(res.data.email)
                    if (res.data.display_name === "full_name") {
                        setName(`${res.data.first_name} ${res.data.last_name}`)
                        setFirstCharacter(res.data.first_name[0].toUpperCase())
                    } else {
                        setName(`${res.data.business_name}`)
                        setFirstCharacter(
                            res.data.business_name[0].toUpperCase()
                        )
                    }
                    setPhone(res.data.phone_number)
                    setWebsite(res.data.website)
                    document.head.querySelector(
                        'meta[name="description"]'
                    ).content = `${res.data.title}`
                    document.title = `AdPlin | Post - ${res.data.title}`

                    if (res.data.subscription_post) {
                        setSubscribedPost(true)
                    }
                }
                setLoading(false)
            })
        return () => {
            document.head.querySelector(
                'meta[name="description"]'
            ).content = originalMeta
        }
    }, [])
    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    {notFound ? (
                        <div>{script.not_found}</div>
                    ) : (
                        <div id="post_grid_container">
                            <div id="post_left_part">
                                <Category
                                    category_id={category_id}
                                    category_name={category_name}
                                />
                                <Title title={title} id={query.get("id")} />
                                <Likes
                                    postID={query.get("id")}
                                    point={point}
                                    people={count}
                                />
                                <Tag tag={tag} />
                                <Pictures
                                    pictures={pictures}
                                    postID={query.get("id")}
                                />
                                <Description description={description} />
                                <div id="post_mobile_container">
                                    <Price
                                        basic={basicPrice}
                                        average={averagePrice}
                                        luxury={luxuryPrice}
                                    />
                                    <Mobile_About
                                        id="post_mobile_about"
                                        socket={props.socket}
                                        post_id={query.get("id")}
                                        post_title={title}
                                        email={email}
                                        name={name}
                                        firstCharacter={firstCharacter}
                                        phone={phone}
                                        website={website}
                                        passedFunction={props.passedFunction}
                                    />
                                    <Link
                                        className="post_payment_button_container"
                                        to={`/payment?id=${encryptedUserID}`}
                                    >
                                        {script.pay}
                                    </Link>
                                </div>
                                {/*<AdplinAd />*/}
                                {subscribedPost ? <></> : <Post_Horizontal />}
                                <Review
                                    post_id={query.get("id")}
                                    user_id={userID}
                                />
                            </div>
                            <div>
                                <div id="post_desktop_container">
                                    <Price
                                        basic={basicPrice}
                                        average={averagePrice}
                                        luxury={luxuryPrice}
                                    />
                                    <Desktop_About
                                        id="post_desktop_about"
                                        socket={props.socket}
                                        post_id={query.get("id")}
                                        post_title={title}
                                        email={email}
                                        name={name}
                                        firstCharacter={firstCharacter}
                                        phone={phone}
                                        website={website}
                                        passedFunction={props.passedFunction}
                                    />
                                    <Link
                                        className="post_payment_button_container"
                                        to={`/payment?id=${encryptedUserID}`}
                                    >
                                        {script.pay}
                                    </Link>
                                    {subscribedPost ? <></> : <Post_Square />}
                                </div>
                            </div>
                            <div id="post_copy_message">{script.copied}</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Post
