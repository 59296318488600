import React, { useState, useEffect } from "react"
import {
    MdStar,
    MdStarBorder,
    MdStarHalf,
    MdPeople,
    MdReport
} from "react-icons/md"
import Report from "./Report"
import getScript from "../../language"
import "../../css/post/Likes.css"

const Likes = props => {
    let script = getScript("post")
    const [point, setPoint] = useState(0)
    const [stars, setStars] = useState([])
    const [openReport, setOpenReport] = useState(false)

    function reportHandler(action) {
        if (action === "open") {
            setOpenReport(true)
        } else {
            setOpenReport(false)
        }
    }

    useEffect(() => {
        let full = Math.floor(point)
        let half = point % 1
        let empty = 0
        if (half !== 0) {
            empty = 4 - full
        } else {
            empty = 5 - full
        }
        let tmp_star = []
        for (let i = 0; i < full; i++) {
            tmp_star.push("full")
        }
        if (half !== 0) {
            tmp_star.push("half")
        }
        for (let i = 0; i < empty; i++) {
            tmp_star.push("empty")
        }
        setStars(tmp_star)
    }, [point])

    useEffect(() => {
        setPoint((Math.round(props.point * 2) / 2).toFixed(1))
    }, [props])

    return (
        <div id="post_likes_space_between">
            <div id="post_likes_container">
                <div id="post_likes_stars">
                    {stars.map(item => {
                        if (item === "full") {
                            return <MdStar color="" />
                        } else if (item === "half") {
                            return <MdStarHalf />
                        } else if (item === "empty") {
                            return <MdStarBorder />
                        }
                    })}
                </div>
                <div id="post_likes_people">
                    <MdPeople />
                    <div id="post_likes_people_number">{props.people}</div>
                </div>
            </div>
            <div id="post_likes_report" onClick={() => reportHandler("open")}>
                <MdReport color="#670940" size={20} />
                <div>{script.report}</div>
            </div>
            {openReport ? (
                <Report postID={props.postID} reportHandler={reportHandler} />
            ) : (
                <></>
            )}
        </div>
    )
}

export default Likes
