import React from "react"
import AdPlin_Language from "./language/AdPlin_Language"
import Available_Language from "./language/Available_Language"
import "../../css/Language_Setting.css"

const Language_Setting = props => {
    return (
        <div id="language_setting_container">
            <AdPlin_Language changeLanguage={props.changeLanguage} />
            <Available_Language />
        </div>
    )
}

export default Language_Setting
