import React, { useState, useEffect } from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom"
import { Provider, useSelector, useDispatch } from "react-redux"
import "./App.css"
import store from "./store/store"
import user from "./store/actions/user"
import GoogleAnalytics from "./components/GoogleAnalytics"
import AdBlockerDetector from "./components/AdBlockerDetector"
import Header from "./layout/Header"
import Footer from "./layout/Footer"

import Home from "./route/Home"
import Login from "./route/Login"
import Google_Login from "./route/Google_Login"
import LoginForm from "./components/LoginForm"
import Search_Result from "./route/Search_Result"
import PrivacyPolicy from "./route/PrivacyPolicy"
import TermsOfService from "./route/TermsOfService"
import Account from "./route/Account"
import Setting from "./route/Setting"
import VerifyAccount from "./route/VerifyAccount"
import Message from "./route/Message"
import Create from "./route/Create"
import Create_Edit from "./route/Create_Edit"
import Edit_Post from "./route/Edit_Post"
import Edit_Success from "./route/Edit_Success"
import Create_Success from "./route/Create_Success"
import Checkout from "./route/Checkout"
import My_Posts from "./route/My_Posts"
import Browse from "./route/Browse"
import Post from "./route/Post"
import Categories from "./route/Categories"
import Category_list from "./route/Category_List"
import Subscription_Succeed from "./route/Subscription_Succeed"
import Subscription_Setting from "./route/Subscription_Setting"
import Create_Failed from "./route/Create_Failed"
import Advertise from "./route/Advertise"
import Messenger from "./route/Messenger"
import Contact_Us from "./route/Contact_Us"
import Recently_Viewed from "./route/Recently_Viewed"
import Earning from "./route/Earning"
import Payment from "./route/Payment"
import Payment_Succeed from "./route/Payment_Succeed"
import ConnectRefresh from "./route/ConnectRefresh"

import socketClient from "socket.io-client"

function App(props) {
    const [login_action, setLogin_action] = useState("")
    const [to, setTo] = useState()
    const [language, setLanguage] = useState()
    const [socket, setSocket] = useState()

    function changeLanguage(language) {
        setLanguage(language)
    }

    function changeLoginFormAction(action, to) {
        setLogin_action(action)
        setTo(to)
    }

    function changeSocket(value) {
        setSocket(value)
    }

    // useEffect(()=>{
    // let socket = socketClient("/")
    //     setSocket(socket)
    // },[])
    return (
        <Provider store={store}>
            <LocalPersist changeLanguage={changeLanguage} />
            <Socket changeSocket={changeSocket} />
            <div className="App">
                <Router>
                    <withRouter>
                        <LoginForm
                            action={login_action}
                            to={to}
                            passedFunction={changeLoginFormAction}
                            changeLanguage={changeLanguage}
                        />
                    </withRouter>
                    <Header passedFunction={changeLoginFormAction} />
                    <Switch>
                        <Route path={"/company"} component={Home} />
                        <div
                            id="body_wrapper"
                            style={{
                                marginTop: "75px",
                                position: "relative"
                            }}
                        >
                            <Route
                                path={"/login"}
                                render={() => (
                                    <Login
                                        passedFunction={changeLoginFormAction}
                                    />
                                )}
                            />
                            <Route
                                path={"/google_login"}
                                render={() => (
                                    <Google_Login
                                        passedFunction={changeLoginFormAction}
                                        changeLanguage={changeLanguage}
                                    />
                                )}
                            />
                            <Route
                                path={"/verify_account"}
                                component={VerifyAccount}
                            />
                            <Route
                                path={"/search_result"}
                                render={() => (
                                    <Search_Result
                                        passedFunction={changeLoginFormAction}
                                    />
                                )}
                            />
                            <Route
                                path={"/privacy_policy"}
                                component={PrivacyPolicy}
                            />
                            <Route
                                path={"/terms_of_service"}
                                component={TermsOfService}
                            />
                            <Route
                                path={"/account"}
                                render={() => (
                                    <Account
                                        passedFunction={changeLoginFormAction}
                                    />
                                )}
                            />
                            <Route
                                path={"/setting"}
                                render={() => (
                                    <Setting
                                        passedFunction={changeLoginFormAction}
                                        changeLanguage={changeLanguage}
                                    />
                                )}
                            />
                            <Route
                                path={"/message"}
                                //component={Message}
                                render={props => (
                                    <Message
                                        passedFunction={changeLoginFormAction}
                                    />
                                )}
                                passedFunction={changeLoginFormAction}
                            />
                            <Route exact path={"/create"} component={Create} />
                            <Route
                                path={"/create/create_edit"}
                                component={Create_Edit}
                            />
                            <Route path={"/edit_post"} component={Edit_Post} />
                            <Route
                                path={"/edit_success"}
                                component={Edit_Success}
                            />
                            <Route
                                path={"/create_success"}
                                component={Create_Success}
                            />
                            <Route
                                path={"/create_failed"}
                                component={Create_Failed}
                            />
                            <Route path={"/my_posts"} component={My_Posts} />
                            <Route
                                exact
                                path={"/"}
                                render={() => (
                                    <Browse
                                        passedFunction={changeLoginFormAction}
                                    />
                                )}
                            />
                            <Route
                                path={"/browse"}
                                render={() => (
                                    <Browse
                                        passedFunction={changeLoginFormAction}
                                    />
                                )}
                            />
                            <Route
                                path={"/post"}
                                render={() => (
                                    <Post
                                        passedFunction={changeLoginFormAction}
                                        socket={socket}
                                    />
                                )}
                            />
                            <Route path={"/checkout"} component={Checkout} />
                            <Route
                                path={"/categories"}
                                component={Categories}
                            />
                            <Route
                                path={"/category_list"}
                                render={() => (
                                    <Category_list
                                        passedFunction={changeLoginFormAction}
                                    />
                                )}
                            />
                            <Route
                                path={"/subscription_setting"}
                                component={Subscription_Setting}
                            />
                            <Route
                                path={"/subscription_succeed"}
                                component={Subscription_Succeed}
                            />
                            <Route path={"/advertise"} component={Advertise} />
                            <Route
                                path={"/messenger"}
                                render={() => <Messenger socket={socket} />}
                            />
                            <Route path={"/contact"} component={Contact_Us} />
                            <Route
                                path={"/recently_viewed"}
                                render={() => (
                                    <Recently_Viewed socket={socket} />
                                )}
                            />
                            <Route
                                path={"/earning"}
                                render={() => (
                                    <Earning
                                        passedFunction={changeLoginFormAction}
                                        socket={socket}
                                        passedFunction={changeLoginFormAction}
                                    />
                                )}
                            />
                            <Route
                                path={"/payment"}
                                render={() => (
                                    <Payment
                                        passedFunction={changeLoginFormAction}
                                        socket={socket}
                                    />
                                )}
                            />
                            <Route
                                path={"/payment_succeed"}
                                component={Payment_Succeed}
                            />
                            <Route
                                path={"/connect_refresh"}
                                component={ConnectRefresh}
                            />
                        </div>
                    </Switch>
                    <div id="footerDistance"></div>
                    <Footer changeLanguage={changeLanguage} />
                    <GoogleAnalytics />
                    <AdBlockerDetector />
                </Router>
            </div>
        </Provider>
    )
}

const LocalPersist = props => {
    const dispatch = useDispatch()
    const data1 = useSelector(state => state.user)
    const user_token = data1.token
    const user_email = data1.email
    const user_language = data1.language

    useEffect(() => {
        if (
            user_token !== undefined &&
            user_token !== "" &&
            user_token !== null &&
            user_token !== "null" &&
            user_token !== "undefined" &&
            user_email === undefined
        ) {
            fetch("/users/getUserInfo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user_token}`
                },
                body: JSON.stringify({})
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    if (data.action === "succeed") {
                        dispatch(
                            user.logIn(
                                data.userData.email,
                                data.userData.login_from,
                                user_token
                            )
                        )
                        dispatch(user.updateLanguage(data.language))
                        props.changeLanguage(data.language)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            //get browser language setting
            fetch(`/language/get`)
                .then(res => res.json())
                .then(res => {
                    if (res.language && res.language[0]) {
                        let language_option = res.language[0].split("-")[0]
                        dispatch(user.updateLanguage(language_option))
                        props.changeLanguage(language_option)
                    }
                })
        }
    }, [])

    return <div></div>
}

const Socket = props => {
    const { REACT_APP_LIVE } = process.env
    const userData = useSelector(state => state.user)

    useEffect(() => {
        let socket
        if (
            userData.token !== undefined &&
            userData.token !== "undefined" &&
            userData.token !== null &&
            userData.token !== "null"
        ) {
            if (REACT_APP_LIVE === "true") {
                socket = socketClient("https://adplin.com/")
            } else {
                socket = socketClient("/")
            }
            socket.on("connection", () => {
                socket.emit("join", { token: userData.token })
                props.changeSocket(socket)
            })
        }
        if (socket && userData.token === "undefined") {
            socket.disconnect()
            props.changeSocket(undefined)
        }
        return () => {
            if (socket) {
                socket.disconnect()
            }
        }
    }, [userData.token])
    return <></>
}

export default App
