import React, { useState, useEffect } from "react"
import List_Card from "../card/List_Card"
import "../../css/category_list/Top_Posts.css"
import getScript from "../../language"

const Top_Posts = props => {
    const script = getScript("category_list")
    const [list, setList] = useState([])

    function changeList(index, id) {
        let newList = [...list]
        newList[index].saved = id
        setList(newList)
    }

    useEffect(() => {
        if (props.list) {
            setList(props.list)
        }
    }, [props.list])
    return (
        <div>
            <div id="category_list_top_post_title">{script.top_posts}</div>
            <div id="category_list_top_post_container_id">
                {list.map((item, index) => {
                    return (
                        <List_Card
                            item={item}
                            index={index}
                            passedFunction={props.passedFunction}
                            changeList={changeList}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default Top_Posts
