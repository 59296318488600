import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

const Available_Language = () => {
    const userData = useSelector(state => state.user)
    const [chosen, setChosen] = useState([])
    const [list, setList] = useState({
        Afrikaans: false,
        Albanian: false,
        Amharic: false,
        Arabic: false,
        Armenian: false,
        Azerbaijani: false,
        Basque: false,
        Belarusian: false,
        Bengali: false,
        Bosnian: false,
        Bulgarian: false,
        Catalan: false,
        Cebuano: false,
        Chichewa: false,
        Chinese: false,
        Corsican: false,
        Croatian: false,
        Czech: false,
        Danish: false,
        Dutch: false,
        English: false,
        Esperanto: false,
        Estonian: false,
        Filipino: false,
        Finnish: false,
        French: false,
        Frisian: false,
        Galician: false,
        Georgian: false,
        German: false,
        Greek: false,
        Gujarati: false,
        Hausa: false,
        Hawaiian: false,
        Hebrew: false,
        Hindi: false,
        Hmong: false,
        Hungarian: false,
        Icelandic: false,
        Igbo: false,
        Indonesian: false,
        Irish: false,
        Italian: false,
        Japanese: false,
        Javanese: false,
        Kannada: false,
        Kazakh: false,
        Khmer: false,
        Kinyarwanda: false,
        Korean: false,
        Kurdish: false,
        Kyrgyz: false,
        Lao: false,
        Latin: false,
        Latvian: false,
        Lithuanian: false,
        Luxembourgish: false,
        Macedonian: false,
        Malagasy: false,
        Malay: false,
        Malayalam: false,
        Maltese: false,
        Maori: false,
        Marathi: false,
        Mongolian: false,
        Myanmar: false,
        Nepali: false,
        Norwegian: false,
        Odia: false,
        Pashto: false,
        Persian: false,
        Polish: false,
        Portuguese: false,
        Punjabi: false,
        Romanian: false,
        Russian: false,
        Samoan: false,
        Serbian: false,
        Sesotho: false,
        Shona: false,
        Sindhi: false,
        Sinhala: false,
        Slovak: false,
        Slovenian: false,
        Somali: false,
        Spanish: false,
        Sundanese: false,
        Swahili: false,
        Swedish: false,
        Tajik: false,
        Tamil: false,
        Tatar: false,
        Telugu: false,
        Thai: false,
        Turkish: false,
        Turkmen: false,
        Ukrainian: false,
        Urdu: false,
        Uyghur: false,
        Uzbek: false,
        Vietnamese: false,
        Welsh: false,
        Xhosa: false,
        Yiddish: false,
        Yoruba: false,
        Zulu: false
    })

    function changeList(key) {
        let newList = { ...list }
        let newArray
        if (list[key]) {
            newList[key] = false
            setList(newList)
            newArray = chosen.filter(item => item !== key)
            setChosen(newArray)

            updateDB(newArray)
        } else {
            newList[key] = true
            setList(newList)
            newArray = [...chosen, key]
            setChosen(newArray)
            updateDB(newArray)
        }
    }

    function updateDB(newArray) {
        fetch("/users/update_available_languages", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                language: newArray
            })
        })
    }

    useEffect(() => {
        console.log(chosen)
        //get available_language and update both chosen and list
        fetch("/users/get_available_languages", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({})
        })
            .then(res => res.json())
            .then(res => {
                let newArray = []
                newArray = res.list.split(",")
                setChosen(newArray)
                let newList = { ...list }
                for (let i = 0; i < newArray.length; i++) {
                    newList[newArray[i]] = true
                }
                setList(newList)
            })
    }, [])
    return (
        <div>
            <div className="language_setting_row_container">
                <div className="language_setting_title">
                    Available languages
                </div>
                <div className="language_setting_description">
                    Select languages that you can speak
                </div>
                <div></div>
            </div>
            <ol id="language_setting_available_languages">
                {Object.keys(list).map((key, item) => {
                    if (list[key]) {
                        return (
                            <li
                                className="language_setting_available_languages_true"
                                onClick={() => changeList(key)}
                            >
                                {key}
                            </li>
                        )
                    } else {
                        return (
                            <li
                                className="language_setting_available_languages_false"
                                onClick={() => changeList(key)}
                            >
                                {key}
                            </li>
                        )
                    }
                })}
            </ol>
        </div>
    )
}

export default Available_Language
