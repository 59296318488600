import React, { useState } from "react"
import "../../css/post/Report.css"
import getScript from "../../language"

const Report = props => {
    let script = getScript("post")
    const [submitted, setSubmitted] = useState(false)
    const [reason, setReason] = useState("")
    function submitHandler() {
        fetch("/post/report", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                post_id: props.postID,
                reason: reason
            })
        }).then(res => {
            return res.json()
        })
        setSubmitted(true)
    }
    return (
        <div id="post_report_container">
            {submitted === false ? (
                <div id="post_report_box_container">
                    <div id="post_report_title">
                        {script.inappropriate_post}
                    </div>
                    <div>{script.tell_us_post}</div>
                    <textarea
                        type="text"
                        id="post_report_textarea"
                        maxLength="1000"
                        value={reason}
                        onChange={e => setReason(e.currentTarget.value)}
                    ></textarea>
                    <div id="post_report_box_buttons">
                        <div onClick={() => props.reportHandler("close")}>
                            {script.close}
                        </div>
                        <div onClick={submitHandler}>{script.submit}</div>
                    </div>
                </div>
            ) : (
                <div id="post_report_box_container">
                    <div id="post_report_title">{script.thank}</div>
                    <div
                        id="post_report_close_button"
                        onClick={() => props.reportHandler("close")}
                    >
                        {script.close}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Report
