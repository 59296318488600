import React, { useState, useEffect } from "react"
import { MdInfoOutline } from "react-icons/md"
import "../../css/Pricing.css"

const Pricing = ({ updateParent, price }) => {
    const [basic, setBasic] = useState()
    const [average, setAverage] = useState()
    const [luxury, setLuxury] = useState()

    useEffect(() => {
        updateParent("price", [basic, average, luxury])
    }, [basic, average, luxury])

    useEffect(() => {
        if (price !== undefined) {
            setBasic(price[0])
            setAverage(price[1])
            setLuxury(price[2])
        }
    }, [price])

    return (
        <div>
            <div className="create_edit_title">Pricing</div>
            <div>
                <div className="create_edit_pricing_box_container">
                    <div>Basic</div>
                    <input
                        id="create_edit_pricing_box_basic"
                        type="number"
                        name="basic"
                        placeholder="0"
                        value={basic}
                        onChange={e => setBasic(e.currentTarget.value)}
                    />
                    <div className="create_edit_pricing_info_container">
                        <MdInfoOutline style={{ margin: "0 5px" }} />
                        <div>Low-end price of your services.</div>
                    </div>
                </div>
                <div className="create_edit_pricing_box_container">
                    <div>Average</div>
                    <input
                        id="create_edit_pricing_box_average"
                        type="number"
                        name="average"
                        placeholder="0"
                        value={average}
                        onChange={e => setAverage(e.currentTarget.value)}
                    />
                    <div className="create_edit_pricing_info_container">
                        <MdInfoOutline style={{ margin: "0 5px" }} />
                        <div>Average price of your services.</div>
                    </div>
                </div>
                <div className="create_edit_pricing_box_container">
                    <div>Premium</div>
                    <input
                        id="create_edit_pricing_box_luxury"
                        type="number"
                        name="luxury"
                        placeholder="0"
                        value={luxury}
                        onChange={e => setLuxury(e.currentTarget.value)}
                    />
                    <div className="create_edit_pricing_info_container">
                        <MdInfoOutline style={{ margin: "0 5px" }} />
                        <div>High-end price of your services.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing
