import React from 'react';
import {Link} from "react-router-dom"
import getScript from "../../language"
import "../../css/post/Category.css"

const Category = (props)=>{
    const script = getScript("db_categories")
    return (
        <Link id="post_category_link" to={`/category_list?id=${props.category_id}`}>
            {script[props.category_id]}
        </Link>
    )
}

export default Category;
