import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Loading from "../components/Loading"

const ConnectRefresh = () => {
    const userData = useSelector(state => state.user)
    const history = useHistory()

    useEffect(() => {
        if (
            !userData.token ||
            userData.token === "undefined" ||
            userData.token === "null"
        ) {
            history.push("browse")
        } else {
            fetch("/stripe/connect/onboard-user", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.url) {
                        window.location = res.url
                    }
                })
        }
    }, [])
    return (
        <div>
            <Loading />
        </div>
    )
}

export default ConnectRefresh
