import React, { useState, useEffect } from "react"
import { useHistory, Link } from "react-router-dom"
import { BiSearch } from "react-icons/bi"
import "../../css/browse/Browse_search.css"
import getScript from "../../language"

const Browse_search = () => {
    let script = getScript("main_categories")
    let db_script = getScript("db_categories")
    const history = useHistory()
    const [input, setInput] = useState()
    const [placeholder, setPlaceholder] = useState(db_script[1])
    const [img1, setImg1] = useState("/images/browse/design.svg")
    const [style1, setStyle1] = useState({ paddingTop: "10px" })
    const [img2, setImg2] = useState("/images/browse/development.svg")
    const [style2, setStyle2] = useState({ paddingTop: "10px" })
    const [img3, setImg3] = useState("/images/browse/marketing.svg")
    const [style3, setStyle3] = useState({ paddingTop: "10px" })
    const [img4, setImg4] = useState("/images/browse/video.svg")
    const [style4, setStyle4] = useState({ paddingTop: "10px" })
    const [img5, setImg5] = useState("/images/browse/writing.svg")
    const [style5, setStyle5] = useState({ paddingTop: "10px" })
    const [img6, setImg6] = useState("/images/browse/other.svg")
    const [style6, setStyle6] = useState({ paddingTop: "10px" })

    function keyPressHandler(e) {
        if (e.key === "Enter") {
            search()
        }
    }
    function search() {
        if (input !== "" && input !== undefined) {
            history.push(`search_result?search=${encodeURIComponent(input)}`)
        }
    }

    function changeColor(e) {
        switch (e) {
            case 1:
                setImg1("/images/browse/design_color.svg")
                setStyle1({ paddingTop: 0, paddingBottom: "10px" })
                break
            case 2:
                setImg2("/images/browse/development_color.svg")
                setStyle2({ paddingTop: 0, paddingBottom: "10px" })
                break
            case 3:
                setImg3("/images/browse/marketing_color.svg")
                setStyle3({ paddingTop: 0, paddingBottom: "10px" })
                break
            case 4:
                setImg4("/images/browse/video_color.svg")
                setStyle4({ paddingTop: 0, paddingBottom: "10px" })
                break
            case 5:
                setImg5("/images/browse/writing_color.svg")
                setStyle5({ paddingTop: 0, paddingBottom: "10px" })
                break
            case 6:
                setImg6("/images/browse/other_color.svg")
                setStyle6({ paddingTop: 0, paddingBottom: "10px" })
                break
            case -1:
                setImg1("/images/browse/design.svg")
                setStyle1({ paddingTop: "10px" })
                break
            case -2:
                setImg2("/images/browse/development.svg")
                setStyle2({ paddingTop: "10px" })
                break
            case -3:
                setImg3("/images/browse/marketing.svg")
                setStyle3({ paddingTop: "10px" })
                break
            case -4:
                setImg4("/images/browse/video.svg")
                setStyle4({ paddingTop: "10px" })
                break
            case -5:
                setImg5("/images/browse/writing.svg")
                setStyle5({ paddingTop: "10px" })
                break
            case -6:
                setImg6("/images/browse/other.svg")
                setStyle6({ paddingTop: "10px" })
                break
        }
    }

    useEffect(() => {
        let count = 0
        let changePlaceholder = setInterval(() => {
            if (count === 0) {
                setPlaceholder(db_script[8])
                count++
            } else if (count === 1) {
                setPlaceholder(db_script[67])
                count++
            } else if (count === 2) {
                setPlaceholder(db_script[1])
                count = 0
            }
        }, 1500)
        return () => {
            clearInterval(changePlaceholder)
        }
    }, [])
    return (
        <div id="browse_search_container">
            <div id="browse_search_relative_width">
                <div id="browse_search_introduction">
                    One platform to find all services you need, AdPlin
                </div>
                <div id="browse_search_input_container">
                    <input
                        type="search"
                        autoComplete="off"
                        id="browse_search_input"
                        placeholder={placeholder}
                        value={input}
                        onKeyPress={keyPressHandler}
                        onChange={e => setInput(e.currentTarget.value)}
                    />
                    <BiSearch id="browse_search_search_icon" onClick={search} />
                </div>
                <div id="browse_search_categories_box">
                    <ul id="browse_search_categories_container">
                        <Link
                            to="/category_list?group=Design"
                            onMouseOver={() => changeColor(1)}
                            onMouseLeave={() => changeColor(-1)}
                        >
                            <img
                                src={img1}
                                className="browse_search_categories_icon"
                                alt="Design"
                                style={style1}
                            />
                            <div>{script.design}</div>
                        </Link>
                        <Link
                            to="/category_list?group=Development"
                            onMouseOver={() => changeColor(2)}
                            onMouseLeave={() => changeColor(-2)}
                        >
                            <img
                                src={img2}
                                className="browse_search_categories_icon"
                                alt="Development"
                                style={style2}
                            />
                            <div>{script.development}</div>
                        </Link>
                        <Link
                            to="/category_list?group=Marketing"
                            onMouseOver={() => changeColor(3)}
                            onMouseLeave={() => changeColor(-3)}
                        >
                            <img
                                src={img3}
                                className="browse_search_categories_icon"
                                alt="Marketing"
                                style={style3}
                            />
                            <div>{script.marketing}</div>
                        </Link>
                        <Link
                            to="/category_list?group=Video%20%26%20Audio"
                            onMouseOver={() => changeColor(4)}
                            onMouseLeave={() => changeColor(-4)}
                        >
                            <img
                                src={img4}
                                className="browse_search_categories_icon"
                                alt="Video & Audio"
                                style={style4}
                            />
                            <div>{script.video}</div>
                        </Link>
                        <Link
                            to="/category_list?group=Writing%20%26%20Translation"
                            onMouseOver={() => changeColor(5)}
                            onMouseLeave={() => changeColor(-5)}
                        >
                            <img
                                src={img5}
                                className="browse_search_categories_icon"
                                alt="Writing & Translation"
                                style={style5}
                            />
                            <div>{script.writing}</div>
                        </Link>
                        <Link
                            to="/category_list?group=Other"
                            onMouseOver={() => changeColor(6)}
                            onMouseLeave={() => changeColor(-6)}
                        >
                            <img
                                src={img6}
                                className="browse_search_categories_icon"
                                alt="Other"
                                style={style6}
                            />
                            <div>{script.other}</div>
                        </Link>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Browse_search
