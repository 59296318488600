import React, { useState, useEffect } from "react"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import "../../css/post/Pictures.css"

const Pictures = props => {
    const [currentPosition, setCurrentPosition] = useState(0)

    function showController() {
        document.getElementsByClassName(
            "post_pictures_smaller_image_controller"
        )[0].style.display = "flex"
        document.getElementsByClassName(
            "post_pictures_smaller_image_controller"
        )[1].style.display = "flex"
    }
    function hideController() {
        document.getElementsByClassName(
            "post_pictures_smaller_image_controller"
        )[0].style.display = "none"
        document.getElementsByClassName(
            "post_pictures_smaller_image_controller"
        )[1].style.display = "none"
    }

    function clickController(to) {
        let box = document.getElementById(
            "post_pictures_smaller_images_container"
        )
        if (to === "left") {
            box.scrollLeft -= box.clientWidth
        } else if (to === "right") {
            box.scrollLeft += +box.clientWidth
        }
    }

    function adjustSmallerImageSize() {
        let containerWidth = document.getElementById(
            "post_pictures_smaller_images_container"
        ).clientWidth
        let imageClasses = document.getElementsByClassName(
            "post_pictures_smaller_images"
        )
        document.getElementById(
            "post_pictures_smaller_images_container"
        ).scrollLeft = 0
        for (let i = 0; i < imageClasses.length; i++) {
            imageClasses[i].style.width = containerWidth / 5 - 11 + "px"
        }
    }
    useEffect(() => {
        adjustSmallerImageSize()
    }, [props])

    useEffect(() => {
        adjustSmallerImageSize()

        window.addEventListener("resize", adjustSmallerImageSize)
        return () => {
            window.removeEventListener("resize", adjustSmallerImageSize)
        }
    }, [])
    return (
        <div>
            {props.postID !== undefined &&
            props.pictures[currentPosition] !== undefined ? (
                <img
                    id="post_pictures_bigger_image"
                    src={`https://adplin.s3.amazonaws.com/${props.postID}/resize/${props.pictures[currentPosition]}`}
                />
            ) : (
                <></>
            )}
            <div
                id="post_pictures_bottom_container"
                onMouseOver={showController}
                onMouseOut={hideController}
                //onMouseLeave={hideController}
            >
                <div
                    className="post_pictures_smaller_image_controller"
                    onClick={() => clickController("left")}
                >
                    <MdChevronLeft size={35} />
                </div>
                <div id="post_pictures_smaller_images_container">
                    {props.pictures.map((item, index) => {
                        if (props.postID !== undefined) {
                            let borderBoxIDName = ""
                            if (currentPosition === index) {
                                borderBoxIDName =
                                    "post_pictures_smaller_image_border"
                            }
                            return (
                                <img
                                    id={borderBoxIDName}
                                    className="post_pictures_smaller_images"
                                    onClick={e => {
                                        e.stopPropagation()
                                        setCurrentPosition(index)
                                    }}
                                    src={`https://adplin.s3.amazonaws.com/${props.postID}/resize/${props.pictures[index]}`}
                                />
                            )
                        }
                    })}
                </div>
                <div
                    id="post_pictures_smaller_image_controller_right"
                    className="post_pictures_smaller_image_controller"
                    onClick={() => clickController("right")}
                >
                    <MdChevronRight size={35} />
                </div>
            </div>
        </div>
    )
}

export default Pictures
