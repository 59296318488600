import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { convertFromRaw } from "draft-js"
import Title from "../components/edit/Title"
import UploadFiles from "../components/edit/UploadFiles"
import Description from "../components/edit/Description"
import Tag from "../components/edit/Tag"
import Pricing from "../components/edit/Pricing"

const Edit_Post = () => {
    let query = new URLSearchParams(useLocation().search)
    let userData = useSelector(state => state.user)
    let history = useHistory()
    const [post_id, setPost_id] = useState()
    const [category, setCategory] = useState()
    const [title, setTitle] = useState("")
    const [mainChanged, setMainChanged] = useState(false)
    const [main, setMain] = useState()
    const [originalMain, setOriginalMain] = useState()
    const [mainFile, setMainFile] = useState()
    const [subFile, setSubFile] = useState()
    const [newlyUploadedFile, setNewlyUploadedFile] = useState()
    const [files, setFiles] = useState()
    const [originalFiles, setOriginalFiles] = useState()
    const [description, setDescription] = useState()
    const [description_data, setDescription_data] = useState()
    const [tag, setTag] = useState()
    const [price, setPrice] = useState()
    const [errMessage, setErrMessage] = useState("")

    function handleChange(what, value) {
	console.log(`update Parent ${what} = ${value}`)
        switch (what) {
            case "title":
                setTitle(value)
                break
            case "main_changed":
                setMainChanged(true)
                break
            case "main":
                setMain(value)
                break
            case "files":
                setFiles(value)
                break
            case "description":
                setDescription(value)
                break
            case "tag":
                setTag(value)
                break
            case "price":
                setPrice(value)
                break
            case "originalFiles":
                setOriginalFiles(value)
                break
	    case "originalMain":
                setOriginalMain(value)
                break
            case "newlyUploadedFile":
                setNewlyUploadedFile(value)
                break
        }
    }

    function cancelHandler() {
        history.goBack()
    }

    function updateePost() {
        if (validateInput("regular")) {
            setErrMessage("")
            sendToServer()
        }
    }

    function sendToServer() {
        fetch("/edit/", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                post_id,
                category,
                title,
                mainChanged,
                main,
                originalMain,
                files,
                originalFiles,
                newlyUploadedFile,
                description,
                tag,
                price
            })
        }).then(res => {
            return res.json()
        })
         .then(res => {
		console.log(res)
             if (res.status === "succeed") {
                 history.push("/edit_success")
             } else {
                 console.log("failed")
             }
         })
    }

    function validateInput(type) {
        switch (type) {
            case "regular":
                if (title === "") {
                    setErrMessage("Failed to create: Set the title.")
                    return false
                }
                if (mainChanged) {
                    if (main === "" || main === undefined) {
                        setErrMessage(
                            "Failed to create: Upload main image file."
                        )
                        return false
                    }
                }
                if (
                    price[0] === undefined ||
                    price[1] === undefined ||
                    price[2] === undefined ||
                    price[0] === "" ||
                    price[1] === "" ||
                    price[2] === ""
                ) {
                    setErrMessage("Failed to create: Set the price.")
                    return false
                }
                if (!convertFromRaw(JSON.parse(description)).hasText()) {
                    setErrMessage(
                        "Failed to create: Description need to be written."
                    )
                    return false
                }
                if (
                    convertFromRaw(JSON.parse(description)).getPlainText()
                        .length < 100
                ) {
                    setErrMessage(
                        `Failed to create: Description is too short. Minimum 100 characters are required. (${
                            convertFromRaw(
                                JSON.parse(description)
                            ).getPlainText().length
                        } / 100)`
                    )
                    return false
                }
                break
        }
        return true
    }

    useEffect(() => {
        fetch("/post/get_my_post_info", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                post_id: query.get("id")
            })
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.status === "unauthorized") {
                    history.goBack()
                } else {
                    if (res.results) {
                        setPost_id(res.results.id)
                        setTitle(res.results.title)
                        if (res.results.tag !== undefined) {
                            let tagArray = res.results.tag.split(",")
                            setTag(tagArray)
                        }
                        setSubFile(res.results.sub_files)
                        setCategory(res.results.category_name)
                        setPrice([
                            res.results.price_basic,
                            res.results.price_average,
                            res.results.price_luxury
                        ])
                        setMainFile(res.results.main_image)
                        setDescription_data(res.results.description)
                    }
                }
            })
    }, [])

    return (
        <div>
            <div>Edit_Post Page</div>
            {post_id ? (
                <div>
                    <Title updateParent={handleChange} title={title} />
                    <UploadFiles
                        updateParent={handleChange}
                        post_id={post_id}
			mainFile={mainFile}
                        subFile={subFile}
                    />
                    <Description
                        updateParent={handleChange}
                        props_description={description_data}
                    />
                    <Tag updateParent={handleChange} props_tag={tag} />
                    <Pricing updateParent={handleChange} price={price} />
                    <div id="create_edit_buttons_container">
                        <button onClick={cancelHandler}>Cancel</button>
                        <button onClick={updateePost}>Update</button>
                    </div>
                    <div id="create_edit_err_message">{errMessage}</div>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default Edit_Post
