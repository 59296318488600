import React, { useState } from "react"
import getScript from "../language"
import "../css/Contact_Us.css"

const Contact_Us = () => {
    let script = getScript("contact")
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [what, setWhat] = useState()
    const [message, setMessage] = useState()
    const [err, setErr] = useState("")
    const [sent, setSent] = useState(false)

    function submitForm() {
        //validate form
        if (name === "" || !name) {
            setErr(script.err_name)
        } else if (email === "" || !email) {
            setErr(script.err_email)
        } else if (!validateEmail(email)) {
            setErr(script.err_incorrect_email)
        } else if (what === "select" || !what) {
            setErr(script.err_what)
        } else if (message === "" || !message) {
            setErr(script.err_message)
        } else {
            setErr("")
            fetch("/contact/send_email", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name,
                    email,
                    what,
                    message
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === "succeed") {
                        //succeed
			setSent(true)
                    } else {
                        setErr(script.failed)
                    }
                })
            //send email
        }
    }
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    return (
	<>{sent?
	<div>
	    Thank you for contacting us!

	</div>
	:
        <div id="contact_us_container">
            <div id="contact_us_left_container">
                <div id="contact_us_left_title">{script.title}</div>
                <div className="contact_us_left_label">{script.name}</div>
                <input
                    type="text"
                    value={name}
                    onChange={e => setName(e.currentTarget.value)}
                />
                <div className="contact_us_left_label">{script.email}</div>
                <input
                    type="text"
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                />
                <div className="contact_us_left_label">{script.what}</div>
                <select
                    value={what}
                    onChange={e => setWhat(e.currentTarget.value)}
                >
                    <option value="select">{script.select}</option>
                    <option value="account">{script.account}</option>
                    <option value="bug">{script.bug}</option>
                    <option value="other">{script.other}</option>
                </select>
                <div className="contact_us_left_label">{script.message}</div>
                <textarea
                    rows="7"
                    value={message}
                    onChange={e => setMessage(e.currentTarget.value)}
                />
                <div id="contact_us_left_err">{err}</div>
                <button
                    id="contact_us_left_submit"
                    type="submit"
                    onClick={submitForm}
                >
                    {script.submit}
                </button>
            </div>
            <div id="contact_us_right_container">
                <div id="contact_us_right_love_to">{script.love_to}</div>
                <div id="contact_us_right_contact">{script.contact}</div>
                <div id="contact_us_right_address">{script.address}</div>
                <img
                    id="contact_us_right_icon"
                    src="/images/logo_icon.svg"
                    alt="logo"
                    width="250px"
                />
            </div>
        </div>
	}</>
    )
}

export default Contact_Us
