import React, { useState, useEffect } from "react"
import getScript from "../language"
import { MdKeyboardBackspace } from "react-icons/md"
import { Link } from "react-router-dom"

import "../css/Categories.css"

const Categories = () => {
    let script = getScript("db_categories")
    let mainScript = getScript("main_categories")
    const [categories, setCategories] = useState([])
    const [listToShow, setListToShow] = useState([])
    const [mobileShowGroups, setMobileShowGroups] = useState(true)

    function groupClicked(group, e, colorPath) {
        setMobileShowGroups(false)
        let doc = document.getElementById("categories_group_container").children
        for (let i = 0; i < doc.length; i++) {
            doc[i].style.borderBottom = "0px"
        }
        unColorAllImages()
        e.currentTarget.children[0].src = `/images/browse/${colorPath}`
        e.currentTarget.style.borderBottom = "2px solid black"
        if (group === "Other") {
            setListToShow(
                categories.filter(
                    item =>
                        item.group !== "Design" &&
                        item.group !== "Video & Audio" &&
                        item.group !== "Development" &&
                        item.group !== "Marketing" &&
                        item.group !== "Writing & Translation"
                )
            )
        } else {
            setListToShow(categories.filter(item => item.group === group))
        }
    }
    function unColorAllImages() {
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[0].src = "/images/browse/design.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[1].src = "/images/browse/video.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[2].src = "/images/browse/development.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[3].src = "/images/browse/marketing.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[4].src = "/images/browse/writing.svg"
        document.getElementsByClassName(
            "browse_search_categories_icon"
        )[5].src = "/images/browse/other.svg"
    }

    useEffect(() => {
        let originalMeta = document.head.querySelector(
            'meta[name="description"]'
        ).content
        document.head.querySelector(
            'meta[name="description"]'
        ).content = `Browse AdPlin service marketplace and select top Businesses and Freelancers by their categories and skills. All you need to grow and develop your business online`
        document.title = `AdPlin | Categories`

        fetch("/category/get")
            .then(res => res.json())
            .then(res => {
                setCategories(res.categories)
                setListToShow(
                    res.categories.filter(item => item.group === "Design")
                )
                document.getElementById(
                    "categories_group_container"
                ).children[0].style.borderBottom = "2px solid black"
            })

        return () => {
            document.head.querySelector(
                'meta[name="description"]'
            ).content = originalMeta
        }
    }, [])
    return (
        <div>
            <div id="categories_wrapper_desktop">
                <div id="categories_title_container">
                    <div id="categories_title">{mainScript.categories}</div>
                    <div id="categories_group_container">
                        <li
                            onClick={e =>
                                groupClicked("Design", e, "design_color.svg")
                            }
                        >
                            <img
                                src="/images/browse/design_color.svg"
                                class="browse_search_categories_icon"
                                alt="Design"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.design}
                        </li>
                        <li
                            onClick={e =>
                                groupClicked(
                                    "Video & Audio",
                                    e,
                                    "video_color.svg"
                                )
                            }
                        >
                            <img
                                src="/images/browse/video.svg"
                                class="browse_search_categories_icon"
                                alt="Video and Audio"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.video}
                        </li>
                        <li
                            onClick={e =>
                                groupClicked(
                                    "Development",
                                    e,
                                    "development_color.svg"
                                )
                            }
                        >
                            <img
                                src="/images/browse/development.svg"
                                class="browse_search_categories_icon"
                                alt="Development"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.development}
                        </li>
                        <li
                            onClick={e =>
                                groupClicked(
                                    "Marketing",
                                    e,
                                    "marketing_color.svg"
                                )
                            }
                        >
                            <img
                                src="/images/browse/marketing.svg"
                                class="browse_search_categories_icon"
                                alt="Marketing"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.marketing}
                        </li>
                        <li
                            onClick={e =>
                                groupClicked(
                                    "Writing & Translation",
                                    e,
                                    "writing_color.svg"
                                )
                            }
                        >
                            <img
                                src="/images/browse/writing.svg"
                                class="browse_search_categories_icon"
                                alt="Writing"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.writing}
                        </li>
                        <li
                            onClick={e =>
                                groupClicked("Other", e, "other_color.svg")
                            }
                        >
                            <img
                                src="/images/browse/other.svg"
                                class="browse_search_categories_icon"
                                alt="Other"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.other}
                        </li>
                    </div>
                </div>
                <div id="categories_list_container">
                    {listToShow.map(category => {
                        let path = `/category_list?id=${category.id}`
                        return (
                            <li className="categories_list">
                                <Link
                                    className="categories_list_link"
                                    to={path}
                                >
                                    {script[category.id]}
                                </Link>
                            </li>
                        )
                    })}
                </div>
            </div>
            <div id="categories_wrapper_mobile">
                {mobileShowGroups ? (
                    <div id="categories_group_container_mobile">
                        <li onClick={e => groupClicked("Design", e)}>
                            <img
                                src="/images/browse/design_color.svg"
                                class="browse_search_categories_icon"
                                alt="Design"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.design}
                        </li>
                        <li onClick={e => groupClicked("Video & Audio", e)}>
                            <img
                                src="/images/browse/video_color.svg"
                                class="browse_search_categories_icon"
                                alt="Video and Audio"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.video}
                        </li>
                        <li onClick={e => groupClicked("Development", e)}>
                            <img
                                src="/images/browse/development_color.svg"
                                class="browse_search_categories_icon"
                                alt="Development"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.development}
                        </li>
                        <li onClick={e => groupClicked("Marketing", e)}>
                            <img
                                src="/images/browse/marketing_color.svg"
                                class="browse_search_categories_icon"
                                alt="Marketing"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.marketing}
                        </li>
                        <li
                            onClick={e =>
                                groupClicked("Writing & Translation", e)
                            }
                        >
                            <img
                                src="/images/browse/writing_color.svg"
                                class="browse_search_categories_icon"
                                alt="Writing"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.writing}
                        </li>
                        <li onClick={e => groupClicked("Other", e)}>
                            <img
                                src="/images/browse/other_color.svg"
                                class="browse_search_categories_icon"
                                alt="Other"
                                style={{ paddingTop: "10px" }}
                            />
                            {mainScript.other}
                        </li>
                    </div>
                ) : (
                    <>
                        <div
                            id="categories_mobile_back_button"
                            onClick={() => setMobileShowGroups(true)}
                        >
                            <MdKeyboardBackspace />
                            Back
                        </div>
                        <div id="categories_list_container">
                            {listToShow.map(category => {
                                let path = `/category_list?id=${category.id}`
                                return (
                                    <li className="categories_list">
                                        <Link
                                            className="categories_list_link"
                                            to={path}
                                        >
                                            {script[category.id]}
                                        </Link>
                                    </li>
                                )
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Categories
