import React from "react"
import { FiCheck } from "react-icons/fi"
import { FcAdvertising } from "react-icons/fc"
import { BsFillPeopleFill } from "react-icons/bs"
import getScript from "../../language"

import "../../css/Home_Howitworks.css"

const HowItWorks = () => {
    let script = getScript("introduction")
    return (
        <div id="home_howitworks_container">
            <div id="home_howitworks_title">{script.how}</div>
            <div id="home_howitworks_detail_container">
                <div className="home_howitworks_detail">
                    <div
                        id="home_howitworks_detail_title_1"
                        className="home_howitworks_detail_title"
                    >
                        <BsFillPeopleFill />
                        {script.looking1}
                    </div>
                    <div className="home_howitworks_detail_list">
                        <div className="home_howitworks_detail_list_number">
                            1
                        </div>
                        <div className="home_howitworks_detail_list_right">
                            <div>{script.looking1_1_title}</div>
                            <div>{script.looking1_1}</div>
                        </div>
                    </div>
                    <div className="home_howitworks_detail_list">
                        <div className="home_howitworks_detail_list_number">
                            2
                        </div>
                        <div className="home_howitworks_detail_list_right">
                            <div>{script.looking1_2_title}</div>
                            <div>{script.looking1_2}</div>
                        </div>
                    </div>
                    <div className="home_howitworks_detail_list">
                        <div className="home_howitworks_detail_list_number">
                            3
                        </div>
                        <div className="home_howitworks_detail_list_right">
                            <div>{script.looking1_3_title}</div>
                            <div>{script.looking1_3}</div>
                        </div>
                    </div>
                </div>
                <div className="home_howitworks_detail">
                    <div
                        id="home_howitworks_detail_title_2"
                        className="home_howitworks_detail_title"
                    >
                        <FcAdvertising />
                        {script.looking2}
                    </div>
                    <div className="home_howitworks_detail_list">
                        <div className="home_howitworks_detail_list_number">
                            1
                        </div>
                        <div className="home_howitworks_detail_list_right">
                            <div>{script.looking2_1_title}</div>
                            <div>{script.looking2_1}</div>
                        </div>
                    </div>
                    <div className="home_howitworks_detail_list">
                        <div className="home_howitworks_detail_list_number">
                            2
                        </div>
                        <div className="home_howitworks_detail_list_right">
                            <div>{script.looking2_2_title}</div>
                            <div>{script.looking2_2}</div>
                        </div>
                    </div>
                    <div className="home_howitworks_detail_list">
                        <div className="home_howitworks_detail_list_number">
                            3
                        </div>
                        <div className="home_howitworks_detail_list_right">
                            <div>{script.looking2_3_title}</div>
                            <div>{script.looking2_3}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks
