import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, Link, useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { convertFromRaw } from "draft-js"
import Title from "../components/create/Title"
import UploadFiles from "../components/create/UploadFiles"
import Description from "../components/create/Description"
import Tag from "../components/create/Tag"
import Pricing from "../components/create/Pricing"

import getScript from "../language"

import "../css/Create_Edit.css"

const Create_Edit = () => {
    const script = getScript("create_edit")
    const db_script = getScript("db_categories")
    let query = new URLSearchParams(useLocation().search)
    const userData = useSelector(state => state.user)
    const history = useHistory()
    const [category, setCategory] = useState()
    const [categoryName, setCategoryName] = useState()
    const [title, setTitle] = useState("")
    const [main, setMain] = useState()
    const [files, setFiles] = useState()
    const [originalMain, setOriginalMain] = useState()
    const [originalFiles, setOriginalFiles] = useState()
    const [description, setDescription] = useState()
    const [tag, setTag] = useState()
    const [price, setPrice] = useState()

    const [errMessage, setErrMessage] = useState("")
    const [loading, setLoading] = useState(true)

    function handleChange(what, value) {
        switch (what) {
            case "title":
                setTitle(value)
                break
            case "main":
                setMain(value)
                break
            case "files":
                setFiles(value)
                break
            case "description":
                setDescription(value)
                break
            case "tag":
                setTag(value)
                break
            case "price":
                setPrice(value)
                break
            case "originalMain":
                setOriginalMain(value)
                break
            case "originalFiles":
                setOriginalFiles(value)
                break
        }
    }

    function cancelHandler() {
        //Redirect to Prev
    }

    function createPost() {
        if (validateInput("regular")) {
            setErrMessage("")
            sendToServer()
        }
    }

    function validateInput(type) {
        switch (type) {
            case "regular":
                if (title === "") {
                    setErrMessage(script.err_title)
                    return false
                }
                if (main === "" || main === undefined) {
                    setErrMessage(script.err_upload)
                    return false
                }
                if (
                    price[0] === undefined ||
                    price[1] === undefined ||
                    price[2] === undefined ||
                    price[0] === "" ||
                    price[1] === "" ||
                    price[2] === ""
                ) {
                    setErrMessage(script.err_price)
                    return false
                }
                if (!convertFromRaw(JSON.parse(description)).hasText()) {
                    setErrMessage(script.err_description)
                    return false
                }
                if (
                    convertFromRaw(JSON.parse(description)).getPlainText()
                        .length < 100
                ) {
                    setErrMessage(
                        `${script.err_short} (${
                            convertFromRaw(
                                JSON.parse(description)
                            ).getPlainText().length
                        } / 100)`
                    )
                    return false
                }
                break
        }
        return true
    }

    function sendToServer() {
        fetch("/create/", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                category,
                categoryName,
                title,
                main,
                originalMain,
                files,
                originalFiles,
                description,
                tag,
                price
            })
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.status === "succeed") {
                    history.push("/create_success")
                } else {
                }
            })
    }

    function resetTemp() {
        fetch("/create/temp/reset", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userData.token}`,
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.status === "succeed") {
                    console.log("success!")
                    setLoading(false)
                } else {
                    console.log("failed")
                }
            })
    }

    useEffect(() => {
        setCategory(query.get("category"))
        if (query.get("category") === "15") {
            setCategoryName(query.get("category_name"))
        } else {
            setCategoryName(db_script[query.get("category")])
        }
        resetTemp()
    }, [])
    return (
        <div>
            {loading ? (
                <div></div>
            ) : (
                <div>
                    <div id="create_edit_navigation">
                        <Link to="/create" id="create_edit_navigation_link">
                            <MdKeyboardBackspace />
                            <div>{script.back}</div>
                        </Link>
                        <div id="create_edit_category_name">{categoryName}</div>
                    </div>
                    <Title updateParent={handleChange} />
                    <UploadFiles updateParent={handleChange} />
                    <Description updateParent={handleChange} />
                    <Tag updateParent={handleChange} />
                    <Pricing updateParent={handleChange} />
                    <div id="create_edit_buttons_container">
                        <button onClick={cancelHandler}>{script.cancel}</button>
                        <button onClick={createPost}>{script.create}</button>
                    </div>
                    <div id="create_edit_err_message">{errMessage}</div>
                </div>
            )}
        </div>
    )
}

export default Create_Edit
