import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

const VerifyAccount = props => {
    let query = new URLSearchParams(useLocation().search)
    useEffect(() => {
        console.log(query.get("code"))
        fetch("/users/verify_account", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                code: query.get("code")
            })
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
            })
    }, [])
    return <div>VerifyAccount</div>
}

export default VerifyAccount
