const getScript = page => {
    let language = localStorage.getItem("language")
    if (!language) {
        language = "en"
    }
    let script = {
        header: {
            en: {
                create: "Create",
                browse: "Browse",
                categories: "Categories",
                messenger: "Message",
                login: "Log in",
                signup: "Sign up",
                logout: "Log out",
                myposts: "My Posts",
                subscription: "Subscription",
                profile: "Profile",
                setting: "Setting",
                account: "My Account",
                language: "Language",
                recently: "Recent Views",
                search: "Search",
                payment: "Pay & Request",
                earning: "Earning",
                google: "Continue with Google"
            },
            ko: {
                create: "업로드",
                browse: "둘러보기",
                categories: "카테고리",
                messenger: "메세지",
                login: "로그인",
                signup: "회원 가입",
                logout: "로그아웃",
                myposts: "내 포스트",
                subscription: "멤버쉽",
                setting: "설정",
                account: "계정 설정",
                language: "언어",
                recently: "최근 본 포스트",
                search: "검색",
                payment: "결제 & 요청",
                earning: "수입",
                google: "Google 로그인"
            }
        },
        loginform: {
            en: {
                signinto: "Sign in",
                facebook: "Continue with Facebook",
                google: "Continue with Google",
                apple: "Continue with Apple",
                email: "Email",
                password: "Password",
                loginbutton: "Login",
                forgot: "Forgot your account?",
                not: "Not a member yet?",
                reset: "Forgot password?",
                create: "Sign up",
                resetbutton: "Reset",
                already: "Already a member?",
                signintoaccount: "Sign into account",
                createbutton: "Create",
                firstname: "First name",
                lastname: "Last name",
                or: "OR",
                loading: "Loading",
                "Sign in to": "Sign in",
                Join: "Sign up",
                Reset: "Reset",
                Verify: "Verify",
                verifyaccount: "Verify your email to proceed",
                justsent:
                    "We just sent you a verification email to the following email address:",
                click:
                    "Please click the link from your email to verify your account.",
                insertemail: "Please insert your email.",
                insertpassword: "Please insert password.",
                insertfirstname: "Please insert your first name.",
                insertlastname: "Please insert your last name.",
                resetmessage: "Reset instruction email has been sent."
            },
            ko: {
                signinto: "애드플린 로그인",
                facebook: "Facebook 로그인",
                google: "Google 로그인",
                apple: "Apple 로그인",
                email: "이메일",
                password: "비밀번호",
                loginbutton: "로그인",
                forgot: "비밀번호를 잊으셨나요?",
                not: "회원이 아닌가요?",
                reset: "비밀번호 초기화",
                create: "회원 가입",
                resetbutton: "초기화",
                already: "이미 회원인가요?",
                signintoaccount: "로그인",
                createbutton: "만들기",
                firstname: "이름",
                lastname: "성",
                or: "또는",
                loading: "로딩중",
                "Sign in to": "로그인",
                Join: "회원가입",
                Reset: "비밀번호 초기화",
                Verify: "이메일 인증",
                verifyaccount: "이메일을 인증해주세요.",
                justsent: "아래 이메일로 인증 메일을 보냈습니다.",
                click: "이메일에 버튼을 클릭하여 인증하여주세요.",
                insertemail: "이메일을 입력해주세요.",
                insertpassword: "비밀번호를 입력해주세요.",
                insertfirstname: "이름을 입력해주세요.",
                insertlastname: "성을 입력해주세요.",
                resetmessage: "초기화 이메일을 전송하였습니다"
            }
        },
        introduction: {
            en: {
                top: "One platform to find all businesses you need.",
                popular: "Popular Categories",
                all: "See all categories",
                different: "What makes AdPlin different?",
                different1:
                    "Minimize your effort to find a right business or freelancer by comparing their portfolios in one platform.",
                different2:
                    "Unlike the other platforms who charges you or the business about 20%, we only charge you 3% fees for card processing fee.",
                different3:
                    "You are welcome to contact the business or freelancer and get quotes directly from them.",
                how: "How it works?",
                looking1: "Looking for a business or freelancer?",
                looking1_1_title: "Browse",
                looking1_1:
                    "Choose category and find the type of work you need.",
                looking1_2_title: "Compare",
                looking1_2: "Compare portfolios and prices at a glance.",
                looking1_3_title: "Connect",
                looking1_3: "Contact the professionals and start the project.",
                looking2: "Looking for a client?",
                looking2_1_title: "Set up",
                looking2_1:
                    "Set up your account with your contact information.",
                looking2_2_title: "Post",
                looking2_2: "Post your portfolio",
                looking2_3_title: "Connect",
                looking2_3: "Get customers"
            },
            ko: {
                top: "모든 비지니스를 위한 단 하나의 플렛폼",
                popular: "인기 카테고리",
                all: "모든 카테고리",
                different: "애드플린이 특별한 이유",
                different1:
                    "단일 플랫폼에서 포트폴리오를 비교하여 적합한 비지니스 또는 프리랜서를 찾는 노력을 최소화 할 수 있습니다.",
                different2:
                    "애드플린은 프로젝트에 대한 수수료로 이득을 얻지 않습니다.",
                different3:
                    "사업체나 프리랜서에게 직접 연락하셔서 견적을 받아도 됩니다.",
                how: "이용 방법",
                looking1: "비즈니스 또는 프리랜서를 찾고 계십니까?",
                looking1_1_title: "Browse",
                looking1_1: "카테고리 중 하나를 클릭합니다.",
                looking1_2_title: "Compare",
                looking1_2: "포트폴리오를 비교합니다.",
                looking1_3_title: "Connect",
                looking1_3: "연락하여 추가 정보를 요청하세요.",
                looking2: "고객을 찾고 계십니까?",
                looking2_1_title: "Set up",
                looking2_1: "회원 가입 후 계정을 설정해주세요.",
                looking2_2_title: "Post",
                looking2_2: "포트폴리오를 업로드 하세요.",
                looking2_3_title: "Connect",
                looking2_3: "고객을 확보하세요."
            }
        },
        main_categories: {
            en: {
                categories: "Categories",
                design: "Design",
                video: "Video & Audio",
                development: "Development & IT",
                marketing: "Marketing",
                writing: "Writing & Translation",
                other: "Other",
                choose: "Choose the category",
                each: "Each user can upload 5 posts without subscription.",
                add: "Add Category",
                cant: "Can't find your category?",
                your: "Enter category name"
            },
            ko: {
                categories: "카테고리",
                design: "디자인",
                video: "비디오 & 오디오",
                development: "개발 & IT",
                marketing: "마케팅",
                writing: "글쓰기 & 번역",
                other: "더보기",
                choose: "카테고리를 선택하세요.",
                each: "맴버쉽 없이는 5개의 게시물을 올릴 수 있습니다.",
                add: "카테고리 추가",
                cant: "카테고리를 추가하세요.",
                your: "카테고리 이름을 입력하세요"
            }
        },
        db_categories: {
            en: {
                1: "Logo Design",
                2: "Sign Design",
                3: "2D Animation",
                4: "3D Art",
                5: "3D Printing",
                6: "Animation",
                7: "Architectural Design",
                8: "Web Development",
                9: "Mobile App Develop",
                10: "Video & Animation",
                11: "Digital Marketing",
                12: "Product Ad Design",
                13: "Covid-19 Related",
                14: "Menu Design",
                15: "Others",
                16: "3D Product Animation",
                17: "Book Design",
                18: "Car Wraps",
                19: "Album Cover Design",
                20: "Promotion Videos",
                21: "Cartoons & Comics",
                22: "AR Filters & Lenses",
                23: "Brochure Design",
                24: "Catalog Design",
                25: "Architecture & Interior Design",
                26: "Building Information Mdeling",
                27: "Character Animation",
                28: "Banner Ads",
                29: "Business Cards",
                30: "Stationery Design",
                31: "3D Product Animation",
                32: "Promotion Videos",
                33: "Character Animation",
                34: "Intros & Outros",
                35: "Animated GIFs",
                36: "Article to Video",
                37: "Dialogue Editing",
                38: "Explainer Video",
                39: "Audio Ads Production",
                40: "DJ Drops & Producer Tags",
                41: "Audiobook Production",
                42: "eLearning Video Production",
                43: "Logo Animation",
                44: "Book Trailers",
                45: "Game Trailers",
                46: "Chatbots",
                47: "Research",
                48: "Data Protection",
                49: "Data Analysis & Reports",
                50: "Databases",
                51: "Desktop Applications",
                52: "eCommerce Development",
                53: "File Conversion",
                54: "Game Development",
                55: "Mobile Apps",
                56: "Online Coding Lessons",
                57: "Quality Assurance",
                58: "Support & IT",
                59: "User Testing",
                60: "Web Programming",
                61: "Website Builders & CMS",
                62: "WordPress",
                63: "Book & eBook Marketing",
                64: "Content Marketing",
                65: "Crowdfunding",
                66: "Domain Research",
                67: "eCommerce Marketing",
                68: "Email Marketing",
                69: "influenceer Marketing",
                70: "Lead Generation",
                71: "Local SEO",
                72: "Marketing Research",
                73: "Market Strategy",
                74: "Mobile Marketing & Advertising",
                75: "Music Promotion",
                76: "Podcast Marketing",
                77: "Public Relations",
                78: "SEM",
                79: "SEO",
                80: "Social Media Advertising",
                81: "Social Media Marketing",
                82: "Articles & Blog Posts",
                83: "Beta Reading",
                84: "Book & eBook Writing",
                85: "Book Editing",
                86: "Brand Voice & Tone",
                87: "Business Name & Slogans",
                88: "Case Studies",
                89: "Cover Letters",
                90: "Creative Writing",
                91: "Email Copy",
                92: "Grant Writing",
                93: "Legal Writing",
                94: "Linkedin Profiles",
                95: "Online Language Lessons",
                96: "Podcast Writing",
                97: "Press Releases",
                98: "Product Descriptions",
                99: "Proofreading & Editing",
                100: "Research & Summaries"
            },
            ko: {
                1: "로고 디자인",
                2: "사인 디자인",
                3: "2D 애니메이션",
                4: "3D 미술",
                5: "3D 프린팅",
                6: "애니메이션",
                7: "건축 설계",
                8: "웹 개발",
                9: "모바일 앱 개발",
                10: "비디오 & 애니메이션",
                11: "디지털 마케팅",
                12: "제품 광고 디자인",
                13: "코로나19 관련",
                14: "메뉴 디자인",
                15: "더보기",
                16: "3D 제품 애니메이션",
                17: "책 표지 디자인",
                18: "자동차 랩",
                19: "앨범 커버 디자인",
                20: "프로모션 비디오",
                21: "카툰 & 코믹스",
                22: "AR 필터 & 렌즈",
                23: "브로셔 디자인",
                24: "카탈로그 디자인",
                25: "건축 & 인테리어 디자인",
                26: "빌딩 정보 모델링",
                27: "Character Animation",
                28: "Banner Ads",
                29: "Business Cards",
                30: "Stationery Design",
                31: "3D Product Animation",
                32: "Promotion Videos",
                33: "Character Animation",
                34: "Intros & Outros",
                35: "Animated GIFs",
                36: "Article to Video",
                37: "Dialogue Editing",
                38: "Explainer Video",
                39: "Audio Ads Production",
                40: "DJ Drops & Producer Tags",
                41: "Audiobook Production",
                42: "eLearning Video Production",
                43: "Logo Animation",
                44: "Book Trailers",
                45: "Game Trailers",
                46: "Chatbots",
                47: "Research",
                48: "Data Protection",
                49: "Data Analysis & Reports",
                50: "Databases",
                51: "Desktop Applications",
                52: "eCommerce Development",
                53: "File Conversion",
                54: "Game Development",
                55: "Mobile Apps",
                56: "Online Coding Lessons",
                57: "Quality Assurance",
                58: "Support & IT",
                59: "User Testing",
                60: "Web Programming",
                61: "Website Builders & CMS",
                62: "WordPress",
                63: "Book & eBook Marketing",
                64: "Content Marketing",
                65: "Crowdfunding",
                66: "Domain Research",
                67: "eCommerce Marketing",
                68: "Email Marketing",
                69: "influenceer Marketing",
                70: "Lead Generation",
                71: "Local SEO",
                72: "Marketing Research",
                73: "Market Strategy",
                74: "Mobile Marketing & Advertising",
                75: "Music Promotion",
                76: "Podcast Marketing",
                77: "Public Relations",
                78: "SEM",
                79: "SEO",
                80: "Social Media Advertising",
                81: "Social Media Marketing",
                82: "Articles & Blog Posts",
                83: "Beta Reading",
                84: "Book & eBook Writing",
                85: "Book Editing",
                86: "Brand Voice & Tone",
                87: "Business Name & Slogans",
                88: "Case Studies",
                89: "Cover Letters",
                90: "Creative Writing",
                91: "Email Copy",
                92: "Grant Writing",
                93: "Legal Writing",
                94: "Linkedin Profiles",
                95: "Online Language Lessons",
                96: "Podcast Writing",
                97: "Press Releases",
                98: "Product Descriptions",
                99: "Proofreading & Editing",
                100: "Research & Summaries"
            }
        },
        category_list: {
            en: {
                top_posts: "Top Posts",
                discover: "Discover"
            }
        },
        create_edit: {
            en: {
                back: "Back to categories",
                title: "Title",
                main: "Upload main image",
                sub: "Upload more images",
                resize: "Images will be resized to 1024px / 768px",
                files: "Upload files",
                description: "Description",
                describe: "Describe your portfolio...",
                tag: "Tag",
                add:
                    "Add up to 5 tags to help clients to find your post easily.",
                pricing: "Pricing",
                basic: "Basic",
                basic_description: "Low-end price of your services",
                average: "Average",
                average_description: "Average price of your services",
                premium: "Premium",
                premium_description: "High-end price of your services",
                cancel: "Cancel",
                create: "Create",
                err_title: "Failed to create: Set the title.",
                err_upload: "Failed to create: Upload main image file.",
                err_price: "Failed to create: Set the price.",
                err_description:
                    "Failed to create: Description need to be written.",
                err_short:
                    "Failed to create: Description is too short. Minimum 100 characters are required."
            }
        },
        post: {
            en: {
                report: "Report",
                inappropriate_post: "Is this post inappropriate?",
                tell_us_post: "Tell us about what is wrong with this post.",
                inappropriate_review: "Is this review inappropriate?",
                tell_us_review: "Tell us about what is wrong with this review.",
                close: "Close",
                submit: "Submit",
                pricing: "Pricing",
                basic: "Basic",
                average: "Average",
                premium: "Premium",
                about: "About",
                message: "Message",
                reviews: "Reviews",
                sort: "sort by:",
                newest: "Newest",
                oldest: "Oldest",
                highest: "Highest rating",
                lowest: "Lowest rating",
                no_review: "No reviews yet. Log in to write the first review!",
                save: "Save",
                cancel: "Cancel",
                edit: "Edit",
                delete: "Delete",
                close: "Close",
                submit: "Submit",
                thanks: "Thank you for your report.",
                tell_us_experience: "Tell us about your experience",
                not_found: "POST NOT FOUND",
                copied: "Copied to clipboard",
                pay: "Pay"
            }
        },
        contact: {
            en: {
                title: "Submit a Request",
                name: "Name",
                email: "Email address",
                what: "What do you need help with?",
                subject: "Subject",
                select: "Select",
                account: "Account issues",
                bug: "Report a bug",
                other: "Other",
                message: "Message",
                submit: "Submit",
                love_to: "We'd love to hear from you.",
                contact: "Contact us",
                address: "contact@adplin.com",
                err_name: "Please insert name.",
                err_email: "Please insert email",
                err_incorrect_email: "Please insert valid email address",
                err_what: "Please select option",
                err_message: "Please insert message",
                failed: "Failed to send an email, please try later."
            }
        },
        browse: {
            en: {
                trending: "Trending",
                popular: "Popular Categories",
                see: "See all categories",
                8: "Popular in Web Development",
                1: "Popular in Logo Design",
                11: "Popular in Digital Marketing"
            },
            ko: {
                trending: "인기있어요",
                popular: "추천 카테고리",
                see: "전체 보기",
                8: "웹 개발 추천",
                1: "로고 디자인 추천",
                11: "디지털 마케팅 추천"
            }
        },
        footer: {
            en: {
                terms: "Terms of Use",
                privacy: "Privacy Policy",
                company: "Company",
                contact: "Contact Us"
            },
            ko: {
                terms: "이용약관",
                privacy: "개인 정보 정책",
                company: "회사 소개",
                contact: "문의하기"
            }
        },
        adblocker: {
            en: {
                title: "We noticed you are using an ad blocker",
                //description: "Adplin noticed that other freelancer platforms charge either client or freelancers about 20% of their pay.",
                description1:
                    "We wanted to help client and freelancers by creating this platform where both of us can be connected without a single charge.",
                description2:
                    "In order to maintain this service without any payment, we decided to monetize advertisement.",
                description3:
                    "It is not required but we would really appreciated if you can support us by disabling the Ad blocker.",
                close: "Close"
            },
            ko: {
                title: "광고 차단기가 활성화 되어있습니다.",
                description1:
                    "저희는 고객과 프리랜서들이 아무런 비용없이 연락할수있게 도움을 주고싶어 이 플랫폼을 만들었습니다.",
                description2:
                    "고객들에게 무료의 서비스를 유지하기 위하여 저희는 광고로 수익을 창출하기로 결정하였습니다.",
                description3:
                    "필수는 아니지만 광고 차단기를 비활성화하여 저희를 지원해 주시면 정말 감사하겠습니다.",
                close: "닫기"
            }
        }
    }
    if (script[page][language]) {
        return script[page][language]
    } else {
        return script[page].en
    }
}

export default getScript
