import React from "react"
import "../css/Message.css"

const Message = props => {
    return (
        <div id="component_message_container">
            <img src="/images/logo_icon.svg" alt="logo" width="150px" />
            <div>{props.message}</div>
        </div>
    )
}

export default Message
