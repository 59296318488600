import React from "react"
import { Link } from "react-router-dom"
import "../../css/Home_Categories.css"
import getScript from "../../language"

const Categories = () => {
    let script = getScript("introduction")
    let db_script = getScript("db_categories")

    return (
        <div id="home_categories_container">
            <div id="home_categories_text">
                <div>{script.popular}</div>
                <Link to="/categories">{script.all}</Link>
            </div>
            <div id="home_categories_list_container">
                <List
                    name={db_script[8]}
                    source={"main4.jpg"}
                    category_id={8}
                />
                <List
                    name={db_script[1]}
                    source={"main3.jpg"}
                    category_id={1}
                />
                <List
                    name={db_script[10]}
                    source={"main1.jpg"}
                    category_id={10}
                />
                <List
                    name={db_script[11]}
                    source={"main16.jpg"}
                    category_id={11}
                />
                <List
                    name={db_script[13]}
                    source={"main13.jpg"}
                    category_id={13}
                />
                <List
                    name={db_script[12]}
                    source={"main10.jpg"}
                    category_id={12}
                />
                <List
                    name={db_script[14]}
                    source={"main12.jpg"}
                    category_id={14}
                />
                <List
                    name={db_script[2]}
                    source={"main11.jpg"}
                    category_id={2}
                />
            </div>
        </div>
    )
}

const List = props => {
    let source = `/images/${props.source}`
    function zoomInImage(event) {
        event.currentTarget.children[0].children[0].style.transform =
            "scale(1.1)"
        //event.currentTarget.children[1].style.color = "white"
    }
    function zoomOutImage(event) {
        event.currentTarget.children[0].children[0].style.transform = "scale(1)"
        event.currentTarget.children[1].style.color = "black"
    }
    let url = `/category_list?id=${props.category_id}`
    return (
        <Link
            id="home_categories_list"
            onMouseOver={zoomInImage}
            onMouseOut={zoomOutImage}
            to={url}
        >
            <div className="home_categories_list_image_container">
                <img
                    className="home_categories_list_image"
                    src={source}
                    alt={"category images"}
                />
            </div>
            <div>{props.name}</div>
        </Link>
    )
}

export default Categories
