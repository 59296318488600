import React, { useEffect } from "react"
//import { useLocation } from "react-router-dom"
import AccountMain from "../components/account/AccountMain"

const Account = props => {
//    let query = new URLSearchParams(useLocation().search)

//    useEffect(() => {
//        console.log(query.get("token"))
//        if (query.get("action") === "reset") {
//            console.log("requested to reset password")
//	    fetch("/users/getUserInfo", {
//                method: "POST",
//                headers: {
//                    "Content-Type": "application/json",
//                    Authorization: `Bearer ${query.get("token")}`
//               },
//                body: JSON.stringify({})
//            })
//                .then(res => {
//                    return res.json()
//                })
//                .then(data => {
//                    if (data.action === "succeed") {
//                        dispatch(
//                            user.logIn(
//                                data.userData.email,
//                                data.userData.login_from,
//                                query.get("token")
//                            )
//                        )
//                    }
//                })
//                .catch(err => {
//                    console.log(err)
//                })

//        }
//    }, [])
    return (
        <div>
            <div>
                <AccountMain passedFunction={props.passedFunction} />
            </div>
        </div>
    )
}

export default Account
