import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import user from "../store/actions/user"

const Google_Login = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    //const userData = useSelector(state => state.user)

    useEffect(() => {
        console.log("before sending back to verify auth")
        fetch(`/users/google/verify_auth`)
            .then(res => res.json())
            .then(res => {
                if (res.status == "succeed") {
                    dispatch(
                        user.logIn(res.data.email, "google", res.data.token)
                    )
                    if (res.data.language) {
                        dispatch(user.updateLanguage(res.data.language))
                        props.changeLanguage(res.data.language)
                    }
                    if (props.to === "pop") {
                        props.passedFunction("Sign in to", "")
                    } else {
                        history.push("browse")
                    }
                }
            })
    }, [])
    return <div></div>
}

export default Google_Login
