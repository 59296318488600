import React, { useEffect } from "react"
import Full_Notification from "../components/notification/Full_Notification"

const Payment_Succeed = () => {
    useEffect(() => {
        //get payment intent
    }, [])
    return (
        <div>
            <Full_Notification
                img="/images/payment/payment_succeed.svg"
                title="Payment sent!"
                message="That’s it! Your payment has been sent succesfully. We have sent your receipt to your email."
            />
        </div>
    )
}

export default Payment_Succeed
