import React from "react"
import "../../css/messenger/MessageList.css"

const MessageList = props => {
    return (
        <div id="messageList_left_container">
            <div id="messageList_left_title">Messages</div>
            <div id="messageList_left_list_container">
                {props.list.map(item => {
                    let to
                    let to_name
                    let to_phone = undefined
                    let to_link = undefined
                    let to_email = undefined
                    let item_date = new Date(item.time)
                    let current_date = new Date()
                    let display_date
                    if (
                        item_date.getFullYear() ===
                            current_date.getFullYear() &&
                        item_date.getMonth() === current_date.getMonth() &&
                        item_date.getDate() === current_date.getDate()
                    ) {
                        let hours = item_date.getHours().toLocaleString()
                        let minutes = item_date.getMinutes().toLocaleString()
                        let ampm = hours >= 12 ? "pm" : "am"
                        hours = hours % 12
                        hours = hours ? hours : 12 // the hour '0' should be '12'
                        minutes = minutes < 10 ? "0" + minutes : minutes
                        display_date = `${hours}:${minutes} ${ampm}`
                    } else {
                        display_date = `${
                            item_date.getMonth() + 1
                        }/${item_date.getDate()}/${item_date.getFullYear()}`
                    }
                    if (item.from_id === props.user_id) {
                        to = item.to_id
                        if (item.to_phone) {
                            to_phone = item.to_phone
                        }
                        if (item.to_link) {
                            to_link = item.to_link
                        }
                        if (item.to_email) {
                            to_email = item.to_email
                        }
                        if (item.to_display_name === "full_name") {
                            to_name = `${item.to_first_name} ${item.to_last_name}`
                        } else {
                            to_name = `${item.to_business_name}`
                        }
                    } else {
                        to = item.from_id
                        if (item.from_phone) {
                            to_phone = item.from_phone
                        }
                        if (item.from_link) {
                            to_link = item.from_link
                        }
                        if (item.from_email) {
                            to_email = item.from_email
                        }
                        if (item.from_display_name === "full_name") {
                            to_name = `${item.from_first_name} ${item.from_last_name}`
                        } else {
                            to_name = `${item.from_business_name}`
                        }
                    }
                    let selectedColor = undefined
                    let selectedColorOverflow = undefined
                    if (item.room === props.room) {
                        selectedColor = "messenger_messageList_selectedColor"
                        selectedColorOverflow =
                            "messenger_messageList_selectedColorOverflow"
                    }
                    return (
                        <li
                            onClick={e => {
                                props.chooseRoom(
                                    item.room,
                                    to_name,
                                    to_phone,
                                    to_link,
                                    to_email
                                )
                                //changeBackground(e)
                            }}
                            className={selectedColor}
                        >
                            <div className="messenger_messageList_icon">
                                {to_name[0]}
                            </div>
                            <div className="messenger_messageList_description">
                                <div className="messenger_messageList_to_name">
                                    {to_name}
                                </div>
                                {!item.already_read &&
                                item.from_id !== props.user_id ? (
                                    <div className="messenger_messageList_read"></div>
                                ) : (
                                    <div></div>
                                )}
                                <div className="messenger_messageList_message">
                                    {item.message}
                                    <div
                                        className={
                                            selectedColorOverflow +
                                            " messenger_messageList_message_overflow"
                                        }
                                    ></div>
                                </div>
                                <div className="messenger_messageList_date">
                                    {display_date}
                                </div>
                            </div>
                        </li>
                    )
                })}
            </div>
        </div>
    )
}

export default MessageList
