import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import getScript from "../../language"
import "../../css/category_list/Desktop_Navigation.css"

const Desktop_Navigation = props => {
    const script = getScript("db_categories")
    const history = useHistory()
    const [other_list, setOther_list] = useState([])

    function sendTo(id) {
        history.push(`/category_list?id=${id}`)
    }
    function moveTo(where) {
        let box = document.getElementById(
            "category_list_desktop_navigation_other_list"
        )
        if (where === "left") {
            box.scrollLeft -= box.clientWidth
        } else {
            box.scrollLeft += box.clientWidth
        }
    }
    let scrollWidth = e => {
        let fullWidth = e.currentTarget.scrollWidth
        let showingWidth = e.currentTarget.clientWidth

        if (e.currentTarget.scrollLeft === 0) {
            document.getElementById(
                "category_list_desktop_navigation_other_list_buttons_left"
            ).style.visibility = "hidden"
        } else {
            document.getElementById(
                "category_list_desktop_navigation_other_list_buttons_left"
            ).style.visibility = "visible"
        }

        if (e.currentTarget.scrollLeft >= fullWidth - showingWidth) {
            document.getElementById(
                "category_list_desktop_navigation_other_list_buttons_right"
            ).style.visibility = "hidden"
        } else {
            document.getElementById(
                "category_list_desktop_navigation_other_list_buttons_right"
            ).style.visibility = "visible"
        }
    }

    useEffect(() => {
        if (props.other_list) {
            setOther_list(props.other_list)
        }
    }, [props])

    let adjustScroll = () => {
        let target = document.getElementById(
            "category_list_desktop_navigation_other_list"
        )
        let fullWidth = target.scrollWidth
        let showingWidth = target.clientWidth

        if (0 >= fullWidth - showingWidth) {
            document.getElementById(
                "category_list_desktop_navigation_other_list_buttons_right"
            ).style.visibility = "hidden"
        }
    }

    useEffect(() => {
        if (other_list.length) {
            adjustScroll()
        }
    }, [other_list])
    return (
        <div id="category_list_desktop_navigation_container">
            <div id="category_list_desktop_navigation_container_title">
                {props.group}
            </div>
            {other_list.length ? (
                <MdChevronLeft
                    id="category_list_desktop_navigation_other_list_buttons_left"
                    className="category_list_desktop_navigation_other_list_buttons"
                    onClick={() => moveTo("left")}
                    size={25}
                />
            ) : (
                <></>
            )}
            <div
                id="category_list_desktop_navigation_other_list"
                onScroll={scrollWidth}
            >
                {other_list.map(item => {
                    return (
                        <div
                            className="category_list_desktop_navigation_other_list_item"
                            onClick={() => sendTo(item.id)}
                        >
                            {script[item.id]}
                        </div>
                    )
                })}
            </div>
            {other_list.length ? (
                <MdChevronRight
                    id="category_list_desktop_navigation_other_list_buttons_right"
                    className="category_list_desktop_navigation_other_list_buttons"
                    onClick={() => moveTo("right")}
                    size={25}
                />
            ) : (
                <></>
            )}
        </div>
    )
}

export default Desktop_Navigation
