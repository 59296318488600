import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useLocation, useHistory } from "react-router-dom"
import MessageList from "../components/messenger/MessageList"
import MessageDetail from "../components/messenger/MessageDetail"
import MessageListMobile from "../components/messenger/MessageListMobile"
import MessageDetailMobile from "../components/messenger/MessageDetailMobile"
import "../css/Messenger.css"

const Messenger = props => {
    const userData = useSelector(state => state.user)
    const query = new URLSearchParams(useLocation().search)
    const history = useHistory()
    const [list, setList] = useState([])
    const [user_id, setUser_id] = useState()
    const [room, setRoom] = useState()
    const [to_name, setTo_name] = useState()
    const [to_phone, setTo_phone] = useState()
    const [to_link, setTo_link] = useState()
    const [to_email, setTo_email] = useState()

    const stateRef = useRef()
    stateRef.list = list

    function chooseRoom(room_id, to_name, phone, link, email) {
        setRoom(room_id)
        setTo_name(to_name)
        setTo_phone(phone)
        setTo_link(link)
        setTo_email(email)

        //set message to read on click
        changeMessageList("already_read", 1, room_id)
    }

    function changeMessageList(what, value, room_id) {
        var newList = [...stateRef.list]
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].room === room_id) {
                newList[i][what] = value
            }
        }
        setList(newList)
    }

    function moveToTop(room_id) {
        var newList = []
        for (let i = 0; i < list.length; i++) {
            if (list[i].room === room_id) {
                newList.unshift(list[i])
            } else {
                newList.push(list[i])
            }
        }
        setList(newList)
    }

    useEffect(() => {
        if (props.socket) {
            props.socket.emit("request messages", { token: userData.token })
            props.socket.on("get messages", e => {
                setList(e.list)
                setUser_id(e.user_id)
            })
            props.socket.on("receive message", e => {
                props.socket.emit("request messages", { token: userData.token })
                //check if e.room does not exist
                // console.log(e)
                // console.log(list)
                // if (list.filter(ev => ev.room === e.room).length === 0) {
                //     console.log("pass1")
                //     let tmpArray = [...list]
                //     tmpArray.unshift(e.message_object)
                //     setList(tmpArray)
                // } else {
                //     console.log("pass2")
                //     changeMessageList("message", e.message, e.room)
                //     changeMessageList("already_read", 0, e.room)
                //     changeMessageList("time", e.time, e.room)
                //     moveToTop(e.room)
                // }
            })
        }
        return () => {
            if (props.socket) {
                props.socket.off("get messages")
                props.socket.off("receive message")
            }
        }
    }, [props.socket])

    useEffect(() => {
        if (query.get("room")) {
            chooseRoom(
                query.get("room"),
                query.get("to"),
                query.get("phone"),
                query.get("link"),
                query.get("email")
            )
        }
    }, [query.get("room")])

    useEffect(() => {
        if (
            !userData.token ||
            userData.token === "undefined" ||
            userData.token === "null"
        ) {
            history.push("/login?action=sign_in&to=pop&page=message")
        }
    }, [])
    return (
        <div id="messenger_container">
            <MessageList
                list={list}
                user_id={user_id}
                room={room}
                chooseRoom={chooseRoom}
            />
            {room ? (
                <MessageDetail
                    room={room}
                    toName={to_name}
                    to_phone={to_phone}
                    to_link={to_link}
                    to_email={to_email}
                    socket={props.socket}
                    changeMessageList={changeMessageList}
                    moveToTop={moveToTop}
                />
            ) : (
                <div id="messenger_select_message">
                    <img src="/images/logo_icon.svg" alt="logo" />
                    <div id="messenger_select_a_conversation">
                        Select a Conversation
                    </div>
                </div>
            )}
            {room ? (
                <MessageDetailMobile
                    room={room}
                    toName={to_name}
                    to_phone={to_phone}
                    to_link={to_link}
                    to_email={to_email}
                    chooseRoom={chooseRoom}
                    socket={props.socket}
                    changeMessageList={changeMessageList}
                    moveToTop={moveToTop}
                />
            ) : (
                <MessageListMobile
                    list={list}
                    chooseRoom={chooseRoom}
                    user_id={user_id}
                />
            )}
        </div>
    )
}

export default Messenger
