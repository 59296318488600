import { createStore, combineReducers, applyMiddleware } from "redux"
import ReduxThunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import userReducer from "./reducers/user"

function saveToLocalStorage(name, state) {
    try {
        //const serializedState = JSON.stringify(state)
        //localStorage.setItem("token", serializedState)
        localStorage.setItem(name, state)
    } catch (e) {
        console.log(e)
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem("token")
        const serializedState2 = localStorage.getItem("language")
        //if (serializedState === null) return undefined

        //let token = JSON.parse(serializedState).user.token
        let token = serializedState
        let language = serializedState2
        return { user: { token: token, language: language } }
        //return JSON.parse(serializedState)
    } catch (e) {
        console.log(e)
        return undefined
    }
}

const persistedState = loadFromLocalStorage()

const rootReducer = combineReducers({
    user: userReducer
})

const store = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(ReduxThunk))
)
store.subscribe(() => {
    //console.log(`subscribe = ${store.getState()}`)
    saveToLocalStorage("token", store.getState().user.token)
    saveToLocalStorage("language", store.getState().user.language)
    //saveToLocalStorage({ user: store.getState().user.token })
})

export default store
