import React from "react"
import getScript from "../../language"
import "../../css/post/Price.css"

const Price = props => {
    let script = getScript("post")

    return (
        <div id="post_price">
            <div id="post_price_title">{script.pricing}</div>
            <div id="post_price_container">
                <div className="post_price_box">
                    <div>{script.basic}</div>
                    <div>
                        {Math.round(props.basic).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0
                        })}
                    </div>
                </div>
                <div className="post_price_box">
                    <div>{script.average}</div>
                    <div>
                        {Math.round(props.average).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0
                        })}
                    </div>
                </div>
                <div className="post_price_box">
                    <div>{script.premium}</div>
                    <div>
                        {Math.round(props.luxury).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Price
